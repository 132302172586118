import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getService, postService } from "../../services/services";

// =====================================
// ============ COMPONENTS  ============
// =====================================
import {
  // AppBar,
  // Box,
  // Button,
  // ButtonGroup,
  // Container,
  // CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // Divider,
  // Fab,
  // FormControl,
  FormControlLabel,
  // FormGroup,
  // FormLabel,
  // Grid,
  // Icon,
  // IconButton,
  // InputLabel,
  // Link,
  // ListSubheader,
  // Menu,
  // MenuItem,
  // Paper,
  Radio,
  RadioGroup,
  // Select,
  Slide,
  // Switch,
  // TextField,
  // Toolbar,
  // Tooltip,
  Typography,
  IconButton

} from "@material-ui/core";

// import DeleteIcon from '@material-ui/icons/Delete';
// import DoneIcon from '@material-ui/icons/Done';
// import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import LanguageIcon from '@material-ui/icons/Language';
import { APP } from '../../redux/actions/actions';
// import { deleteService} from "../../services/services";
import * as services from "../../services/services.json";
import { createMuiTheme } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

class DialogLanguage extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      language:this.props.appstate.App.currentLanguage,
      languagePreConfirm:this.props.appstate.App.currentLanguage,
    });

    this.changeLanguage=this.changeLanguage.bind(this);
    this.handleRadioLan=this.handleRadioLan.bind(this);
  }
  componentDidMount() {

  }

  componentDidUpdate(prevProps){
    if ( this.props.appstate.App.currentLanguage !== this.state.language ) {
      this.setState({language:this.props.appstate.App.currentLanguage,
      languagePreConfirm:this.props.appstate.App.currentLanguage});
    }
  }

  onclose = () => {
    // let self=this;
    this.props.appstate.Modal.openModalLanguage = false;
    this.props.dispatch(APP(this.props.appstate));
    if(this.props.appstate.App.user.userid!==""){
      if(this.props.appstate.App.user.preferences===undefined)
        this.props.appstate.App.user.preferences={};

      this.props.appstate.App.user.preferences.language=this.state.language;
      this.props.dispatch(APP(this.props.appstate));

      const headers = { headers: {  'Content-Type': 'application/x-www-form-urlencoded' } };
      const params = new URLSearchParams();
      params.append('appname',this.props.appstate.App.name );
      params.append('id',this.props.appstate.App.user.userid);
      params.append('domainId', this.props.appstate.App.user.domain);
      params.append('language', this.state.language);
      let serviceurl =
      process.env.REACT_APP_DOMAIN + services.userpreferences_POST;
      postService(serviceurl, params, "", headers, function (err, result) {
        if (err) {
          return;
        }
        if (result.status === 200) {
            //preferences lang ok

        }
      });
    }
  }

  getDefaultLanguage= (appname, defaultlang) =>{
    var thisRef=this;
    console.log("sono in getDefaultLanguage "+appname +" "+defaultlang);
    let serviceurl =
    process.env.REACT_APP_DOMAIN + services.language_GET.replace("{APPNAME}", appname);
    serviceurl = serviceurl.replace("{LANG}", defaultlang);
    getService(serviceurl, "", function (err, result) {
      if (err) {
        return;
      }
      if (result.status === 200) {
        let currentconfig = thisRef.props.appstate;
        currentconfig.App.currentLanguage = defaultlang;
        currentconfig.App.language = result.response.labels.language;
        const iframe = document.getElementById('iframecontent');
        if (iframe) {
          thisRef.props.appstate.App.theme.refmaterial = {};
          let appstate = Object.assign({}, thisRef.props.appstate);
          iframe.contentWindow.postMessage({
            type: "WHOLE", acamar: thisRef.props.acamar, appstate: appstate
          }, thisRef.props.appstate.App.currentappchild);
          if (thisRef.props.acamar.configuration.Theme.currenttheme === "light") {
            thisRef.props.appstate.App.theme.refmaterial = createMuiTheme(window.getLightTheme());
          } else if (thisRef.props.acamar.configuration.Theme.currenttheme === "dark") {
            thisRef.props.appstate.App.theme.refmaterial = createMuiTheme(window.getDarkTheme());
          }
        }
        thisRef.props.dispatch(APP(currentconfig));
      }
    });
  }

  //radio button lingua
  handleRadioLan(event, _ref){
    var thisRef = this;
      thisRef.state.languagePreConfirm=event.target.value;

    this.setState({ languagePreConfirm:thisRef.state.languagePreConfirm}, this.changeLanguage(this.state.languagePreConfirm));

  };

  //settaggio lingua
  changeLanguage(lng) {
    //this.props.appstate.App.currentLanguage=lng;
    this.getDefaultLanguage(this.props.acamar.app, this.state.languagePreConfirm);
    //this.props.dispatch(APP(this.props.appstate));
    this.setState({language : this.state.languagePreConfirm}, () => { this.onclose() });
    this.props.newlanguage(this.state.languagePreConfirm);
  };

  render() {

    let currentconfig = this.props.acamar.configuration;
    let jsonlang = this.props.appstate.App.language;

    return (
      <div>
        <Dialog
          role="checkbox"
          aria-label="language check boxes"
          TransitionComponent={Transition}
          disableBackdropClick
          open={this.props.appstate.Modal.openModalLanguage}
          onClose={this.onclose}
          fullWidth
          maxWidth={'xs'}
          >
            <DialogTitle>
              <LanguageIcon
                aria-label="Language Icon"
                color="primary"
                style={{
                  // color: "#fff",
                  // backgroundColor: "#3f51b5",
                  borderRadius: "50px",
                  fontSize: "36px",
                  padding: "2px",
                }}
              />
              <span style={{
                padding: 5,
                position: 'fixed',
              }}>
              {jsonlang.languagesettings.toUpperCase()}
            </span>
            </DialogTitle>
            <DialogContent dividers>
                {currentconfig.Languages.list.map((item,i) =>
                <RadioGroup
                  aria-label="Languages"
                  name="country"
                  value={this.state.languagePreConfirm}
                  onChange={this.handleRadioLan}
                   >
                  <span style={{paddingLeft: '25%'}}>
                    <FormControlLabel value={item.code} control={<Radio color="primary"/>}
                    label={
                      <Typography variant="h5">
                        {/*item.icon!=="" ?
                        <span><img alt={item.code}
                          width="40px"
                          height="auto"
                          src={process.env.REACT_APP_DOMAIN+"/"+item.icon}/></span>
                          : <></>
                        */}
                        <span style={{paddingLeft:10}}>{item.label}</span>
                      </Typography>}/>
                    </span>

                  </RadioGroup>
                )}
              </DialogContent>
              {/* <DialogActions>
                <Button
                onClick={this.onclose}
                color="primary">
                Cancel
              </Button>
              <Button
              onClick={() => this.changeLanguage(this.state.languagePreConfirm)}
              color="primary">
              Ok
            </Button>
          </DialogActions> */}

          <DialogActions>
            <IconButton
            color="primary"
            aria-label="chiudi"
            onClick={this.onclose}
            >
              <CloseIcon />
            </IconButton>
            {/* <Button
              variant="contained"
              size="small"
              onClick={this.onclose}
              color="primary"
              startIcon={<ClearIcon />}>
              <Tooltip title={<h2>{jsonlang.instcancel.toUpperCase()}</h2>}>
              <Typography variant="button">{jsonlang.instcancel}</Typography>
            </Tooltip>
          </Button> */}
          {/* <Button
            variant="contained"
            size="small"
            onClick={() => this.changeLanguage(this.state.languagePreConfirm)}
            color="primary"
            startIcon={<SaveIcon />}>
            <Tooltip title={<h2>{jsonlang.instsave.toUpperCase()}</h2>}>
            <Typography variant="button">{jsonlang.save}</Typography>
          </Tooltip>
        </Button> */}
      </DialogActions>



      {/* <IconButton
        color="primary"
        aria-label="chiudi"
        onClick={this.onclose}
        >
          <CloseIcon />
        </IconButton> */}




    </Dialog>
  </div>
)
}
}
export default connect(mapStateToProps)(DialogLanguage);
