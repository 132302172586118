import {
  IconButton,
  InputBase,
  Divider,
  Box,
  // Tabs,
  // Tab,
  AppBar,
  // InputAdornment,
  Toolbar,
  // Switch,
  Tooltip,
  // TextField,
  // Fab,
  Card,
  // CardHeader,
  CardMedia,
  Typography,
  // Avatar,
  CardContent,
  CardActions,
  Checkbox,
  Grid,
  // Link
} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQrcode,
  // faClosedCaptioning,
  // faCopyright,
  // faBan
} from '@fortawesome/free-solid-svg-icons';
// import AddIcon from '@material-ui/icons/Add';
// import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CancelRounded from '@material-ui/icons/CancelRounded';
// import CropFreeIcon from '@material-ui/icons/CropFree';
// import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import Search from '@material-ui/icons/Search';
import RoomIcon from '@material-ui/icons/Room';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
// import Audiotrack from '@material-ui/icons/Audiotrack';
// import Apps from '@material-ui/icons/Apps';
// import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
// import DeleteIcon from '@material-ui/icons/Delete';
// import EditIcon from '@material-ui/icons/Edit';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { APP } from '../../redux/actions/actions';
import {
  getService,
  // deleteService,
  // postService,
  // multipartformService
}
from "../../services/services";
import * as services from "../../services/services.json";
//att
import './ShowRoomDetail.css';
import DialogDisclaimer from '../Dialog/DialogDisclaimer.jsx';
import DialogResourcesManager from '../ResourcesManager/DialogResourcesManager';
import { GeoMap, loadMarkers } from '../ShowRoom/mapcomp';
// import MapDialog from '../ResourcesManager/MapDialog'
import PreviewDialog from '../ResourcesManager/PreviewDialog'

import ShareIcon from '@material-ui/icons/Share';

import ImageIcon from '@material-ui/icons/Image';
// import CropOriginalIcon from '@material-ui/icons/CropOriginal';

import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import AudiotrackOutlinedIcon from '@material-ui/icons/AudiotrackOutlined';
// import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  // getRights ,
  itemsSort,
  itemsSortInverse,
  itemsSortDate,
  itemsSortInverseDate,
  // getExternalMediaType
} from "../../Utils/utility";
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import Chip from '@material-ui/core/Chip';
import DescriptionIcon from '@material-ui/icons/Description';
import DialogDelete from '../Dialog/DialogDelete';
// import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import {
  getResourceDataForLanguage,
  getExternalMediaType
} from "../../Utils/utility";


const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

class ShowRoomDetail extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      items: [],//this.props.appstate.ShowRoom.resources,
      showtile: {},
      mapmark: {},
      map: false,
      audiofilter: false,
      videofilter: false,
      imagefilter: false,
      documentfilter:false,
      filtersarray: [],
      publicRight:false,
      ccRight:false,
      otherRight:false,
      rightSelected:"",
      copyResState:[],
      searchValue:"",
      datedown:false ,
      dateup:false,
      namedown:false,
      nameup:false,
      orderSelected:"dateup",
      // datedown: true,
      // dateup: false,
      // namedown: false,
      // nameup: false,
      // orderSelected: "datedown",
      openDisclaimer:false,
    });
  }
  componentDidMount() {
    // const params = new URLSearchParams(this.props.location.search);
    // const domain = params.get('domain');
    // this.props.appstate.App.user.domain=params.get('domain');
    this.props.dispatch(APP(this.props.appstate))
    this.getStories();

  }

  getStories = () => {
    this.props.appstate.Modal.openModalLoading = true;
    this.props.appstate.Modal.openModalLoadingTextActive = false;
    this.props.dispatch(APP(this.props.appstate));
    let self = this;
    let ser =
    process.env.REACT_APP_DOMAIN +
    services.resourcemanagerSHOWROOM.replace(
      "{APPNAME}",
      this.props.appstate.App.name
    );
    // ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
    ser = ser.replace("{DEST}", "resource");
    ser=ser+"&visibility=true" ;
    getService(
      ser,
      "",
      function (err, result) {
        if (err) {
          self.props.appstate.Modal.openModalLoadingTextActive = true;
          self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.resourcerror;
          self.props.dispatch(APP(self.props.appstate));
          return;
        }
        if (result.status === 200) {
          self.props.appstate.Modal.openModalLoading = false;
          // let orderArray= self.order(self.state.orderSelected,false, result.response);
          self.props.appstate.ShowRoom.resources = self.random(result.response) ;
          self.props.dispatch(APP(self.props.appstate));
          self.setState({ items: self.props.appstate.ShowRoom.resources});
          return;
        }
        self.props.appstate.Modal.openModalLoadingTextActive = true;
        self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.noresource;
        self.props.dispatch(APP(self.props.appstate));
        return;
      }
    );
  }


  random = (list) =>{
    let currentIndex = list.length,  randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [list[currentIndex], list[randomIndex]] = [
        list[randomIndex], list[currentIndex]];
      }

      return list;
    }

    truncate = (str, n) =>{
      return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    };

    urlCopy = (event) => {
      //console.log(event.currentTarget.id);
      let index = event.currentTarget.id;
      if (index) {
        const temp = document.createElement("input");
        document.body.appendChild(temp);
        temp.value = this.resourceUrl(index);
        temp.select();
        document.execCommand("copy");
        document.body.removeChild(temp);

        this.props.appstate.PreviewDialog.openModalPreview = true;
        this.props.appstate.PreviewDialog.render = true;
        this.props.appstate.PreviewDialog.item = this.state.items[event.currentTarget.name];
        this.props.appstate.PreviewDialog.share = true;
        if (getExternalMediaType().findIndex(x => x.value === this.state.items[event.currentTarget.name].type)!==-1){
          let selectedUri= this.state.items[event.currentTarget.name].url;
          let url="";
          if (typeof(selectedUri)==='string'){ //retrocompatibilità
            url = selectedUri;
          }else {
            let itemHELP={};
            itemHELP.medialan=selectedUri;
            let lang =  getResourceDataForLanguage(itemHELP);
            url = selectedUri[lang];
          }

          this.props.appstate.PreviewDialog.itemurl = url;
        }

        this.previewDialogRef.init();
        // this.props.appstate.PreviewDialog.itemurl=this.props.appstate.PreviewDialog.item.url;
        this.props.dispatch(APP(this.props.appstate));
      }
    }

    // imageOnMouseOver = (event) => {
    //   //console.log(event.currentTarget.id);
    //   let showtile = {};
    //   showtile[event.currentTarget.id] = true;
    //   this.setState({ showtile: showtile });
    // }

    // imageOnMouseOut = (event) => {
    //   //console.log(event.currentTarget.id);
    //   let showtile = {};
    //   showtile[event.currentTarget.id] = false;
    //   this.setState({ showtile: showtile });
    // }

    searchInput = (event) => {
      if (event.target.value.length > 0) {
        let self = this;
        let filtered = this.props.appstate.ShowRoom.resources.filter(function (el, index, arr) {
          let found = false;
          let activeFilter = [];
          if ((el.name.toUpperCase()).indexOf(event.currentTarget.value.toUpperCase()) !== -1) {
            activeFilter.push("name");
            found = true;
          }

          let keysdesc = Object.keys(el.dcDescription);
          for (let i = 0; i < keysdesc.length; i++) {
            if ((el.dcDescription[keysdesc[i]].toUpperCase()).indexOf(event.currentTarget.value.toUpperCase()) !== -1) {
              activeFilter.push("description");
              found = true;
              break;
            }
          }



          if (el.tags) {
            if ((self.getKeyLang(el.tags).toUpperCase()).indexOf(event.currentTarget.value.toUpperCase()) !== -1) {
              activeFilter.push("tags");
              found = true;
            }
          }
          el.filter = activeFilter;
          return found;
        });
        this.setState({ items: filtered, searchValue: event.target.value });
      } else {
        this.setState({ items: this.props.appstate.ShowRoom.resources, searchValue: event.target.value });
      }
    }

    searchInputCancel = () => {
      // document.getElementById("resourcesearchinput").value = "";
      this.setState({ items: this.props.appstate.ShowRoom.resources, searchValue: "" });
    }

    filterselection = (event, value) => {
      switch (event.currentTarget.name) {

        case "map":
        this.setState({ map: this.state.map === true ? false : true },() => {this.filterengine(undefined,undefined,undefined,"")}); // caso da controllare
        // this.filterengine();
        break;

        default:
        break;
      }
    }

    openMapFromCard = (event, value) => {
      if (this.mapref && window.google.maps) {
        this.mapref.setCenter(new window.google.maps.LatLng(this.state.items[event.currentTarget.name].location.coordinates[1], this.state.items[event.currentTarget.name].location.coordinates[0]));
      }
      this.setState({
        map: true,
        openmapfromcard: true,
        openmapfromcardpos: [this.state.items[event.currentTarget.name].location.coordinates[1], this.state.items[event.currentTarget.name].location.coordinates[0]]
      });
    }


    filterengine = (type, flag, mapbound, rightSel) => {
      let filarr = this.state.filtersarray.slice(0, this.state.filtersarray.length);
      if (type) {
        for (var i=0; i<type.length;i++){
          let indexel = filarr.indexOf(type[i]);
          if (indexel !== -1) {
            filarr.splice(indexel, 1);
          }
          if (flag === true) {
            filarr.push(type[i].toLowerCase());
          }
        }
      }
      var filtered = [];
      var copyResources =[]
      if (this.state.searchValue!==""){
        copyResources=this.state.items.slice();
      } else {
        copyResources = this.props.appstate.ShowRoom.resources.slice(0, this.props.appstate.ShowRoom.resources.length);
      }
      let self = this;
      if (filarr.length > 0 && rightSel==="") { //one or more filter activated
        filtered = copyResources.filter(function (el, index, arr) {
          if (self.state.map === true && mapbound !== undefined && self.mapref && parseFloat(el.location.coordinates[1]) !== 0 && parseFloat(el.location.coordinates[0]) !== 0 && typeof mapbound.contains === 'function') {
            return (filarr.indexOf(el.type) !== -1 && mapbound.contains(new window.google.maps.LatLng(parseFloat(el.location.coordinates[1]), parseFloat(el.location.coordinates[0]))))
          } else if (self.state.map === true && self.mapref) {
            return (filarr.indexOf(el.type) !== -1 && parseFloat(el.location.coordinates[1]) !== 0 && parseFloat(el.location.coordinates[0]) !== 0)
          }else {
            return (filarr.indexOf(el.type) !== -1)
          }
        });

        this.setState({ items: self.order(self.state.orderSelected, false, filtered), filtersarray: filarr ,rightSelected:rightSel}, () => { loadMarkers(this.state.items, this.mapref, this) });

      } else if (self.state.map === true && mapbound !== undefined && self.mapref && typeof mapbound.contains === 'function') {//fov map filter activated
        filtered = copyResources.filter(function (el, index, arr) {
          return (mapbound.contains(new window.google.maps.LatLng(parseFloat(el.location.coordinates[1]), parseFloat(el.location.coordinates[0]))) && parseFloat(el.location.coordinates[1]) !== 0 && parseFloat(el.location.coordinates[0]) !== 0 && el.location !== undefined)
        })
        this.setState({ items: self.order(self.state.orderSelected, false, filtered), filtersarray: filarr , rightSelected:rightSel}, () => { loadMarkers(this.state.items, this.mapref, this) });
      } else if (self.state.map === true && self.mapref) {
        filtered = copyResources.filter(function (el, index, arr) {
          return (parseFloat(el.location.coordinates[1]) !== 0 && parseFloat(el.location.coordinates[0]) !== 0)
        })
        this.setState({ items: filtered, filtersarray: filarr, rightSelected: rightSel }, () => { loadMarkers(this.state.items, this.mapref, this) });
      }else { //no filter
        this.setState({ items: self.order(self.state.orderSelected, false, copyResources), filtersarray: filarr , rightSelected:rightSel}, () => { loadMarkers(this.state.items, this.mapref, this) });
      }


    }

    coverUrl = (id,medialan) => {
      let ser = process.env.REACT_APP_DOMAIN + services.singleresourcemanager_GET_AUTH.replace("{APPNAME}", this.props.appstate.App.name);
      ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
      ser = ser.replace("{RESOURCEID}", id);
      ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
      ser = ser.replace("{COVER}", true);
      ser = ser + "&t=" + new Date().getTime();
      ser = ser.replace("{MEDIALAN}",medialan );
      ser = ser.replace("{MEDIARES}", "first");
      return ser;
    }

    avatarUrl = (id) => {
      let ser = process.env.REACT_APP_DOMAIN + services.avatar_GET_AUTH.replace("{APPNAME}", this.props.appstate.App.name);
      ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
      ser = ser.replace("{USERID}", id);
      return ser;
    }

    resourceUrl = (id) => {
      let ser = process.env.REACT_APP_DOMAIN + services.singleresourcemanager_GET_AUTH.replace("{APPNAME}", this.props.appstate.App.name);
      ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
      ser = ser.replace("{RESOURCEID}", id);
      ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
      ser = ser.replace("{COVER}", false);
      return ser;
    }

    mapCallback = (type, result, mapbound) => {
      switch (type) {
        case "drawcompleted":
        break;
        case "ref":
        this.mapref = result;
        loadMarkers(this.state.items, this.mapref, this);
        //this.filterengine(undefined, undefined, mapbound);
        break;
        case "mapBoundDragend":
        case "mapBoundZoomChanged":
        case "mapBoundonTilesLoaded":
        this.filterengine(undefined, undefined, result,"");
        if (this.state.openmapfromcard && window.google.maps && this.mapref) {
          this.mapref.setCenter(new window.google.maps.LatLng(this.state.openmapfromcardpos[0], this.state.openmapfromcardpos[1]));
          this.setState({ openmapfromcard: false, openmapfromcardpos: []});
        }

        break;
        default:
        break;
      }
    }

    mapMarkerCallback = (type, id, index) => {
      if (type === "delete") {
        this.props.appstate.Modal.openModalDelete = true;
        this.props.appstate.Modal.openModalType = "manager"; //to recognize from resources "emperor"
        this.props.appstate.ResourceManager.index = index;
        this.props.appstate.ResourceManager.id = id;
        const itemcopy = Object.assign({}, this.state.items[index]);
        itemcopy.clientcover = this.coverUrl(id, getResourceDataForLanguage( this.state.items[index]));
        this.props.appstate.ResourceManager.resourceInfo = itemcopy;
        this.props.dispatch(APP(this.props.appstate));
      }
      //let mapmark = {};
      //mapmark[id] = true;
      //this.setState({ mapmark: mapmark});
    }

    filterselection = (event, value) => {
      switch (event.currentTarget.name) {
        case "image":
        this.setState(
          { imagefilter: this.state.imagefilter === true ? false : true },
          () => {
            this.filterengine(
              ["image", "imagecloud"],
              this.state.imagefilter,
              undefined,
              this.state.rightSelected
            );
          }
        );
        break;
        case "audio":
        this.setState(
          { audiofilter: this.state.audiofilter === true ? false : true },
          () => {
            this.filterengine(
              ["audio", "audiocloud"],
              this.state.audiofilter,
              undefined,
              this.state.rightSelected
            );
          }
        );
        break;
        case "video":
        this.setState(
          { videofilter: this.state.videofilter === true ? false : true },
          () => {
            this.filterengine(
              ["video", "videocloud"],
              this.state.videofilter,
              undefined,
              this.state.rightSelected
            );
          }
        );
        break;
        case "map":
        this.setState({ map: this.state.map === true ? false : true }, () => {
          this.filterengine(undefined, undefined, undefined, "");
        }); // caso da controllare
        // this.filterengine();
        break;
        case "publicright":
        if (event.target.checked) {
          this.setState(
            {
              publicRight: event.target.checked,
              ccRight: !event.target.checked,
              otherRight: !event.target.checked,
            },
            () => {
              this.filterengine(undefined, undefined, undefined, "publicright");
            }
          );
        } else {
          this.setState(
            {
              publicRight: event.target.checked,
              ccRight: event.target.checked,
              otherRight: event.target.checked,
            },
            () => {
              this.filterengine(undefined, undefined, undefined, "");
            }
          );
        }
        break;
        case "ccright":
        if (event.target.checked) {
          this.setState(
            {
              publicRight: !event.target.checked,
              ccRight: event.target.checked,
              otherRight: !event.target.checked,
            },
            () => {
              this.filterengine(undefined, undefined, undefined, "ccright");
            }
          );
        } else {
          this.setState(
            {
              publicRight: event.target.checked,
              ccRight: event.target.checked,
              otherRight: event.target.checked,
            },
            () => {
              this.filterengine(undefined, undefined, undefined, "");
            }
          );
        }
        break;
        case "otherright":
        if (event.target.checked) {
          this.setState(
            {
              publicRight: !event.target.checked,
              ccRight: !event.target.checked,
              otherRight: event.target.checked,
            },
            () => {
              this.filterengine(undefined, undefined, undefined, "otherright");
            }
          );
        } else {
          this.setState(
            {
              publicRight: event.target.checked,
              ccRight: event.target.checked,
              otherRight: event.target.checked,
            },
            () => {
              this.filterengine(undefined, undefined, undefined, "");
            }
          );
        }
        break;
        case "document":
        this.setState(
          { documentfilter: this.state.documentfilter === true ? false : true },
          () => {
            this.filterengine(
              ["document", "filecloud", "webcloud", "documentcloud"],
              this.state.documentfilter,
              undefined,
              this.state.rightSelected
            );
          }
        );
        break;
        case "mineFilter":
        this.setState(
          { mineFilter: this.state.mineFilter === true ? false : true },
          () => {
            this.filterengine(
              undefined,
              undefined,
              undefined,
              this.state.rightSelected
            );
          }
        );
        break;
        default:
        break;
      }
    };

    resourcePreview = (event) => {
      // console.log("resourcePreview")
      let index = event.currentTarget.name;
      if (!index) {
        index = event.target.id;
      }

      this.props.appstate.PreviewDialog.openModalPreview = true;
      this.props.appstate.PreviewDialog.render = true;
      console.log(this.state.items[index])
      this.props.appstate.PreviewDialog.item = this.state.items[index];
      if (this.props.appstate.App.user.domainDetail.backupaws===true && this.state.items[index].streamextloaded===true){
        this.props.appstate.PreviewDialog.itemurl = this.state.items[index].streamext;
      }else{
        // this.props.appstate.PreviewDialog.itemurl = this.resourceUrl(this.state.items[index]._id,Object.keys(this.state.items[index].medialan)[0]);
        this.props.appstate.PreviewDialog.itemurl = this.resourceUrl(this.state.items[index]._id,getResourceDataForLanguage(this.state.items[index]));

      }
      this.previewDialogRef.init();

      this.props.dispatch(APP(this.props.appstate));
    };


    qrcode = (event) => {

      this.props.appstate.PreviewDialog.openModalPreview = true;
      this.props.appstate.PreviewDialog.qrcode = true;
      this.props.appstate.PreviewDialog.render = true;
      this.props.appstate.PreviewDialog.item = this.state.items[event.currentTarget.name];
      if (getExternalMediaType().findIndex(x => x.value === this.state.items[event.currentTarget.name].type)!==-1){
        let selectedUri= this.state.items[event.currentTarget.name].url;
        let url="";
        if (typeof(selectedUri)==='string'){ //retrocompatibilità
          url = selectedUri;
        }else {
          let itemHELP={};
          itemHELP.medialan=selectedUri;
          let lang =  getResourceDataForLanguage(itemHELP);
          url = selectedUri[lang];
        }

        this.props.appstate.PreviewDialog.itemurl = url;
      }
      this.previewDialogRef.init();
      // this.props.appstate.PreviewDialog.itemurl=this.props.appstate.PreviewDialog.item.url;
      this.props.dispatch(APP(this.props.appstate));
    }


    tabOrder=(event)=>{
      this.order(event.currentTarget.name, true, this.state.items);
    }

    order=(orderType,orderByInterface,arrayToOrder) =>{
      //ordinamento potrebbe dover essere fatto piu volte anche quando fai edit o quando cambi l'array attenzione
      //nel caso completare implementazione di questa funzione  rendendola chiamabile dall'esterno
      let data=arrayToOrder.slice();
      let orderArray=[];
      // console.log(orderType)
      // console.log(orderByInterface)
      // console.log(data)
      switch (orderType) {
        case "nameup":
        orderArray= itemsSort("name",data);
        if (orderByInterface){
          this.setState({datedown:false, dateup:false, namedown:false, nameup:true,items:orderArray,orderSelected:"nameup"});
        }else{
          return orderArray;
        }
        break;
        case "namedown":
        orderArray= itemsSortInverse("name",data);
        if (orderByInterface){
          this.setState({datedown:false, dateup:false, namedown:true, nameup:false,items:orderArray,orderSelected:"namedown"});
        }else{
          return orderArray;
        }
        break;
        case "dateup":
        orderArray= itemsSortDate("modifiedAt",data);
        if (orderByInterface){
          this.setState({datedown:false, dateup:true, namedown:false, nameup:false,items:orderArray,orderSelected:"dateup"});
        }else{
          return orderArray;
        }
        break;
        case "datedown":
        orderArray= itemsSortInverseDate("modifiedAt",data);
        if (orderByInterface){
          this.setState({datedown:true, dateup:false, namedown:false, nameup:false,items:orderArray,orderSelected:"datedown"});
        }else{
          return orderArray;
        }
        break;
        default:
        break;
      }

    }
    getUTCData=(data)=>{
      return new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'UTC',
        timeZoneName: 'short'}).format(new Date( data ));
      }

      getKeyLang = (strinput) => {
        if (this.props.acamar.configuration.Languages && strinput) {
          let keylang = "en";
          if (strinput[this.props.acamar.configuration.Languages.default]) {
            return this.props.acamar.configuration.Languages.default;
          } else if (strinput[keylang]) {
            return keylang;
          } else {
            let allKey = Object.keys(strinput);
            if (allKey.length > 0) {
              return allKey[0];
            }
          }
        }
        return "";
      }

      dialogResourcesManagerCallBack = () => {
        // document.getElementById("resourcesearchinput").value = "";
        // this.setState({ items: this.props.appstate.ResourceManager.resources});
        this.setState({ items: this.props.appstate.ResourceManager.resources}, () => { this.filterengine(undefined, undefined, undefined, this.state.rightSelected) });
      }

      gotoStory =(item) =>{
        // var pathArray = window.location.pathname.split('/Storyeditor/');
        //   if (item.type==="internal"){
        //   var id = item._id;
        // //  console.log("id "+id);
        //   var urlStoryView =process.env.REACT_APP_STORYVIEW+"?appname="+this.props.appstate.App.name+"&domain="+this.props.appstate.App.user.domain+"&storyid="+id;
        //   window.open(urlStoryView, '_blank');
        //   }else {
        let url="";
        if (typeof(item.url)==='string'){ //retrocompatibilità
          url = item.url;
        }else {
          let itemHELP={};
          itemHELP.medialan=item.url;
          let lang =  getResourceDataForLanguage(itemHELP);
          url = item.url[lang];
        }

        window.open(url, '_blank');
        // }

      }


      handleClickOpenDisclaim = () => {

        this.setState({openDisclaimer:true});
        this.dialogDisclaimer.init(this.props.appstate.App.user.domain);
      }

      handleCloseDisclaim = () => {
        this.setState({openDisclaimer:false});
      };


      /*getEmailDomain = (domValue) => {
      var self=this;
      console.log("dominio "+domValue);
      console.log("ok");
      let serviceurl = process.env.REACT_APP_DOMAIN + services.new_domainsusers_GET;
      console.log(serviceurl);
      serviceurl = serviceurl.replace("{APPNAME}", self.props.acamar.app);
      serviceurl = serviceurl.replace("{ID}", domValue);
      console.log(serviceurl);
      getService(serviceurl,
      "",
      function (err, result) {
      if (err) {
      console.log("errore get user admin domain");
    } else if (result.status === 200) {
    console.log("chiamata ok"+JSON.stringify(result.response[0]));
    let admin=result.response[0].domainusers.filter(adm => adm.role==="domainadmin");
    console.log(admin[0].email);

    self.setState({emailDomain:admin[0].email});
  }
}
);



}
*/

getUriLang =(selectedUri)=>{
  let url="";
  if (typeof(selectedUri)==='string'){ //retrocompatibilità
    url = selectedUri;
  }else {
    let itemHELP={};
    itemHELP.medialan=selectedUri;
    let lang =  getResourceDataForLanguage(itemHELP);
    url = selectedUri[lang];
  }
  return url;
}

render() {
  let jsonlang = this.props.appstate.App.language;
  return (
    <div>
      <DialogDisclaimer open={this.state.openDisclaimer} close={this.handleCloseDisclaim}
        okDisc={this.handleCloseDisclaim}
        onRef={childthis => (this.dialogDisclaimer = childthis)}/>

        <DialogResourcesManager callback={this.dialogResourcesManagerCallBack} onRef={childthis => (this.resourcesManagerDialogRef = childthis)} />
        <DialogDelete callback={this.dialogResourcesManagerCallBack}/>
        {/* <MapDialog onRef={childthis => (this.mapDialogRef = childthis)} />*/}
        <PreviewDialog onRef={childthis => (this.previewDialogRef = childthis)} />
        <AppBar
          className="appbarstyle"
          position="fixed"
          color="default"
          >
            <Typography
              variant='h6'
              justify='center'
              align='center'
              >
                {jsonlang.showroomMUME}
              </Typography>
              <Toolbar >
                <InputBase
                  style={{
                    minWidth: 80,
                    marginLeft: 8
                  }}
                  id="resourcesearchinput"
                  onChange={this.searchInput}
                  placeholder={jsonlang.search}
                  value={this.state.searchValue}
                />
                <Tooltip title={<h2>{jsonlang.search.toUpperCase()}</h2>}>
                <IconButton
                  type="submit"
                  color="primary"
                  aria-label="search"
                  >
                    <Search />
                  </IconButton>
                </Tooltip>
                <Divider
                  style={{
                    height: 28,
                    margin: 4,
                  }}
                  orientation="vertical"
                />
                <Tooltip title={<h2>{jsonlang.cancel.toUpperCase()}</h2>}>
                <CancelRounded
                  color="primary"
                  onClick={this.searchInputCancel}
                  fontSize="large"
                  style={{"margin-right": "4.5em"}}
                />
              </Tooltip>

              {/* filtri data e alfabetico inizio   */}


              <Tooltip title={<h2>{jsonlang.namesort.toUpperCase()}</h2>}>
              <SortByAlphaIcon fontSize="large"/>
            </Tooltip>
            <Tooltip title={<h2>{jsonlang.namesort.toUpperCase()}</h2>}>
            <Checkbox
              checked={this.state.nameup}
              onChange={this.tabOrder}
              name="nameup"
              color="primary"
              icon={<ArrowDropUpIcon fontSize="large"/>}
              checkedIcon={<ArrowDropUpIcon fontSize="large" className="btn-dotted"/>}
            />
          </Tooltip>
          <Tooltip title={<h2>{jsonlang.namesort.toUpperCase()}</h2>}>
          <Checkbox
            checked={this.state.namedown}
            onChange={this.tabOrder}
            name="namedown"
            color="primary"
            icon={<ArrowDropDownIcon fontSize="large"/>}
            checkedIcon={<ArrowDropDownIcon fontSize="large" className="btn-dotted"/>}
          />
        </Tooltip>
        <Divider style={{height: 28,margin: 4}} orientation="vertical" />


        <Tooltip title={<h2>{jsonlang.datesort.toUpperCase()}</h2>}>
        <AccessTimeIcon fontSize="large"/>
      </Tooltip>
      <Tooltip title={<h2>{jsonlang.datesort.toUpperCase()}</h2>}>
      <Checkbox
        checked={this.state.dateup}
        onChange={this.tabOrder}
        name="dateup"
        color="primary"
        icon={<ArrowDropUpIcon fontSize="large"/>}
        checkedIcon={<ArrowDropUpIcon fontSize="large" className="btn-dotted"/>}
      />
    </Tooltip>
    <Tooltip title={<h2>{jsonlang.datesort.toUpperCase()}</h2>}>
    <Checkbox
      checked={this.state.datedown}
      onChange={this.tabOrder}
      name="datedown"
      color="primary"
      icon={<ArrowDropDownIcon fontSize="large"/>}
      checkedIcon={<ArrowDropDownIcon fontSize="large" className="btn-dotted"/>}
    />
  </Tooltip>
  <Divider
    orientation="vertical"
    style={{
      height: 28,
      marginRight: "4.5em"
    }}
  />
  {/* filtri data e alfabetico fine   */}
  <Tooltip title={<h2>{jsonlang.resgeo.toUpperCase()}</h2>}>
  <Checkbox
    checked={this.state.map}
    onChange={this.filterselection}
    name="map"
    color="primary"
    icon={<RoomOutlinedIcon fontSize="large" />}
    checkedIcon={<RoomIcon className="btn-dotted" fontSize="large" />}
  />
</Tooltip>
<Divider
  style={{
    height: 28,
    margin: 4,
  }}
  orientation="vertical"
/>
<Tooltip title={<h2>{jsonlang.resimage.toUpperCase()}</h2>}>
<Checkbox
  checked={this.state.imagefilter}
  onChange={this.filterselection}
  name="image"
  color="primary"
  icon={<ImageOutlinedIcon fontSize="large" />}
  checkedIcon={<ImageIcon className="btn-dotted" fontSize="large" />}
/>
</Tooltip>
<Divider
  style={{
    height: 28,
    margin: 4,
  }}
  orientation="vertical"
/>
<Tooltip title={<h2>{jsonlang.resaudio.toUpperCase()}</h2>}>
<Checkbox
  checked={this.state.audiofilter}
  onChange={this.filterselection}
  name="audio"
  color="primary"
  icon={<AudiotrackOutlinedIcon fontSize="large" />}
  checkedIcon={<AudiotrackIcon className="btn-dotted" fontSize="large" />}
/>
</Tooltip>
<Divider
  style={{
    height: 28,
    margin: 4,
  }}
  orientation="vertical"
/>
<Tooltip title={<h2>{jsonlang.resvideo.toUpperCase()}</h2>}>
<Checkbox
  checked={this.state.videofilter}
  onChange={this.filterselection}
  name="video"
  color="primary"
  icon={<PlayCircleOutlineIcon fontSize="large" />}
  checkedIcon={<PlayCircleOutlineIcon className="btn-dotted" fontSize="large" />}
/>
</Tooltip>
<Divider
  style={{
    height: 28,
    margin: 4,
  }}
  orientation="vertical"
/>
<Tooltip title={<h2>{jsonlang.documentfilter.toUpperCase()}</h2>}>
<Checkbox
  checked={this.state.documentfilter}
  onChange={this.filterselection}
  name="document"
  color="primary"
  icon={<DescriptionIcon fontSize="large" />}
  checkedIcon={<DescriptionIcon className="btn-dotted" fontSize="large" />}
/>
</Tooltip>

{/* <Divider style={{
  height: 28,
  margin: 4
}} orientation="vertical" />
<Tooltip title={<h2>{jsonlang.search.toUpperCase()}</h2>}>
<IconButton color="primary" >
<Search fontSize="large"/>
</IconButton>
</Tooltip>
<TextField
variant="outlined"
id="resourcesearchinput"
onChange={this.searchInput}
/>*/}

{/* <Divider style={{
  height: 28,
  margin: 4
}} orientation="vertical" /> */}

{/* filtri di diritti di autore  */}
{/* <Divider style={{ height: 28, margin: 4 }} orientation="vertical" />
<Tooltip title={<h2>{jsonlang.publicres.toUpperCase()}</h2>}>
<Checkbox
  checked={this.state.publicRight}
  onChange={this.filterselection}
  name="publicright"
  color="primary"
  icon={
    <img
      className="public-rights-img"
      alt="public_icon"
      src="/images/icons/public_dom.png"
    />
  }
  checkedIcon={
    <img
      className="public-rights-img btn-dotted"
      alt="public_icon_selected"
      src="/images/icons/public_dom_sel.png"
    />
  }
  // icon={ <FontAwesomeIcon icon={faBan} />  }
  // checkedIcon={<FontAwesomeIcon icon={faBan} color="blue"/>}
/>
</Tooltip>
<Divider style={{ height: 28, margin: 4 }} orientation="vertical" />
<Tooltip title={<h2>{jsonlang.ccres.toUpperCase()}</h2>}>
<Checkbox
  checked={this.state.ccRight}
  onChange={this.filterselection}
  name="ccright"
  color="primary"
  icon={<FontAwesomeIcon icon={faClosedCaptioning} size="lg" />}
  checkedIcon={
    <FontAwesomeIcon
      icon={faClosedCaptioning}
      color="blue"
      size="lg"
      className="btn-dotted"
    />
  }
/>
</Tooltip>
<Divider style={{ height: 28, margin: 4 }} orientation="vertical" />
<Tooltip title={<h2>{jsonlang.copyrightres.toUpperCase()}</h2>}>
<Checkbox
  checked={this.state.otherRight}
  onChange={this.filterselection}
  name="otherright"
  color="primary"
  icon={<FontAwesomeIcon icon={faCopyright} size="lg" />}
  checkedIcon={
    <FontAwesomeIcon icon={faCopyright} color="blue" size="lg"
      className="btn-dotted"/>
    }
  />
</Tooltip>
<Divider style={{ height: 28, margin: 4 }} orientation="vertical" /> */}
{/* fine filtri diritti di autore  */}
</Toolbar>
</AppBar>
<div>
  <Grid container
    className={this.state.map ? "gridcontainerwithmap" : "gridcontainer"}>
    {this.state.map ?
      <Grid xs={9}>
        <GeoMap
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCtAWW-Sv99CiDFq5i4cYgE_0UBAuQBwXg&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={< div style={{ height: '100%' }} />}
          containerElement={< div className="mapcontainer" />}
          mapElement={< div style={{ height: '98%' }} />}
          options={{
            scrollwheel: false,
            streetViewControl: false,
            minZoom: 1,
            maxZoom: 19,
            mapTypeControl: true,
            zoomControl: true,
          }}
          drawingControl={false}
          onlyMarker={false}
          callback={this.mapCallback}
        /> </Grid> : <></>}
        <Grid
          className="cardcontainer"
          xs={this.state.map ? 1 : 12}>
          {this.state.items.map((item, index) =>
            <Card
              className="cardstyle-sr"
              index={index}
              >
                <CardMedia
                  style={{
                    cursor:"pointer"
                  }}
                  component="img"
                  height="150"
                  id={index}
                  image={item.type === "audio"
                  ? "/images/icons/sound-bars.png"
                  : item.type === "imagecloud"
                  ? item.url ? this.getUriLang(item.url) : "/images/icons/ext_res.png"
                  : item.type === "videocloud"
                  ?  item.uriCoverExternalRes ? item.uriCoverExternalRes : "/images/icons/video.png"
                  : item.type === "audiocloud"
                  ? "/images/icons/sound-bars.png"
                  : item.type === "filecloud"
                  ? "/images/icons/ext_res.png"
                  : item.type === "documentcloud"
                  ? "/images/icons/ext_res.png"
                  : item.type === "textcloud"
                  ? "/images/icons/text.png"
                  : item.type === "webcloud"
                  ?  item.uriCoverExternalRes ? item.uriCoverExternalRes : "/images/icons/ext_res.png"
                  : item.type === "document"
                  ? "/images/icons/document.png"
                  : this.coverUrl(item._id, getResourceDataForLanguage(item))

                }
                title=""
                onClick={()=>this.gotoStory(item)}
              />
              <CardContent style={{height:"90px"}}>
                <Typography variant="body2" style={{fontWeight:900}}>
                  {this.truncate(item.name, 32)}
                </Typography>
                <Typography style={{
                  fontSize: "12px",
                  fontStyle: "italic",
                  margin:"10px 0px 0px 0px"

                }}>
                {item.dcDescription.en ? this.truncate(item.dcDescription.en, 90) : ""}
              </Typography>
            </CardContent>
            <CardActions style={{ display: "flex",
              alignItems: "center",
              justifyContent: "space-between"}}>

              <div style={{ width: '100%' }}>
                <Box display="flex" >
                  <Box flexGrow={1} >
                    <IconButton
                      name={index}
                      id={item._id}
                      onClick={this.urlCopy}
                      >
                        <ShareIcon
                          color="primary"
                          style={{
                            fontSize: 20
                          }}
                        />
                      </IconButton>
                      <IconButton name={index} id={item._id} onClick={this.qrcode}>
                        <FontAwesomeIcon icon={faQrcode} />
                      </IconButton>
                      <IconButton name={index} id={item._id} onClick={this.resourcePreview}>
                        <VisibilityIcon
                          color="primary"
                          style={{
                            fontSize: 20
                          }}
                        />
                      </IconButton>
                    </Box>
                    <Box>
                      {item.CI==="MUME" ?
                      <img alt="domain icon" className="domain-image" src='/images/icons/ico-mume.png'/>
                      :
                      item.CI==="GFR" ?
                      <img alt="domain icon" className="domain-image" src='/images/icons/ico-gfr.png'/> 
                      :
                      item.CI==="DEMO" ?
                      <img alt="domain icon" className="domain-image" src='/images/icons/demo-logo.png'/> 
                      :
                      item.CI==="MONTE" ?
                      <img alt="domain icon" className="domain-image" src='/images/icons/ico-montesole.png'/>
                      :
                      item.CI==="VDA" ?
                      <img alt="domain icon" className="domain-image" src='/images/icons/ico-villadecius.png'/>
                      : ""
                    }
                  </Box>
                </Box>
              </div>
            </CardActions>
          </Card>
        )}
      </Grid>
    </Grid>
  </div>
</div>
)
}
}
export default connect(mapStateToProps)(withRouter(ShowRoomDetail))
