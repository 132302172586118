import React, { Component } from 'react';
import { connect } from 'react-redux';
import { APP } from '../../redux/actions/actions';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Grid,
  Typography,
  // FormControl,
  TextField,
  // InputLabel,
  IconButton,
  MenuItem,
  Tooltip,
  InputAdornment,
  OutlinedInput,
  Switch,
  Fab,
  FormControlLabel,
    Link

} from "@material-ui/core";
import './PreviewDialog.css';
import CloseIcon from '@material-ui/icons/Close';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright,faQrcode,faFilePdf,faFileExcel,faFileWord,faFile  } from '@fortawesome/free-solid-svg-icons';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import AudiotrackOutlinedIcon from '@material-ui/icons/AudiotrackOutlined';
import {
  getRights,
  
} from "../../Utils/utility";
const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class DialogRights extends Component {
constructor(props) {
    super(props);
    // this.state = ({
    // item:{}
    // });
}
 componentDidMount() {
    // this.props.onRef(this); //for the parent
  }

  // init = (senderItem) => {
  //   console.log("senderItem")      
  //   console.log(senderItem)      
  //   this.setState({
  //     item:senderItem
  //   });    
  // }
onclose = () => {
    this.props.appstate.DialogRights.open = false;
    this.props.appstate.DialogRights.item={};
    this.props.dispatch(APP(this.props.appstate));
}
render() {
    let jsonlang = this.props.appstate.App.language;
    return(
        <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.props.appstate.DialogRights.open}
                onClose={this.onclose}
               // TransitionComponent={Transition}
                fullWidth
                maxWidth = {'xl'}
                >
                 <Fab
                  style={{
                    margin: 0,
                    top: 0,
                    right: 0,
                    bottom: 'auto',
                    left: 'auto',
                    position: 'fixed'
                  }}
                  role="button"
                  color="primary"
                  size="large"
                  aria-label="Add"
                  onClick={this.onclose}
                  >
                    <CloseIcon
                    />
                  </Fab>
            <div className="divexitbutton">
            <DialogTitle>
              <span style={{
                                          borderRadius: "50px",
                                          fontSize: "32px"
                                        }}>

              <FontAwesomeIcon icon={faCopyright} size="lg" />
              </span>
             <span style={{                
                                    padding: 10,
                                    position: 'fixed',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: '55vmin',
                                    fontSize: '20px'
                          }}>
              RIGHTS
            </span>
            </DialogTitle>
        <DialogContent dividers style={{fontSize: "18px", height: '100%'}}>  
        <Grid
          container
          direction="row"
          spacing={3}      
          alignItems="right"
          className="gridmargin">

          <Grid item sm={2} xs={2}>
           {(this.props.appstate.DialogRights.item.type === 'image' || this.props.appstate.DialogRights.item.type === 'imagecloud') ? <ImageOutlinedIcon className="fsize35"/> : 
            (this.props.appstate.DialogRights.item.type === 'video' || this.props.appstate.DialogRights.item.type === 'videocloud') ? <PlayCircleOutlineIcon className="fsize35"/>:
            (this.props.appstate.DialogRights.item.type === 'audio' || this.props.appstate.DialogRights.item.type === 'audiocloud') ? <AudiotrackOutlinedIcon className="fsize35"/> :
            (this.props.appstate.DialogRights.item.type === 'document' || this.props.appstate.DialogRights.item.type === 'documentcloud') &&  this.props.appstate.DialogRights.item.extension==="application/pdf"?  <FontAwesomeIcon icon={faFilePdf}   size="4x"/>:
            (this.props.appstate.DialogRights.item.type === 'document' || this.props.appstate.DialogRights.item.type === 'documentcloud') &&  this.props.appstate.DialogRights.item.extension==="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"?  <FontAwesomeIcon icon={faFileExcel}   size="4x"/>:
            (this.props.appstate.DialogRights.item.type === 'document' || this.props.appstate.DialogRights.item.type === 'documentcloud') &&  this.props.appstate.DialogRights.item.extension==="application/vnd.openxmlformats-officedocument.wordprocessingml.document"?  <FontAwesomeIcon icon={faFileWord}   size="4x"/>:
            <FontAwesomeIcon icon={faFile}   size="4x"/>}
            {/* <label for="confirm">{jsonlang.selelang.toUpperCase()}</label> */}
          </Grid>
          <Grid item sm={10} xs={10}>
          {this.props.appstate.DialogRights.item.name}
          </Grid>
          <Grid item sm={2} xs={2} style={{textAlign:"right" }}>
          CREATOR:
          </Grid>
          <Grid item sm={10} xs={10}  > 
           {this.props.appstate.DialogRights.item.dcCreator!=="" ? this.props.appstate.DialogRights.item.dcCreator : "N.A."}
          </Grid>
          <Grid item sm={2} xs={2} style={{textAlign:"right" }}>
          RIGHTS HOLDER:
          </Grid>
          <Grid item sm={10} xs={10}>
            {this.props.appstate.DialogRights.item.dcRightsHolder!=="" ? this.props.appstate.DialogRights.item.dcCreator : "N.A."}
          </Grid>
          <Grid item sm={2} xs={2} style={{textAlign:"right" }}>
             <img
                className="rights-img-rm"
                alt="CC-BY-NC-ND"
                src={
                  this.props.appstate.DialogRights.item.edmRights
                    ? getRights(this.props.appstate.DialogRights.item.edmRights.dcRights).logo
                    : ""
              }    />
          </Grid>
           <Grid item  xs='auto'>
              {
                  this.props.appstate.DialogRights.item.edmRights?
                this.props.appstate.DialogRights.item.edmRights.dcRights:""
                }
              </Grid>
              <Grid item xs='auto'>
               <Link
                    color="inherit"
                    role="link"
                    aria-label="Public Domain - No Copyright"
                    href={
                      this.props.appstate.DialogRights.item.edmRights
                        ? getRights(this.props.appstate.DialogRights.item.edmRights.dcRights).uri
                        : ""
                    }
                    target="blank"
                  >
                {
                      this.props.appstate.DialogRights.item.edmRights
                        ? getRights(this.props.appstate.DialogRights.item.edmRights.dcRights).uri
                        : ""
                    }
                </Link>
              </Grid>
            </Grid>
          
          
        </DialogContent>
          </div>
        </Dialog>
        )
    }
};
export default connect(mapStateToProps)(DialogRights);
