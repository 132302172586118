import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Tooltip
} from "@material-ui/core";

import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import DescriptionIcon from "@material-ui/icons/Description";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const mapStateToProps = (state) => {
    return {
      acamar: state.acamar,
      appstate: state.appstate,
    };
  };
class MenuCard extends Component {
    state = {
      anchorEl: null,
    };
  
    handleClick = event => {
      this.setState({ anchorEl: event.currentTarget });
    };
  
    handleClose = () => {
      this.setState({ anchorEl: null });
    };
  
    render() {
      const { item } = this.props;
      const { anchorEl } = this.state;
      let jsonlang = this.props.appstate.App.language;

      return (
        <ListItem style={{padding:0}}>
          {/* <ListItemSecondaryAction> */}
          <div style={{padding:0}}>
            <IconButton
              aria-label="More"
              aria-owns={anchorEl ? 'long-menu' : null}
              aria-haspopup="true"
              style={{padding:0}}
              onClick={this.handleClick.bind(this)}
              disabled={
                this.props.appstate.App.user.userid === item.owner
                    ? false
                    : true
                } 
            >
              <MoreVertIcon color={this.props.appstate.App.user.userid ===
                            item.owner
                            ? "primary"
                            : "disabled"}
                          />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose.bind(this)}
              PaperProps={{
                style: {
                  maxHeight: 200,
                  width: 80,
                },
              }}
            >              
             {this.props.from!=="catalog"?
              <MenuItem>                
           
                    <Tooltip title={<h2>EDIT</h2>}>
                    <IconButton
                        name={this.props.index}
                        id={item._id}
                        // onClick={this.edit}
                        onClick={() =>{ this.handleClose();this.props.onEdit()}}                        
                        disabled={
                        this.props.appstate.App.user.userid === item.owner
                            ? false
                            : true
                        }
                    >
                        {/* check se utente corrente è owner delle risorsa */}
                        <EditIcon
                        color={
                            this.props.appstate.App.user.userid ===
                            item.owner
                            ? "primary"
                            : "disabled"
                        }
                        style={{ fontSize: 20 }}
                        />
                    </IconButton>
                    </Tooltip>
              </MenuItem>
                :null}
              <MenuItem>
                <Tooltip title={<h2>{jsonlang.remove.toUpperCase()}</h2>}>
                    <IconButton
                        name={this.props.index}
                        id={item._id}
                        onClick={() =>{ this.handleClose();this.props.onDelete()}}                        
                        disabled={
                        this.props.appstate.App.user.userid === item.owner
                            ? false
                            : true
                        }
                    >
                        {/* check se utente corrente è owner delle risorsa */}
                        <DeleteIcon
                        color={
                            this.props.appstate.App.user.userid ===
                            item.owner
                            ? "primary"
                            : "disabled"
                        }
                        style={{ fontSize: 20 }}
                        />
                    </IconButton>
                    </Tooltip>
              </MenuItem>
              <MenuItem>
                <Tooltip title={<h2>{jsonlang.copyreslabel.toUpperCase()}</h2>} >
                        <IconButton
                            name={this.props.index}
                            id={item._id}
                            onClick={() => { this.handleClose();this.props.onCopy()}}
                            disabled={
                            this.props.appstate.App.user.userid === item.owner
                                ? false
                                : true
                            }
                        >
                            {/* check se utente corrente è owner delle risorsa */}
                            <FileCopyIcon
                            color={
                                this.props.appstate.App.user.userid ===
                                item.owner
                                ? "primary"
                                : "disabled"
                            }
                            style={{ fontSize: 20 }}
                            />
                        </IconButton>
                </Tooltip>
              </MenuItem>
            </Menu>
            </div>
          {/* </ListItemSecondaryAction> */}
                   
                          </ListItem>
      )
    }
  }
  export default  connect(mapStateToProps)(withRouter(MenuCard));



