import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  // DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  // IconButton,
  Tooltip,
  Typography
} from "@material-ui/core";

// import BatteryCharging30Rounded from '@material-ui/icons/BatteryCharging30Rounded';

// import CloseIcon from '@material-ui/icons/Close';
import ClearIcon from '@material-ui/icons/Clear';
import { APP } from '../../redux/actions/actions';

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

class DialogLoading extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {

  }

  onclose = () => {
    this.props.appstate.Modal.openModalLoading = false;
    this.props.appstate.Modal.openModalLoadingTextExtra="";
    this.props.appstate.Modal.openModalLoadingText="";
    this.props.dispatch(APP(this.props.appstate));
  }

  render() {
  let jsonlang = this.props.appstate.App.language;
    return (
      <div>
        <Dialog
          open={this.props.appstate.Modal.openModalLoading}
          onClose={this.onclose}
          aria-label="dialog-title"
          fullWidth
          maxWidth={'sm'}
          >
            {/* <DialogTitle id="simple-dialog-title"><BatteryCharging30Rounded fontSize="large"/></DialogTitle> */}
            <DialogContent>
              {this.props.appstate.Modal.openModalLoadingTextActive === false ?
                <Typography
                  justify='center'
                  align='center'
                  >
                    <CircularProgress color="primary"/>
                  </Typography>
                  :
                  <div>
                  <Typography
                    variant='h6'
                    justify='center'
                    align='center'
                    >
                      {this.props.appstate.Modal.openModalLoadingText}
                    </Typography>

                    { this.props.appstate.Modal.openModalLoadingTextExtra ?
                    <Typography
                    variant='h6'
                    justify='center'
                    align='center'
                    noWrap
                    >
                      {this.props.appstate.Modal.openModalLoadingTextExtra}
                    </Typography>
                    :null}
                    </div>
                  }
                </DialogContent>
                {this.props.appstate.Modal.openModalLoadingTextActive === true ?
                  <DialogActions>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={this.onclose}
                      color="primary"
                      startIcon={<ClearIcon />}>
                      <Tooltip title={<h2>{jsonlang.close.toUpperCase()}</h2>}>
                      <Typography variant="button">{jsonlang.close}</Typography>
                    </Tooltip>
                  </Button>
                </DialogActions>
                : <></>}
              </Dialog>
            </div>
          )
        }
      }
      export default connect(mapStateToProps)(DialogLoading);
