import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Box from '@material-ui/core/Box';
// import Grid from '@material-ui/core/Grid';
// import Axios from 'axios';
// import * as data from '../../services/services.json';
import {
  // WHOLE,
  APP } from '../../redux/actions/actions';
  // import ReactDOM from 'react-dom';
  import './Home.css';

  // import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
  // import SettingsIcon from '@material-ui/icons/Settings';

  // =====================================
  // ============ COMPONENTS  ============
  // =====================================
  import {
    AppBar,
    // Box,
    // Button,
    // ButtonGroup,
    // Container,
    // CssBaseline,
    // Dialog,
    // DialogActions,
    // DialogContent,
    // DialogTitle,
    // Divider,
    // Fab,
    // FormControl,
    // FormControlLabel,
    // FormGroup,
    // FormLabel,
    // Grid,
    // Icon,
    // IconButton,
    // InputLabel,
    Link,
    // ListSubheader,
    // Menu,
    // MenuItem,
    // Paper,
    // Radio,
    // RadioGroup,
    // Select,
    // Slide,
    // Switch,
    // TextField,
    // Toolbar,
    Tooltip,
    Typography
  } from "@material-ui/core";

  import ShowRoomDomainsDialog from '../ShowRoom/ShowRoomDomainsDialog';

  const mapStateToProps = state => {
    return {
      acamar: state.acamar,
      appstate: state.appstate
    };
  };

  class Home extends Component {
    // constructor(props) {
    //   super(props);
    // }

    componentDidMount() {
      this.props.appstate.Modal.openModalLoading = false;
      this.props.appstate.Resource.openModalResources = false;
      this.props.appstate.ResourceManager.openModalResourcesManager = false;
        this.props.appstate.ResourceManager.openModalResourcesHeader = false;
      this.props.dispatch(APP(this.props.appstate));
      this.checkUserPrefereces();
    }

    static getDerivedStateFromProps(props, state) {
      if (props.store) {
        return {
          store: props.store
        }
      }


      return null
    }







    checkUserPrefereces=()=>{
      //  console.log("prefer "+ JSON.stringify(this.props.appstate.App.user.preferences));
      if(this.props.appstate.App.user.userid!==""){
        //  console.log("prefer sono loggato");
        if(this.props.appstate.App.user.preferences && this.props.appstate.App.user.preferences.accessibility && this.props.appstate.App.user.preferences.accessibility.fontSize!==""){
          //ho le preferences dell'utente
        }else{
          //sono loggato ma non vi è il campo preferences
          this.props.appstate.App.user.preferences = {};
          this.props.appstate.App.user.preferences.accessibility = {
            contrast:"light",
            yellowLinks:"colornormal",
            underlineLinks:"linksdefault",
            fontSize : "fontnormal",
            familyFont : "familydefault"
          }
          this.props.appstate.App.user.preferences.language=this.props.appstate.App.currentLanguage;
          //apro le dialog per settare le preferenze
          this.props.appstate.Modal.openModalAccessibility=true;
          this.props.appstate.Modal.openModalLanguage=true;
          this.props.dispatch(APP(this.props.appstate));
        }
      }
      else{
        //console.log("prefer non sono loggato");
        //non sono loggato

      }


    }

    loadApp = (event) => {
      switch (event.currentTarget.name) {
        case "storyapp":
        this.props.history.push('/App');
        this.props.appstate.App.currentappchild = process.env.REACT_APP_CAT_STORIE;
        this.props.dispatch(APP(this.props.appstate));
        break;
        case "socialmedia":
    //    console.log("event "+event.currentTarget.name);
        this.props.history.push('/App');
        this.props.appstate.App.currentappchild = process.env.REACT_APP_SOCIAL_MEDIA;
      //  console.log("event "+this.props.appstate.App.currentappchild );
        this.props.dispatch(APP(this.props.appstate));
        break;
        case "annotatorapp":
        this.props.history.push('/App');
        this.props.appstate.App.currentappchild = process.env.REACT_APP_ANNOTATORAPP;
        this.props.dispatch(APP(this.props.appstate));
        break;
        case "semanticsearch":
      //  console.log("event "+event.currentTarget.name);
        this.props.history.push('/App');
        this.props.appstate.App.currentappchild = process.env.REACT_APP_SOCIAL_MEDIA+"/Semantic";
        //console.log("event "+this.props.appstate.App.currentappchild );
        this.props.dispatch(APP(this.props.appstate));
        break;
        default:
        break;
      }
    }

    // loadDialogShowRoomDetail = () => {
    //   if (this.props.appstate.App.user.logged === false) {
    //     this.dialogShowRoomDetailRef.init();
    //     this.props.appstate.ShowRoomDomainsDialog.open = true;
    //     this.props.dispatch(APP(this.props.appstate));
    //   } else {
    //     this.props.history.push({ pathname: '/ShowRoomDetail', search: '?domain=' + this.props.appstate.App.user.domain});
    //   }
    // }

    dialogShowRoomDetailCallback = (result) => {
      //console.log(result);
      // this.props.history.push({ pathname:'/ShowRoomDetail',search:'?domain=' + result });
      this.props.history.push({ pathname:'/ShowRoomDetail'});
    }

    getFooter = () => {
      switch (this.props.appstate.App.name.toUpperCase()) {
        case 'SOCLOSE':
        return(
          // <div>
          //       <Grid container spacing={0}>
          //         <Grid item xs>
          //           <img className="footer-image" src='/images/partner/eu_label.png'/>
          //         </Grid>
          //         <Grid item xs>
          //           <img className="footer-image" src='/images/partner/logo-la-tempesta.png'/>
          //         </Grid>
          //         <Grid item xs>
          //           <img className="footer-image" src='/images/partner/logo-lund-university.png'/>
          //         </Grid>
          //       </Grid>
          //       <Grid container spacing={3}>
          //         <Grid item xs>
          //           <img className="footer-image" src='/images/partner/logo-lund-university.png'/>
          //         </Grid>
          //         <Grid item xs={6}>
          //           <img className="footer-image" src='/images/partner/logo-the-centre-for-research-and-technology-hellas-certh.png'/>
          //         </Grid>
          //         <Grid item xs>
          //           <img className="footer-image" src='/images/partner/logo-the-exile-memorial-museum-consortium.png'/>
          //         </Grid>
          //       </Grid>
          //     </div>
          <AppBar position="fixed" style={{top: 'auto', bottom: 0}}>
            <div className="spaghetto-container">
              <div id="icons-container">

                <div className="single-icon-container">
                  <Link target="_blank" href="https://ec.europa.eu/info/research-and-innovation/funding/funding-opportunities/funding-programmes-and-open-calls/horizon-2020_en" rel="noreferrer">
                  <img src='/images/partner/eu_label.png' alt="Horizon 2020" className="icon-master"/>
                </Link>
              </div>

              <div className="single-icon-container">
                <Link target="_blank" href="https://www.uab.cat/" rel="noreferrer">
                <img src='/images/partner/logo-the-universitat-autonoma-de-barcelona-uab.png' alt="Universitat Autònoma de Barcelona" className="icon"/>
              </Link>
            </div>

            <div className="single-icon-container">
              <Link target="_blank" href="https://www.lunduniversity.lu.se" rel="noreferrer">
              <img src='/images/partner/logo-lund-university.png' alt="Lund University" className="icon"/>
            </Link>
          </div>

          <div className="single-icon-container">
            <Link target="_blank" href="https://willadecjusza.pl/willa-decjusza" rel="noreferrer">
            <img src='/images/partner/logo-villa-decius-association.png' alt="Willa Decjusza" className="icon"/>
          </Link>
        </div>

        <div className="single-icon-container">
          <Link target="_blank" href="https://www.montesole.org" rel="noreferrer">
          <img src='/images/partner/logo-the-peace-school-foundation-of-monte-sole.png' alt="SCUOLA DI PACE DI MONTE SOLE" className="icon"/>
        </Link>
      </div>

      <div className="single-icon-container">
        <Link target="_blank" href="https://www.museuexili.cat/ca/" rel="noreferrer">
        <img src='/images/partner/logo-the-exile-memorial-museum-consortium.png' alt="Museu Memorial de l'Exili" className="icon"/>
      </Link>
    </div>

    <div className="single-icon-container">
      <Link target="_blank" href="https://www.certh.gr/AAC07255.en.aspx" rel="noreferrer">
      <img src='/images/partner/logo-the-centre-for-research-and-technology-hellas-certh.png' alt="Information Technologies Institute (ITI)"  className="icon"/>
    </Link>
  </div>

  <div className="single-icon-container">
    <Link target="_blank" href="https://www.eng.it" rel="noreferrer">
    <img src='/images/partner/logo-engineering-ingegneria-informatica.png' alt="Engineering" className="icon"/>
  </Link>
</div>

<div className="single-icon-container">
  <Link target="_blank" href="https://latempesta.cc/ca.html" rel="noreferrer">
  <img src='/images/partner/logo-la-tempesta.png' alt="La Tempesta" className="icon"/>
</Link>
</div>

<div className="single-icon-container">
  <Link target="_blank" href="https://refugees.gr" rel="noreferrer">
  <img src='/images/partner/logo-the-greek-forum-of-refugees-gfr.png' alt="Greek Forum of Refugees" className="icon"/>
</Link>
</div>
</div>
</div>

</AppBar>



// <div style={{position: 'absolute', bottom: 0}}>
//   <ul>
//     <li>
//       <img className="footer-image" src='/images/partner/eu_label.png'/>
//     </li>
//     <li>
//       <img className="footer-image" src='/images/partner/logo-la-tempesta.png'/>
//     </li>
//     <li>
//       <img className="footer-image" src='/images/partner/logo-lund-university.png'/>
//     </li>
//     <li>
//      <img className="footer-image" src='/images/partner/logo-lund-university.png'/>
//     </li>
//     <li>
//       <img className="footer-image" src='/images/partner/logo-the-exile-memorial-museum-consortium.png'/>
//     </li>
//     <li>
//       <img className="footer-image" src='/images/partner/logo-the-greek-forum-of-refugees-gfr.png'/>
//     </li>
//     <li>
//       <img className="footer-image" src='/images/partner/logo-the-peace-school-foundation-of-monte-sole.png'/>
//     </li>
//     <li>
//       <img className="footer-image" src='/images/partner/logo-the-universitat-autonoma-de-barcelona-uab.png'/>
//     </li>
//     <li>
//       <img className="footer-image" src='/images/partner/logo-villa-decius-assotiation.png'/>
//     </li>
//   </ul>
// </div>



);
case 'VDA':
return(<div></div>);
case 'SMSTAPP':
return(<div></div>);
case 'GREENSCENT':
return(<AppBar position="fixed" style={{top: 'auto', bottom: 0}}>
  <img
    // style={{height: 56}}
    src='/images/partner/barra.png'
    alt="logo-eu_label"/>
  </AppBar>);
  case 'SMST':
  return(<div></div>);
  case 'IDEHA':
  return(<div></div>);
  default:
  return(<div></div>);
}


}


render() {
  let jsonlang = this.props.appstate.App.language;
  let currentconfig = this.props.acamar.configuration;

  return (

    <div>
      <ShowRoomDomainsDialog callback={this.dialogShowRoomDetailCallback} onRef={childthis => (this.dialogShowRoomDetailRef = childthis)} />
      <div
        style={{paddingBottom:56}}
        >

          <div className="heading">
            <Typography variant="h3" display="block">{this.props.acamar.configuration.title}</Typography>
            <Typography variant="h6">{jsonlang.subtitleHome}</Typography>
          </div>
          {/* <ThemeProvider theme={createMuiTheme(this.props.appstate.App.theme.themelink)}> */}

          <>

          <div className="contain">

            <div class={this.props.appstate.App.name.toUpperCase() === "SOCLOSE" || this.props.appstate.App.name.toUpperCase() === "GREENSCENT" ? "row" : "row2"}>
              <div className="row__inner">


                {/* ============================INIZIO ResourcesManager                        */}
                {/* <div className="tile"> */}
                {currentconfig.Section!=null && currentconfig.Section["ResourceManager"]!=null && currentconfig.Section["ResourceManager"] ?
                <div className={this.props.appstate.App.user.logged === true &&  this.props.appstate.App.user.role!=="superadmin"  ? "tile" : "tile disabled"}>
                  <div className="tile__media">
                    <img
                      alt="immagine copertina gallery"
                      className="tile__img"
                      src={this.props.appstate.App.name.toUpperCase() === "GREENSCENT" ? "/images/sfondi-home/gs_create.png" : "/images/sfondi-home/resource_manager.jpg"}
                      onClick={() => { this.props.history.push('/ResourcesManager');}}
                    />
                  </div>
                  <div className="tile_no_details">
                    <div className="tile__title">
                      <Tooltip title={<h2>{jsonlang.resourcemanager.toUpperCase()}</h2>}>
                      <Link
                        rel="noreferrer"
                        role="link"
                        color="inherit"
                        component="button"
                        aria-label={jsonlang.resourcemanager.toUpperCase()}
                        onClick={() => { this.props.history.push('/ResourcesManager');}}
                        >
                          <Typography variant="h6">{jsonlang.resourcemanager.toUpperCase()}</Typography>
                        </Link>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                : <></>
              }

              {/* ============================FINE ResourcesManager */}



              {/* ============================INIZIO showroom  */}
              {currentconfig.Section!=null && currentconfig.Section["Showroom"]!=null && currentconfig.Section["Showroom"] ?

              <div className="tile" style={{height:"230px",widht:"200px"}} >
                <div className="tile__media">
                  <img alt="immagine copertina gallery" style={{height:"230px",widht:"200px"}} className="tile__img"
                    src={this.props.appstate.App.name.toUpperCase() === "GREENSCENT" ? "/images/sfondi-home/gs_explore.png" : "/images/sfondi-home/showroom.jpg"}
                    onClick={this.dialogShowRoomDetailCallback}/>
                  </div>
                  <div className="tile_no_details"> {/* eliminare "no" per attivare classe overlay -> tile__details */}
                  <div className="tile__title">

                    <Tooltip title={<h2>{jsonlang.showroomMUME.toUpperCase()}</h2>}>
                    <Link
                      rel="noreferrer"
                      role="link"
                      color="inherit"
                      component="button"
                      aria-label={jsonlang.showroomMUME.toUpperCase()}
                      onClick={this.dialogShowRoomDetailCallback}
                      >
                        {/* <Typography variant="h6">{jsonlang.showroom.toUpperCase()}</Typography> */}

                          <Typography variant="h6">{jsonlang.showroomMUME.toUpperCase()}</Typography>
                      </Link>
                    </Tooltip>
                  </div>
                </div>
              </div>
              : <></>
            }

            {/* ============================FINE showroom  */}


            {/* ============================INIZIO SOCIAL MEDIA */}
            {currentconfig.Section!=null && currentconfig.Section["SocialMedia"]!=null && currentconfig.Section["SocialMedia"] ?

            <div className={this.props.appstate.App.user.logged === true &&  this.props.appstate.App.user.role!=="superadmin"  ? "tile" : "tile disabled"}>
              <div className="tile__media">
                <img
                  alt="immagine copertina socialmedia"
                  className="tile__img"
                  name="socialmedia"
                  src="/images/sfondi-home/social-media-engagement.jpg"
                  onClick={this.loadApp}
                />
              </div>
              <div className="tile_no_details">
                <div className="tile__title">
                  <Tooltip title={<h2>{jsonlang.socialmedia.toUpperCase()}</h2>}>
                  <Link
                    rel="noreferrer"
                    role="link"
                    name="socialmedia"
                    color="inherit"
                    component="button"
                    aria-label={jsonlang.socialmedia.toUpperCase()}
                    onClick={this.loadApp}
                    >
                      <Typography variant="h6">{jsonlang.socialmedia.toUpperCase()}</Typography>
                    </Link>
                  </Tooltip>
                </div>
              </div>
            </div> : <></>
          }
          {/* ============================FINE SOCIAL MEDIA */}


          {/* ============================INIZIO storycatalogue   */}
          {currentconfig.Section!=null && currentconfig.Section["Catalog"]!=null && currentconfig.Section["Catalog"] ?
          <div className={this.props.appstate.App.user.logged === true &&  this.props.appstate.App.user.role!=="superadmin"? "tile" : "tile disabled"}>
            <div className="tile__media">
              <img
                alt="immagine copertina story catalogue"
                name="storyapp" className="tile__img"
                src={this.props.appstate.App.name.toUpperCase() === "GREENSCENT" ? "/images/sfondi-home/gs_library.png" : "/images/sfondi-home/story_manager.jpg"}
                onClick={() => { this.props.history.push('/Catalog');}}/>
              </div>
              <div className="tile_no_details">
                <div className="tile__title">
                  <Tooltip title={<h2>{jsonlang.storycatalogue.toUpperCase()}</h2>}>
                  <Link
                    rel="noreferrer"
                    name="storyapp"
                    role="link"
                    color="inherit"
                    component="button"
                    aria-label={jsonlang.storycatalogue.toUpperCase()}
                    // onClick={this.loadApp}
                    onClick={() => { this.props.history.push('/Catalog');}}
                    >
                      <Typography variant="h6">{jsonlang.storycatalogue.toUpperCase()}</Typography>
                    </Link>
                  </Tooltip>
                </div>
              </div>
            </div>
            : <></>
          }

          {/* ============================FINE storycatalogue   */}

          {/* ============================INIZIO poimanager   */}
          {currentconfig.Section!=null && currentconfig.Section["POIManager"]!=null && currentconfig.Section["POIManager"] ?

          <div className={this.props.appstate.App.user.logged === true &&  this.props.appstate.App.user.role!=="superadmin"  ? "tile" : "tile disabled"}>
            <div className="tile__media">
              <img
                alt="immagine copertina socialmedia"
                className="tile__img"
                name="luoghi"
                src="/images/sfondi-home/places.jpg"
                onClick={() => { this.props.history.push('/Places');}}
              />
            </div>
            <div className="tile_no_details">
              <div className="tile__title">
                <Tooltip title={<h2>{jsonlang.places.toUpperCase()}</h2>}>
                <Link
                  rel="noreferrer"
                  role="link"
                  name="luoghi"
                  color="inherit"
                  component="button"
                  aria-label={jsonlang.places.toUpperCase()}
                  onClick={() => { this.props.history.push('/Places');}}
                  >
                    <Typography variant="h6">{jsonlang.places.toUpperCase()}</Typography>
                  </Link>
                </Tooltip>
              </div>
            </div>
          </div> : <></>
        }
        {/* ============================FINE poimanager   */}


        {/* ============================INIZIO Semantic Search */}
        {currentconfig.Section!=null && currentconfig.Section["SemanticSearch"]!=null && currentconfig.Section["SemanticSearch"] ?

        <div className={this.props.appstate.App.user.logged === true &&  this.props.appstate.App.user.role!=="superadmin"  ? "tile" : "tile disabled"}>
          <div className="tile__media">
            <img
              alt="immagine copertina Semantic Search"
              className="tile__img"
              name="semanticsearch"
              src="/images/sfondi-home/semantic.jpg"
              onClick={this.loadApp}
            />
          </div>
          <div className="tile_no_details">
            <div className="tile__title">
              <Tooltip title={<h2>{jsonlang.semanticsearch.toUpperCase()}</h2>}>
              <Link
                rel="noreferrer"
                role="link"
                name={jsonlang.semanticsearch.toUpperCase()}
                color="inherit"
                component="button"
                aria-label={jsonlang.semanticsearch.toUpperCase()}
                onClick={this.loadApp}
                >
                  <Typography variant="h6">{jsonlang.semanticsearch.toUpperCase()}</Typography>
                </Link>
              </Tooltip>
            </div>
          </div>
        </div> : <></>
      }
      {/* ============================FINE Semantic Search */}


      </div>
    </div>

  </div>

</>
{/* <Box display="flex" justifyContent="center" m={1} p={1}>
<Box>
{'Copyright © '}
<Link
role="link"
color="inherit"
aria-label="SO CLOSE HOME PAGE"
href="https://so-close.eu"

target="blank"
>
<span>SO CLOSE</span>
</Link>{' '}
{new Date().getFullYear()}
{' '}
</Box>
</Box> */}
{/* </ThemeProvider> */}
</div>
{this.getFooter()}
</div>
)
}
}

export default connect(mapStateToProps)(Home);
