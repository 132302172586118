// import * as services from "./services.json";
import Axios from "axios";

export function postService(service, inputdata, token, header, callback) {
    Axios.defaults.headers.common["Authorization"] =
        "Bearer " + token;
      //  const headers = { headers: { "content-type": "application/json" } };
        let headers="";
        if(header==="")
          headers = { headers: { "content-type": "application/json" } };
        else headers=header;

    // const headers = { headers: {  'Content-Type': 'application/x-www-form-urlencoded' } };
    Axios.post(service, inputdata, headers)
        .then(function (response) {
            if (response.status === 201) {
                callback(undefined, {
                    status: response.status,
                    response: response.data.message,
                });
                return;
            }
            callback(undefined, {
                status: response.status,
                response: response.data.message,
            });

        })
        .catch(function (error) {
             callback(error, undefined);
        });
}
/*services.domain +
  services.video_group_GET_AUTH.replace("[group]", self.props.reducer.group);*/
export function getService(querystring, token, callback) {
    Axios.defaults.headers.common["Authorization"] =
        "Bearer " + token;
    Axios.get(
        querystring
    )
        .then(function (response) {
            if (response.status === 200) {
                callback(undefined, { status: response.status, response: response.data.message });
                return;
            }
            callback(undefined, {
                status: response.status,
                response: undefined,
            });
            return;
        })
        .catch(function (error) {
            callback(error, undefined);
           /* if (error.response.status === 401) {
                callback(error, undefined);
                //token expired
            }*/
            return;
        });
}

export function deleteService(service, inputdata, token, callback) {
    // var self = this;
    Axios.defaults.headers.common["Authorization"] =
      "Bearer " + token;
    Axios({
      method: "delete",
      url: service,
      data: inputdata //json
    })
      .then(function (response) {
          if (response.status === 200) {//il codice 204 non mi fa tornare dati dalla richiesta mi serve per il delete delle lingue della risorsa trovare alternativa
                            
                            callback(undefined, {
                              status: response.status,
                              response: response.data.message,
                            });
              return;
          }          
          if (response.status === 204) {
                            
                            callback(undefined, {
                              status: response.status,
                              response: response.data.message,
                            });
              return;
          }
           callback(undefined, {
             status: response.status,
             response: undefined,
           });
          return;
      })
        .catch(function (error) { callback(error, undefined);});
}

export function multipartformService(service, inputdata, token, file, callback) {
    var formData = new FormData();
    Object.keys(inputdata).forEach(function (key) {
        formData.set(key, inputdata[key]);
    })
    formData.set('resource', file);
    const headers = { headers: { 'Authorization': 'Bearer ' + token, 'content-type': 'multipart/form-data' } }
    Axios.post(service, formData, headers).then(
        function (response) {
            if (response.status === 201) {
                callback(undefined, {
                    status: response.status,
                    response: response.data.message,
                });
                return;
            }
            callback(undefined, {
                status: response.status,
                response: response.data.message,
            });
        }).catch(
            function (error) {
                callback(error, undefined);
            })
}


export function getStreamFileService(url, token, callback) {
    Axios.defaults.headers.common["Authorization"] ="Bearer " + token;
    Axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // Important
        }).then(function(response){     
                if (response.status === 200) {
                        callback(undefined, response);
                        return;
                    }
        }).catch(function (error) {
                    callback(error, undefined);           
                    return;
        });
}