import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { APP } from '../../redux/actions/actions';
import * as services from "../../services/services.json";
import FlagsDialog from './FlagsDialog';
import LanDialog from './LanDialog';
import InfoDialog from './InfoDialog';
import languages from '../../config/languages.json';
// import languages2 from '../../config/languages.json';
// import * as services from "../../services/services.json";
// import { getService, postService } from "../../services/services";
// import { makeStyles } from '@material-ui/core/styles';
// import * as mediatypes from "../../Utils/mediatype.json";
import './PlacesEditManager.css';
// import TextCloudHandler from "./TextCloudHandler"
import  WeakResourceHandler from "./WeakResourceHandler"
import {
  TextField,
  Tooltip,
  MenuItem,
  // Switch,
  Grid,
  // Divider,
  IconButton,
  // OutlinedInput,
  Select,
  InputLabel,
  // FormControl,
  ListItemIcon,
  Typography,
  Link,
  // Checkbox,
  // Toolbar,
  // Button
} from "@material-ui/core";

import {
  getRights,
  // getExternalMediaType
} from "../../Utils/utility";
import RoomIcon from '@material-ui/icons/Room';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LanguageIcon from '@material-ui/icons/Language';
// import InfoIcon from '@material-ui/icons/Info';
// import CloudOffIcon from '@material-ui/icons/CloudOff';
// import CloudDoneIcon from '@material-ui/icons/CloudDone';
// import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

class PlacesEditManager extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      name: "",
      description: {},
      title: {},
      file: undefined,
      filename: "",
      rights: getRights("all"),
      selectedRight: getRights("CC0").value,
      mediaLanguageList: this.props.acamar.configuration.Languages.list,
      selectedMediaLanguage: "en",
      dataLanguage: "",
      degreeMedia: false,
      degreeMediaSelected: 2,
      langfilter: new Array(this.props.acamar.configuration.Languages.list.length).fill(false),
      titlestate: "",
      descstate: "",
      linkRight: getRights("CC0").uri,
      creator: "",
      dcRightsHolder:"",
      backupstatus:true,
      //new prop
      medialan:{},
      folderIdentifier:"",
      currentMediaLan:"",
      arraymediares:[],

      deleteLanguageFlag:false,
      uriCoverExternalRes:"",
      publicStatusExtRes:false,
      gravity:0,


    })
  }

  componentDidMount() {
    //this.props.onRef(this);
    this.firstInit()
  }

  firstInit=(lan)=>{
    let itemData = this.props.appstate.ResourceManager.resources[this.props.appstate.ResourceManager.index];
    let currentMediaLan_help="";
    let arraymediares_help="";
    if (lan){
      currentMediaLan_help=lan;
      arraymediares_help=itemData.medialan[lan];
    }else {
      currentMediaLan_help=Object.keys(itemData.medialan)[0]
      arraymediares_help=itemData.medialan[Object.keys(itemData.medialan)[0]]
    }
    let parseDescription;
    try {
      parseDescription = itemData.description;
    } catch (e) {
      parseDescription = "";
    }
    let parseTitle;
    try {
      parseTitle = itemData.title;
    } catch (e) {
      parseTitle = "";
    }
    let parseHls;
    try {
      parseHls = itemData.hls;
    } catch (e) {
      parseHls = "";
    }
    let parseDash;
    try {
      parseDash = itemData.dash;
    } catch (e) {
      parseDash = "";
    }
    console.log(typeof(itemData.url))
    console.log("itemData")
    console.log(itemData)

    this.setState({
      name: itemData.name,
      descstate: itemData.description ? itemData.description[this.getKeyLang(parseDescription)] : "",
      description: parseDescription,
      title: parseTitle,
      titlestate: itemData.title ? itemData.title[this.getKeyLang(parseTitle)] : "",
      filename: itemData.fsname,
      file: undefined,
      selectedMediaLanguage: itemData.mediaLanguage,
      selectedRight: itemData.edmRights ? itemData.edmRights.dcRights : "",
      linkRight: itemData.edmRights ? itemData.edmRights.uri : "",
      id: itemData._id,
      creator: itemData.dcCreator ? itemData.dcCreator : "",
      degreeMediaSelected: itemData.degree,
      degreeMedia: parseInt(itemData.degree) === 3 ? true : false,
      type:itemData.type,
      url:itemData.url,
      dcRightsHolder:itemData.dcRightsHolder?itemData.dcRightsHolder:"",
      backupstatus:itemData.streamextloaded,
      //new prop
      medialan:itemData.medialan,
      medialanextload:itemData.medialanextload,
      folderIdentifier:itemData.folderIdentifier,
      currentMediaLan:currentMediaLan_help,
      arraymediares:arraymediares_help,
      deleteLanguageFlag:false,
      uriCoverExternalRes:itemData.uriCoverExternalRes ?itemData.uriCoverExternalRes : "" ,
      publicStatusExtRes:itemData.publicStatusExtRes ?itemData.publicStatusExtRes : false,
      gravity:itemData.gravity ? itemData.gravity : 0

    },this.worldLangHandler(this.getKeyLang(itemData.description)));
    this.props.appstate.MapDialog.position.lat = itemData.location.coordinates[1];
    this.props.appstate.MapDialog.position.lng = itemData.location.coordinates[0];
    this.props.dispatch(APP(this.props.appstate));
  }


  textfiedlvalue = (event) => {
    switch (event.target.id) {
      case "nameres":
      this.setState({ name: event.target.value }, this.props.dataform({
        name: event.target.value,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degree: this.state.degreeMediaSelected,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        id: this.state.id,
        creator: this.state.creator,
        url:this.state.url,
        type:this.state.type,
        dcRightsHolder:this.state.dcRightsHolder    ,folderIdentifier:this.state.folderIdentifier,
        medialanToSend:this.state.currentMediaLan,
        mediaresToSend:this.state.mediaresToSend,
        uriCoverExternalRes:this.state.uriCoverExternalRes,
        publicStatusExtRes:this.state.publicStatusExtRes,
        gravity:this.state.gravity
      },
      // document.getElementById('file-input').files[0]));
      this.state.file ? this.state.file:null));
      break;
      case "descres":
      this.setState({ descstate: event.target.value });
      // this.setState({ description: event.target.value }, this.props.dataform({ name: this.state.name, description: event.target.value }, document.getElementById('file').files[0]));
      break;
      case "title":
      this.setState({ titlestate: event.target.value });
      // this.setState({ description: event.target.value }, this.props.dataform({ name: this.state.name, description: event.target.value }, document.getElementById('file').files[0]));
      break;
      case "creator":
      this.setState({ creator: event.target.value }, this.props.dataform({
        name: this.state.name,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degree: this.state.degreeMediaSelected,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        id: this.state.id,
        creator: event.target.value,
        url:this.state.url,
        type:this.state.type,
        dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
        medialanToSend:this.state.currentMediaLan,
        mediaresToSend:this.state.mediaresToSend,
        uriCoverExternalRes:this.state.uriCoverExternalRes,
        publicStatusExtRes:this.state.publicStatusExtRes,
        gravity:this.state.gravity
      },
      this.state.file ? this.state.file:null));
      // document.getElementById('file-input').files[0]));
      // this.setState({ description: event.target.value }, this.props.dataform({ name: this.state.name, description: event.target.value }, document.getElementById('file').files[0]));
      break;
      case "url":
      this.setState({ url:event.target.value},this.props.dataform({
        name: this.state.name,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degree: this.state.degreeMediaSelected,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        id: this.state.id,
        creator: this.state.creator,
        url:event.target.value,
        type:this.state.type,
        dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
        medialanToSend:this.state.currentMediaLan,
        mediaresToSend:this.state.mediaresToSend,
        uriCoverExternalRes:this.state.uriCoverExternalRes,
        publicStatusExtRes:this.state.publicStatusExtRes,
        gravity:this.state.gravity


      },
      null));
      // this.setState({ description: event.target.value }, this.props.dataform({ name: this.state.name, description: event.target.value }, document.getElementById('file').files[0]));
      break;
      case "dcRightsHolder":
      this.setState({ dcRightsHolder:event.target.value},this.props.dataform({
        name: this.state.name,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degree: this.state.degreeMediaSelected,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        id: this.state.id,
        creator: this.state.creator,
        url:this.state.url,
        type:this.state.type,
        dcRightsHolder:event.target.value,folderIdentifier:this.state.folderIdentifier,
        medialanToSend:this.state.currentMediaLan,
        mediaresToSend:this.state.mediaresToSend,
        uriCoverExternalRes:this.state.uriCoverExternalRes,
        publicStatusExtRes:this.state.publicStatusExtRes,
        gravity:this.state.gravity

      },
      null));
      // this.setState({ description: event.target.value }, this.props.dataform({ name: this.state.name, description: event.target.value }, document.getElementById('file').files[0]));
      break;
      case "coverUrl":
      this.setState({ uriCoverExternalRes:event.target.value},this.props.dataform({
        name: this.state.name,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degree: this.state.degreeMediaSelected,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        id: this.state.id,
        creator: this.state.creator,
        url:this.state.url,
        type:this.state.type,
        dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
        medialanToSend:this.state.currentMediaLan,
        mediaresToSend:this.state.mediaresToSend,
        uriCoverExternalRes:event.target.value,
        publicStatusExtRes:this.state.publicStatusExtRes,
        gravity:this.state.gravity

      },
      null));
      break;
      default:
      break;

    }
  }

  saveDescForLang = () => {
    let descCopy = Object.assign({}, this.state.description);
    if (this.state.descstate === "") {
      // se l'elemento di lingua esiste lo cancelllo altrimenti niente
      if (descCopy[this.state.dataLanguage]) {
        delete descCopy[this.state.dataLanguage]
      }
    } else {
      //creo o aggiorno l'elemento di lingua
      descCopy[this.state.dataLanguage] = this.state.descstate;
    }
    this.setState({ description: descCopy }, this.props.dataform({
      name: this.state.name,
      description: descCopy,
      title: this.state.title,
      selectedRight: this.state.selectedRight,
      linkRight: this.state.linkRight,
      degree: this.state.degreeMediaSelected,
      selectedMediaLanguage: this.state.selectedMediaLanguage,
      id: this.state.id,
      creator: this.state.creator,
      url:this.state.url,
      type:this.state.type,
      dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
      medialanToSend:this.state.currentMediaLan,
      mediaresToSend:this.state.mediaresToSend,
      uriCoverExternalRes:this.state.uriCoverExternalRes,
      publicStatusExtRes:this.state.publicStatusExtRes,
      gravity:this.state.gravity
    },
    this.state.file ? this.state.file:null));
    // document.getElementById('file-input').files[0]));
  }


  saveTitleForLang = () => {
    let titleCopy = Object.assign({}, this.state.title);
    if (this.state.titlestate === "") {
      // se l'elemento di lingua esiste lo cancelllo altrimenti niente
      if (titleCopy[this.state.dataLanguage]) {
        delete titleCopy[this.state.dataLanguage]
      }
    } else {
      //creo o aggiorno l'elemento di lingua
      titleCopy[this.state.dataLanguage] = this.state.titlestate;
    }
    this.setState({ title: titleCopy }, this.props.dataform({
      name: this.state.name,
      description: this.state.description,
      title: titleCopy,
      selectedRight: this.state.selectedRight,
      linkRight: this.state.linkRight,
      degree: this.state.degreeMediaSelected,
      selectedMediaLanguage: this.state.selectedMediaLanguage,
      id: this.state.id,
      creator: this.state.creator,
      url:this.state.url,
      type:this.state.type,
      dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
      medialanToSend:this.state.currentMediaLan,
      mediaresToSend:this.state.mediaresToSend,
      uriCoverExternalRes:this.state.uriCoverExternalRes,
      publicStatusExtRes:this.state.publicStatusExtRes,
      gravity:this.state.gravity
    },
    this.state.file ? this.state.file:null));    // document.getElementById('file-input').files[0]));
  }

  showMap = () => {
    this.props.appstate.MapDialog.render = true;
    this.props.appstate.MapDialog.openModalMap = true;
    this.props.dispatch(APP(this.props.appstate));
  }
  handleChangeRight = (e) => {
    //console.log(e.target.value);
    this.setState({ selectedRight: e.target.value, linkRight: getRights(e.target.value).uri }, this.props.dataform({
      name: this.state.name,
      description: this.state.description,
      title: this.state.title,
      selectedRight: e.target.value,
      linkRight: getRights(e.target.value).uri,
      degree: this.state.degreeMediaSelected,
      selectedMediaLanguage: this.state.selectedMediaLanguage,
      id: this.state.id,
      creator: this.state.creator,
      url:this.state.url,
      type:this.state.type,
      dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
      medialanToSend:this.state.currentMediaLan,
      mediaresToSend:this.state.mediaresToSend,
      uriCoverExternalRes:this.state.uriCoverExternalRes,
      publicStatusExtRes:this.state.publicStatusExtRes,
      gravity:this.state.gravity
    },
    this.state.file ? this.state.file:null));
    // document.getElementById('file-input').files[0]));
  }
  handleChangeMediaLanguage = (e) => {
    //console.log(e.target.value)
    this.setState({ selectedMediaLanguage: e.target.value }, this.props.dataform({
      name: this.state.name,
      description: this.state.description,
      title: this.state.title,
      selectedRight: this.state.selectedRight,
      linkRight: this.state.linkRight,
      degree: this.state.degreeMediaSelected,
      selectedMediaLanguage: e.target.value,
      id: this.state.id,
      creator: this.state.creator,
      url:this.state.url,
      type:this.state.type,
      dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
      medialanToSend:this.state.currentMediaLan,
      mediaresToSend:this.state.mediaresToSend,
      uriCoverExternalRes:this.state.uriCoverExternalRes,
      publicStatusExtRes:this.state.publicStatusExtRes,
      gravity:this.state.gravity
    },
    this.state.file ? this.state.file:null));
    // document.getElementById('file-input').files[0]));
  }
  handleChangeDataLanguage = (e) => {
    //gestione filtro
    let langfiltercopy = this.state.langfilter.slice().fill(false);
    // langfiltercopy[e.target.id]=e.target.checked;
    langfiltercopy[e.target.id] = true;
    let currentDescription = this.state.description[this.state.mediaLanguageList[e.target.id].code];
    let currentTitle = this.state.title[this.state.mediaLanguageList[e.target.id].code];
    if (!currentTitle) {
      currentTitle = "";
    }
    if (!currentDescription) {
      currentDescription = "";
    }
    this.setState({ dataLanguage: this.state.mediaLanguageList[e.target.id].code, langfilter: langfiltercopy, titlestate: currentTitle, descstate: currentDescription },
      this.props.dataform({
        name: this.state.name,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degree: this.state.degreeMediaSelected,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        id: this.state.id,
        creator: this.state.creator,
        url:this.state.url,
        type:this.state.type,
        dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
        medialanToSend:this.state.currentMediaLan,
        mediaresToSend:this.state.mediaresToSend,
        uriCoverExternalRes:this.state.uriCoverExternalRes,
        publicStatusExtRes:this.state.publicStatusExtRes,
        gravity:this.state.gravity
      },
      this.state.file ? this.state.file:null));
      // document.getElementById('file-input').files[0])
      // );
    }
    newMediaLanHandler=(language)=>{
      // let langfiltercopy = this.state.langfilter.slice().fill(false);
      let lan= language;
      // check controllo se hls esiste
      let currentHls="";
      if  (this.state.hls){
        currentHls = this.state.hls[lan.toLowerCase()];
        if (!currentHls) {
          currentHls = "";
        }
      }
      let currentDash = "";
      if  (this.state.dash){
        currentDash =  this.state.dash[lan.toLowerCase()];
        if (!currentDash) {
          currentDash = "";
        }
      }



      let arraymediares_help = this.state.medialan[lan.toLowerCase()];
      let deleteLanguageFlag_help=false;
      if (!arraymediares_help) {
        arraymediares_help = [];
        deleteLanguageFlag_help=true;
      }
      this.setState({
        arraymediares:arraymediares_help,
        currentMediaLan:lan.toLowerCase(),
        deleteLanguageFlag:deleteLanguageFlag_help,
      });
    }
    worldLangHandler = (lang) => {
      let langfiltercopy = this.state.langfilter.slice().fill(false);
      let currentDescription = this.state.description[lang.toLowerCase()];
      let currentTitle = this.state.title[lang.toLowerCase()];
      if (!currentTitle) {
        currentTitle = "";
      }
      if (!currentDescription) {
        currentDescription = "";
      }
      this.setState({ dataLanguage: lang.toLowerCase(), langfilter: langfiltercopy, titlestate: currentTitle, descstate: currentDescription },
      this.props.dataform({
        name: this.state.name,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degree: this.state.degreeMediaSelected,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        dataLanguage: lang.toLowerCase(),
        id: this.state.id,
        creator: this.state.creator,
        url:this.state.url,
        type:this.state.type,
        dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
        medialanToSend:this.state.currentMediaLan,
        mediaresToSend:this.state.mediaresToSend,
        uriCoverExternalRes:this.state.uriCoverExternalRes,
        publicStatusExtRes:this.state.publicStatusExtRes,
        gravity:this.state.gravity
      },
      this.state.file ? this.state.file:null));
      // document.getElementById('file-input').files[0]));
      // this.setState({dataLanguage:lang.toLowerCase(),langfilter:langfiltercopy});
    }
    handleDegreeType = (e) => {
      let internalDegree = "";
      if (e.target.checked) {
        internalDegree = 3;
      } else {
        internalDegree = 2;
      }
      this.setState({ degreeMedia: e.target.checked, degreeMediaSelected: internalDegree }, this.props.dataform({
        name: this.state.name,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degree: internalDegree,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        id: this.state.id,
        creator: this.state.creator,
        url:this.state.url,
        type:this.state.type,
        dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
        medialanToSend:this.state.currentMediaLan,
        mediaresToSend:this.state.mediaresToSend,
        uriCoverExternalRes:this.state.uriCoverExternalRes,
        publicStatusExtRes:this.state.publicStatusExtRes,
        gravity:this.state.gravity
      },
      this.state.file ? this.state.file:null));
      // document.getElementById('file-input').files[0]));
    }

    getKeyLang = (strinput) => {
      if (this.props.acamar.configuration.Languages && strinput) {
        let keylang = "en";
        if (strinput[this.props.acamar.configuration.Languages.default]) {
          return this.props.acamar.configuration.Languages.default;
        } else if (strinput[keylang]) {
          return keylang;
        } else {
          let allKey = Object.keys(strinput);
          if (allKey.length > 0) {
            return allKey[0];
          }
        }
      }
      return "";
    }

    coverUrl = (id, type,mediaData) => {
      let ser = process.env.REACT_APP_DOMAIN + services.singleresourcemanager_GET_AUTH.replace("{APPNAME}", this.props.appstate.App.name);
      ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
      ser = ser.replace("{RESOURCEID}", id);
      ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
      if (type === "audio" || type === "video") {
        ser = ser.replace("{COVER}", false);
      } else {
        ser = ser.replace("{COVER}", true);
      }
      ser=ser.replace("{MEDIARES}",mediaData.resolution );
      ser=ser.replace("{MEDIALAN}",mediaData.lan);
      return ser + "&t=" + new Date().getTime();
    }

    openFlagsDialog = () => {
      this.flagsDialogRef.init();
      this.props.appstate.FlagsDialog.render = true;
      this.props.appstate.FlagsDialog.openModalFlags = true;
      let titlearr = Object.keys(this.state.title);
      let descarr = Object.keys(this.state.description);
      if (titlearr.length >= descarr.length) {
        this.props.appstate.FlagsDialog.items = titlearr;
      } else {
        this.props.appstate.FlagsDialog.items = descarr;
      }
      this.props.dispatch(APP(this.props.appstate));
    }

    openInfoDialog = (event) => {
      console.log(event.currentTarget.name)
      this.props.appstate.InfoDialog.render = true;
      this.props.appstate.InfoDialog.openModalInfo = true;
      this.props.appstate.InfoDialog.tagElement = event.currentTarget.name;
      this.props.appstate.InfoDialog.lan=this.state.currentMediaLan;
      this.props.appstate.InfoDialog.items = this.props.appstate.ResourceManager.resources[this.props.appstate.ResourceManager.index];
      this.props.dispatch(APP(this.props.appstate));
      this.infoDialogRef.init();
    }


    openLanDialog = () => {
      this.lanDialogRef.init();
      this.props.appstate.LanDialog.render = true;
      this.props.appstate.LanDialog.openModalLan = true;
      let arrayLan=[];
      Object.keys(this.state.medialan).forEach(function(key) {
        arrayLan.push(key);});
        this.props.appstate.LanDialog.items= arrayLan;
        this.props.dispatch(APP(this.props.appstate));
      }

      callBackFlagsDialog = (lang) => {
        this.worldLangHandler(lang);
      }
      callBackLanDialog= (lang) => {
        this.newMediaLanHandler(lang)
      }
      selectMediaLanguage=(event)=>{
        this.newMediaLanHandler(event.target.value);
      }
      selectLanguage = (event) => {
        this.worldLangHandler(event.target.value);
      }
      handleChangeExternalMediaType=(e)=>{
        // this.setState({selectedExternalMediaType:e.target.value});
        this.setState({type:e.target.value, uriCoverExternalRes:"" ,publicStatusExtRes:false },this.props.dataform({
          name: this.state.name,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degree: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          id: this.state.id,
          creator: this.state.creator,
          url:this.state.url,
          type:e.target.value,
          dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
          medialanToSend:this.state.currentMediaLan,
          mediaresToSend:this.state.mediaresToSend,
          uriCoverExternalRes:"",
          publicStatusExtRes:false,
          hls:this.state.hls,
          dash:this.state.dash,textcloudcontent:this.state.textcloudcontent,
          gravity:this.state.gravity
        },
        null));
      }

      deleteLanguage=()=>{
        console.log("delete lang");
        let item= this.props.appstate.ResourceManager.resources[this.props.appstate.ResourceManager.index];
        let data={
          domain:this.props.appstate.App.user.domain,
          id: item._id,
          user: this.props.appstate.App.user.userid,
          appname: this.props.appstate.App.name,
          medialan: this.state.currentMediaLan
        }
        this.props.deleteLan(data)
      }

      handlePublicStatusExternalWebResource=(e)=>{
        this.setState({ publicStatusExtRes: e.target.checked }, this.props.dataform({
          name: this.state.name,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degree: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          id: this.state.id,
          creator: this.state.creator,
          url:this.state.url,
          type:this.state.type,
          dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
          medialanToSend:this.state.currentMediaLan,
          mediaresToSend:this.state.mediaresToSend,
          publicStatusExtRes:e.target.checked,
          uriCoverExternalRes:this.state.uriCoverExternalRes,
          gravity:this.state.gravity
        },
        this.state.file ? this.state.file:null));
      }

      dataformText=(textObj)=>{
        console.log("dataformText")
        console.log(textObj)
        this.setState({ textcloudcontent: textObj }, this.props.dataform({
          name: this.state.name,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degree: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          id: this.state.id,
          creator: this.state.creator,
          url:this.state.url,
          type:this.state.type,
          dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
          medialanToSend:this.state.currentMediaLan,
          mediaresToSend:this.state.mediaresToSend,
          publicStatusExtRes:this.state.publicStatusExtRes,
          uriCoverExternalRes:this.state.uriCoverExternalRes,
          hls:this.state.hls,
          dash:this.state.dash,
          textcloudcontent: textObj,
          gravity:this.state.gravity
        },
        this.state.file ? this.state.file:null));
        // this.props.dataform({});
      }
      //funzione chiamata dal componente Weak che salva i dati della sezione upload per le weak resource
      dataformWeakRes=(data,type)=>{
        console.log("dataformWeakRes");
        switch (type) {
          case "coverUrl":
          this.setState({ uriCoverExternalRes:data},this.props.dataform({
            name: this.state.name,
            description: this.state.description,
            title: this.state.title,
            selectedRight: this.state.selectedRight,
            linkRight: this.state.linkRight,
            degree: this.state.degreeMediaSelected,
            selectedMediaLanguage: this.state.selectedMediaLanguage,
            id: this.state.id,
            creator: this.state.creator,
            url:this.state.url,
            type:this.state.type,
            dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
            medialanToSend:this.state.currentMediaLan,
            mediaresToSend:this.state.mediaresToSend,
            uriCoverExternalRes:data,
            publicStatusExtRes:this.state.publicStatusExtRes,
            hls:this.state.hls,
            dash:this.state.dash
            ,textcloudcontent:this.state.textcloudcontent,
            gravity:this.state.gravity
          },
          null));
          break;
          case "publicStatus":
          this.setState({ publicStatusExtRes: data }, this.props.dataform({
            name: this.state.name,
            description: this.state.description,
            title: this.state.title,
            selectedRight: this.state.selectedRight,
            linkRight: this.state.linkRight,
            degree: this.state.degreeMediaSelected,
            selectedMediaLanguage: this.state.selectedMediaLanguage,
            id: this.state.id,
            creator: this.state.creator,
            url:this.state.url,
            type:this.state.type,
            dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
            medialanToSend:this.state.currentMediaLan,
            mediaresToSend:this.state.mediaresToSend,
            publicStatusExtRes:data,
            uriCoverExternalRes:this.state.uriCoverExternalRes,
            hls:this.state.hls,
            dash:this.state.dash,textcloudcontent:this.state.textcloudcontent,
            gravity:this.state.gravity
          },
          this.state.file ? this.state.file:null));

          break;
          case "degreeMedia":
          let internalDegree = "";
          if (data) {
            internalDegree = 3;
          } else {
            internalDegree = 2;
          }
          this.setState({ degreeMedia: data, degreeMediaSelected: internalDegree }, this.props.dataform({
            name: this.state.name,
            description: this.state.description,
            title: this.state.title,
            selectedRight: this.state.selectedRight,
            linkRight: this.state.linkRight,
            degree: internalDegree,
            selectedMediaLanguage: this.state.selectedMediaLanguage,
            id: this.state.id,
            creator: this.state.creator,
            url:this.state.url,
            type:this.state.type,
            dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
            medialanToSend:this.state.currentMediaLan,
            mediaresToSend:this.state.mediaresToSend,
            uriCoverExternalRes:this.state.uriCoverExternalRes,
            publicStatusExtRes:this.state.publicStatusExtRes,
            hls:this.state.hls,
            dash:this.state.dash
            ,textcloudcontent:this.state.textcloudcontent,
            gravity:this.state.gravity
          },
          this.state.file ? this.state.file:null));
          break;
          case "url":
          this.setState({ url:data},this.props.dataform({
            name: this.state.name,
            description: this.state.description,
            title: this.state.title,
            selectedRight: this.state.selectedRight,
            linkRight: this.state.linkRight,
            degree: this.state.degreeMediaSelected,
            selectedMediaLanguage: this.state.selectedMediaLanguage,
            id: this.state.id,
            creator: this.state.creator,
            url:data,
            type:this.state.type,
            dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
            medialanToSend:this.state.currentMediaLan,
            mediaresToSend:this.state.mediaresToSend,
            uriCoverExternalRes:this.state.uriCoverExternalRes,
            publicStatusExtRes:this.state.publicStatusExtRes,
            hls:this.state.hls,
            dash:this.state.dash
            ,textcloudcontent:this.state.textcloudcontent,
            gravity:this.state.gravity

          },
          null));
          break;

          default:
          break;
        }
        // gestire come datafromText ma con le varianti dei dati in piu es show in showroom
      }

      changeGravity=(event)=>{

        const MAX_GRAVITY= 99;

        if(parseInt(event.target.value)>=0 && parseInt(event.target.value)<=100){
          this.setState({gravity:parseInt(event.target.value),
            gravityInfo: parseInt(event.target.value)>MAX_GRAVITY ? true : false
          },
          this.props.dataform({
            name: this.state.name ,
            description:this.state.description,
            title:this.state.title,
            selectedRight:this.state.selectedRight,
            linkRight:this.state.linkRight,
            degreeMediaSelected:this.state.degreeMediaSelected,
            selectedMediaLanguage:this.state.selectedMediaLanguage,
            creator:this.state.creator,
            isExtRes:this.state.isExtRes,
            uriExternalRes:this.state.uriExternalRes,
            selectedExternalMediaType:this.state.selectedExternalMediaType,
            dcRightsHolder:this.state.dcRightsHolder,
            mediares:this.state.mediares,
            uriCoverExternalRes:"",
            publicStatusExtRes:this.state.publicStatusExtRes,
            textcloudcontent:this.state.textcloudcontent,
            gravity:parseInt(event.target.value)
          },
          this.state.file ? this.state.file:null));
        }
        else if(event.target.value===""){
          this.setState({gravity:parseInt(event.target.value),
            gravityInfo: parseInt(event.target.value) > MAX_GRAVITY ? true : false},
            this.props.dataform({
              name: this.state.name ,
              description:this.state.description,
              title:this.state.title,
              selectedRight:this.state.selectedRight,
              linkRight:this.state.linkRight,
              degreeMediaSelected:this.state.degreeMediaSelected,
              selectedMediaLanguage:this.state.selectedMediaLanguage,
              creator:this.state.creator,
              isExtRes:this.state.isExtRes,
              uriExternalRes:this.state.uriExternalRes,
              selectedExternalMediaType:this.state.selectedExternalMediaType,
              dcRightsHolder:this.state.dcRightsHolder,
              mediares:this.state.mediares,
              uriCoverExternalRes:"",
              publicStatusExtRes:this.state.publicStatusExtRes,
              textcloudcontent:this.state.textcloudcontent,
              gravity:0,    },
              this.state.file ? this.state.file:null));
            }
          }


          render() {
            let jsonlang = this.props.appstate.App.language;
            //  let itemData = this.props.appstate.ResourceManager.resources[this.props.appstate.ResourceManager.index];
            return (
              <div>
                <FlagsDialog onRef={childthis => (this.flagsDialogRef = childthis)} callback={this.callBackFlagsDialog}/>
                <LanDialog onRef={childthis => (this.lanDialogRef = childthis)} callback={this.callBackLanDialog}/>
                <InfoDialog onRef={childthis => (this.infoDialogRef = childthis)} callback={this.callBackFlagsDialog}/>
                <Grid container
                  direction="row"
                  spacing={3}
                  justifyContent="left"
                  alignItems="left"
                  // className="gridmargin"
                  >
                    <Grid item sm={2}>
                      <Typography variant="button" display="block">
                        {jsonlang.identifier.toUpperCase()} / GRAVITY
                      </Typography>
                      {/* <label for="confirm">{jsonlang.nome.toUpperCase()}</label> */}
                    </Grid>

                    <Grid item xs={9}>

                      <TextField
                        variant="outlined"
                        fullWidth
                        id="nameres"
                        defaultValue=""
                        label={jsonlang.identifier.toUpperCase()}
                        value={this.state.name}
                        onChange={this.textfiedlvalue}
                      />

                    </Grid>
                    <Grid item xs={1}>
                      <TextField
                        id="outlined-number"
                        label="GRAVITY"
                        type="number"
                        InputProps={{ inputProps: { min: "0", max: "100", step: "1" } }}
                        defaultValue={this.state.gravity}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        onChange={this.changeGravity}
                        info={this.state.gravityInfo}
                        value={this.state.gravity}
                        helperText={this.state.gravityInfo=== true ? "max 100" : ""}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="left"
                    alignItems="left"
                    // className="gridmargin"
                    >
                      <Grid item xs={2}>
                        <Typography variant="button" display="block">
                          {jsonlang.selelang.toUpperCase()}
                        </Typography>
                        {/* <label for="confirm">{jsonlang.selelang.toUpperCase()}</label> */}
                      </Grid>
                      {/* <Divider orientation="vertical" flexItem /> */}
                      <Grid item xs={3}>
                        <InputLabel id="languages_choose">Languages</InputLabel>
                        <Select
                          style={{ minWidth: 200, maxHeight: 70}}
                          labelId="languages_choose"
                          id="languages_select"
                          value={this.state.dataLanguage.toLowerCase()}
                          onChange={this.selectLanguage}
                          displayEmpty
                          defaultValue={"en"}
                          >
                            <MenuItem disabled value="">
                              <em>{jsonlang.language.toUpperCase()}</em>
                            </MenuItem>
                            <MenuItem selected disabled value="">
                              {/* <h3>{jsonlang.selelang.toUpperCase()}</h3> */}
                            </MenuItem>
                            {languages.map((item, index) =>
                              <MenuItem key={index} value={item.code} selected>
                                <ListItemIcon>
                                  <img
                                    name={item.code}
                                    alt={item.code}
                                    style={{ width: "32px", height: "32px" }}
                                    src={"/images/languages/" + item.code + ".svg"}>
                                  </img>
                                </ListItemIcon>
                                <Typography variant="inherit">{item.name}</Typography>
                              </MenuItem>
                            )}
                          </Select>
                        </Grid>

                        <Grid
                          // style={{margin: "10px 10px 10px 10px"}}
                          item xs={1}
                          onClick={this.openFlagsDialog}
                          >
                            <Tooltip title={<h2>{jsonlang.lanset.toUpperCase()}</h2>}>
                            <IconButton color="primary"
                              role="button"
                              size="small"
                              aria-label={jsonlang.adda.toUpperCase()}
                              onClick={this.openFlagsDialog}
                              >
                                <LanguageIcon
                                  style={{ fontSize: 40 }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          {/* <Divider orientation="vertical" flexItem /> */}
                          <Grid item xs={3}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              id="title"
                              label={jsonlang.title.toUpperCase()}
                              defaultValue=""
                              onBlur={this.saveTitleForLang}
                              value={this.state.titlestate}
                              onChange={this.textfiedlvalue}
                              disabled={this.state.dataLanguage === "" ? true : false}
                            />
                          </Grid>
                          {/* <Divider orientation="vertical" flexItem /> */}

                          <Grid item xs={3}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              id="descres"
                              label={jsonlang.desc.toUpperCase()}
                              defaultValue=""
                              onBlur={this.saveDescForLang}
                              value={this.state.descstate}
                              onChange={this.textfiedlvalue}
                              disabled={this.state.dataLanguage === "" ? true : false}
                            />
                          </Grid>
                        </Grid>


                        <Grid container
                          direction="row"
                          spacing={2}
                          justifyContent="left"
                          alignItems="left"
                          >

                            <Grid item xs={2}>
                              <Typography variant="button" display="block">
                                {/* {jsonlang.medialan.toUpperCase()} / {jsonlang.rights.toUpperCase()} / {jsonlang.creator.toUpperCase()} */}
                                {"RIGHTS / POSITION / CREATOR/ RIGHTS HOLDER"}

                              </Typography>
                            </Grid>

                            {/* <Divider orientation="vertical" flexItem /> */}
                            <Grid item xs={3}>
                              {/* <label for="confirm">{jsonlang.selerig.toUpperCase()}</label> */}
                              <Select
                                required
                                id="id"
                                displayEmpty
                                fullWidth
                                value={this.state.selectedRight}
                                onChange={this.handleChangeRight}
                                >
                                  <MenuItem disabled value="">
                                    <em>{jsonlang.selerig.toUpperCase()}</em>
                                  </MenuItem>
                                  {this.state.rights.map((right, i) =>
                                    <MenuItem key={i} className="MuiInputBase-input-custom" value={right.value}>{right.value}</MenuItem>
                                  )}
                                </Select>
                                {getRights(this.state.selectedRight).uri===""?
                                <img className="rights-img-RM" alt="CC-BY-NC-ND" src={getRights(this.state.selectedRight).logo}/>
                                :
                                <Tooltip title={jsonlang.rightInfo.toUpperCase()}>
                                  <Link
                                    color="inherit"
                                    role="link"
                                    aria-label="Public Domain - No Copyright"
                                    href={getRights(this.state.selectedRight).uri}
                                    target="blank"
                                    >
                                      <img className="rights-img-RM" alt="CC-BY-NC-ND" src={getRights(this.state.selectedRight).logo}/>
                                    </Link>
                                  </Tooltip>
                                }
                              </Grid>

                              {/* <Divider orientation="vertical" flexItem /> */}
                              <Grid item xs={1} onClick={this.showMap}>
                                <Tooltip title={<h2>{jsonlang.position.toUpperCase()}</h2>}>
                                <IconButton color="primary"
                                  role="button"
                                  size="small"
                                  aria-label={jsonlang.adda.toUpperCase()}
                                  onClick={this.showMap} >
                                  <RoomIcon style={{ fontSize: 40 }}
                                  />
                                </IconButton>
                              </Tooltip>
                              {parseFloat(this.props.appstate.MapDialog.position.lat).toFixed(3) > 0 && parseFloat(this.props.appstate.MapDialog.position.lng).toFixed(3) > 0 ?
                                <label> {this.props.appstate.MapDialog.position ? parseFloat(this.props.appstate.MapDialog.position.lat).toFixed(3) + "," + parseFloat(this.props.appstate.MapDialog.position.lng).toFixed(3) : ""} </label> :
                                <label> </label>}
                              </Grid>
                              <Grid item xs={3}>
                                <Tooltip title={<h2>{jsonlang.creatordesc}</h2>}>
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  id="creator"
                                  label={jsonlang.creator.toUpperCase()}
                                  defaultValue=""
                                  value={this.state.creator}
                                  onChange={this.textfiedlvalue}
                                />
                              </Tooltip>
                            </Grid>
                            <Grid item xs={3}>
                              <Tooltip title={<h2>{jsonlang.rightsholdertip}</h2>}>
                              <TextField
                                variant="outlined"
                                fullWidth
                                id="dcRightsHolder"
                                label={jsonlang.rightsholder.toUpperCase()}
                                defaultValue=""
                                value={this.state.dcRightsHolder}
                                onChange={this.textfiedlvalue}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>

                        <div style={{paddingTop:10}}>
                          <Grid
                            container
                            direction="row"
                            spacing={2}
                            justifyContent="left"
                            alignItems="left"
                            >
                              <Grid item  xs={2}>
                                <Typography variant="button" display="block">
                                  {/* {jsonlang.medialan.toUpperCase()} / {jsonlang.rights.toUpperCase()} / {jsonlang.creator.toUpperCase()} */}
                                  {"UPLOAD"}
                                </Typography>
                              </Grid>

                              {/* new language */}
                              <WeakResourceHandler dataform={this.dataformWeakRes}/>

                            </Grid>
                          </div>
                        </div>
                      )
                    }
                  }

                  export default connect(mapStateToProps)(withRouter(PlacesEditManager));
