import React, { Component } from 'react';
import ResourcesUpload from './ResourcesUpload';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Tooltip,
  Typography
} from "@material-ui/core";

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import * as services from "../../services/services.json";
import { multipartformService } from "../../services/services";
import { APP } from '../../redux/actions/actions';
import * as mediatypes from "../../Utils/mediatype.json";
            import InfoIcon from '@material-ui/icons/Info';

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class DialogResource extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      form: {
        appname: this.props.appstate.App.name,
        userId: this.props.appstate.App.user.userid,
        name: "",
        description: "",
        dest: "resource",
      },
      file: undefined
    });
  }
  componentDidMount() {

  }

  dataform = (data, file) => {
    this.setState({
      form: {
        appname: this.props.appstate.App.name,
        userId: this.props.appstate.App.user.userid,
        /*userId: "6077f52aeec4dc8288f88af8",*/
        name: data.name,
        description: data.description,
        dest: "resource",
      },
      file: file
    });
  }

  saveResource = () => {
    this.props.appstate.Modal.openModalLoading = true;
    this.props.dispatch(APP(this.props.appstate));
    let self = this;
    if (this.state.form.name.length > 0 && this.state.form.description.length > 0 && this.state.file) {
      let serviceurl = process.env.REACT_APP_DOMAIN + services.resource_POST_AUTH;
      multipartformService(serviceurl, this.state.form, this.props.appstate.App.user.token, this.state.file, function (err, result) {
        if (err) {
          self.props.appstate.Resource.openModalResources = false;
          self.props.appstate.Modal.openModalLoadingTextActive = true;
          self.props.appstate.Modal.openModalLoadingText = "resource upload error";
          self.props.dispatch(APP(self.props.appstate));
          self.setState({file: undefined})
          return;
        }
        if (result.status === 201) {
          self.props.appstate.Resource.openModalResources = false;
          self.props.appstate.Modal.openModalLoadingTextActive = true;
          self.props.appstate.Modal.openModalLoadingText = "Upload ok";
          self.props.appstate.Resource.resources.push(result.response.ops[0]);
          self.props.dispatch(APP(self.props.appstate));
          self.setState({file: undefined})
          return;
        }
        self.props.appstate.Modal.openModalLoading = false;
        self.props.dispatch(APP(this.props.appstate));
        return;
      })
    }
  }

  onclose = () => {
    this.props.appstate.Resource.openModalResources = false;
    this.props.dispatch(APP(this.props.appstate));
  }

  render() {
    let jsonlang = this.props.appstate.App.language;
    return (
      <div className="iframe">
        <Dialog
          open={this.props.appstate.Resource.openModalResources}
          onClose={this.onclose}
          TransitionComponent={Transition}
          aria-labelledby="dialog-add"
          fullWidth
          maxWidth={'sm'}
          >
            <DialogTitle id="dialog-add">
              <AddCircleOutlineIcon
                aria-label="Add Icon"
                style={{
                  color: "#fff",
                  backgroundColor: "#3f51b5",
                  borderRadius: "50px",
                  fontSize: "36px",
                  padding: "2px",
                }}
              />
              <span style={{
                padding: 5,
                position: 'fixed',
              }}>
              {jsonlang.addresources.toUpperCase()}
            </span>
            </DialogTitle>
            <DialogContent dividers>
              <ResourcesUpload dataform={this.dataform} fileAccept={mediatypes.resourcesMT}/>
              <Tooltip title={jsonlang.mediatype.toUpperCase()+":"+ mediatypes.resourcesMT}>
                  <InfoIcon color="grey" fontSize="small"/>
            </Tooltip>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                size="small"
                onClick={this.onclose}
                color="primary"
                startIcon={<ClearIcon />}>
                <Tooltip title={<h2>{jsonlang.instcancel.toUpperCase()}</h2>}>
                <Typography variant="button">{jsonlang.instcancel}</Typography>
              </Tooltip>
            </Button>
            {/* {(this.state.form.name.length > 0 && this.state.form.description.length && this.state.file) ?
              <Button
              variant="contained"
              size="small"
              onClick={this.saveResource}
              color="primary"
              startIcon={<SaveIcon />}>
              <Tooltip title={<h2>{jsonlang.save.toUpperCase()}</h2>}>
              <Typography variant="button">{jsonlang.save}</Typography>
            </Tooltip>
          </Button>
              : <></>} */}
              <Button
                disabled={(this.state.form.name.length > 0 && this.state.form.description.length && this.state.file) ?
                   false : true}
                variant="contained"
                size="small"
                onClick={this.saveResource}
                color="primary"
                startIcon={<SaveIcon />}>
                <Tooltip title={<h2>{jsonlang.instsavedata.toUpperCase()}</h2>}>
                <Typography variant="button">{jsonlang.save}</Typography>
              </Tooltip>
            </Button>


            </DialogActions>
          </Dialog>
        </div>
      )
    }
  }
  export default connect(mapStateToProps)(DialogResource);
