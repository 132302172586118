
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  // Grid,
  Typography,
  // FormControl,
  // TextField,
  // InputLabel,
  // IconButton,
  // MenuItem,
  // Tooltip,
  // InputAdornment,
  // OutlinedInput
} from "@material-ui/core";
import * as services from "../../services/services.json";
import { getService } from "../../services/services";


const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class DialogDisclaimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailDomain: ""
    }
  }

  componentDidMount() {
    this.props.onRef(this); //for the parent
  }

  init = (domValue) => {
    var self = this;
    console.log("dominio " + domValue);
    console.log("ok");
    let serviceurl = process.env.REACT_APP_DOMAIN + services.new_domainsusers_GET;
    console.log(serviceurl);
    serviceurl = serviceurl.replace("{APPNAME}", self.props.acamar.app);
    serviceurl = serviceurl.replace("{ID}", domValue);
    console.log(serviceurl);
    getService(serviceurl,
      "",
      function (err, result) {
        if (err) {
          console.log("errore get user admin domain");
        } else if (result.status === 200) {
          console.log("chiamata ok" + JSON.stringify(result.response[0]));
          let admin = result.response[0].domainusers.filter(adm => adm.role === "domainadmin");
          console.log(admin[0].email);

          self.setState({ emailDomain: admin[0].email });
        }
      }
    );
  }

  render() {
    let jsonlang = this.props.appstate.App.language;
    return (



      <Dialog
        open={this.props.open}
        maxWidth="md"
        onClose={this.props.close}
        aria-labelledby="disclaimer-dialog-title"
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Typography variant="h6"> <span style={{ color: "blue" }}>&#8505;</span>&nbsp;&nbsp;&nbsp;MCP INSTITUTION REGISTRATION</Typography>
        </DialogTitle>
        <DialogContent
          dividers={true}
        >
          <DialogContentText>
            <Box
              textAlign="center"
              color="text.primary">
              If you wish to register your institution, please send an e-mail to: miquelserranoj@gencat.cat
            </Box>
            <br />
            <Box
              textAlign="center"
              color="text.primary">
              providing these information:
            </Box>
            <Box>
              <ul>
                <li>INSTITUTION NAME</li>
                <li>COUNTRY</li>
                <li>INSTITUTION NAME</li>
                <li>INSTITUTION OWNER USERNAME</li>
                <li>EMAIL ADDRESS</li>
                <li>INSTITUTION CONTACT PHONE NUMBER</li>
                <i>Soon your request will be taken in charge.</i><br />
              </ul>
            </Box>
            <Box
              textAlign="center"
              color="text.primary">
              See you soon on the MCP platform !
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.props.close} color="primary">
            {jsonlang.close.toUpperCase()}
          </Button>
        </DialogActions>
      </Dialog>


    )
  }
};
export default connect(mapStateToProps)(DialogDisclaimer);
