import {
  IconButton,
  InputBase,
  Divider,
  // Tabs,
  // Tab,
  AppBar,
  // InputAdornment,
  Toolbar,
  // Switch,
  Tooltip,
  // TextField,
  // Fab,
  Card,
  CardHeader,
  CardMedia,
  Typography,
  Avatar,
  // CardContent,
  CardActions,
  Checkbox,
  Grid,
  // Link,
  ListItem

} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQrcode,
  // faClosedCaptioning,
  // faCopyright,
  // faBan,
} from "@fortawesome/free-solid-svg-icons";
// import AddLocationIcon from '@material-ui/icons/AddLocation';
import TimelineIcon from '@material-ui/icons/Timeline';

// import AddIcon from "@material-ui/icons/Add";
// import AspectRatioIcon from "@material-ui/icons/AspectRatio";
// import VisibilityIcon from "@material-ui/icons/Visibility";
import CancelRounded from "@material-ui/icons/CancelRounded";
// import CropFreeIcon from "@material-ui/icons/CropFree";
// import CenterFocusWeakIcon from "@material-ui/icons/CenterFocusWeak";
import Search from "@material-ui/icons/Search";
import MapIcon from '@material-ui/icons/Map';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RoomIcon from "@material-ui/icons/Room";
// import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
// import Audiotrack from "@material-ui/icons/Audiotrack";
// import Apps from "@material-ui/icons/Apps";
// import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
// import AudiotrackIcon from "@material-ui/icons/Audiotrack";
// import DeleteIcon from "@material-ui/icons/Delete";
// import EditIcon from "@material-ui/icons/Edit";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { APP } from "../../redux/actions/actions";
// import EventNoteIcon from '@material-ui/icons/EventNote';
import {
  getService,
  // deleteService,
  postService,
  // multipartformService,
} from "../../services/services";
import * as services from "../../services/services.json";
import "./Places.css";
import DialogDisclaimer from '../Dialog/DialogDisclaimer.jsx';
import DialogPlacesManager from "../Places/DialogPlacesManager";
import { GeoMap, loadMarkers } from "./mapcomp";
import MapDialog from "./MapDialog";
import PreviewDialog from "./PreviewDialog";
import ShareIcon from "@material-ui/icons/Share";

// import ImageIcon from "@material-ui/icons/Image";
// import CropOriginalIcon from "@material-ui/icons/CropOriginal";

// import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
// import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
// import AudiotrackOutlinedIcon from "@material-ui/icons/AudiotrackOutlined";
// import FileCopyIcon from "@material-ui/icons/FileCopy";
import {
  getRights,
  itemsSort,
  itemsSortInverse,
  itemsSortDate,
  itemsSortInverseDate,
  getExternalMediaType,
  getResourceDataForLanguage
} from "../../Utils/utility";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Chip from "@material-ui/core/Chip";
// import DescriptionIcon from "@material-ui/icons/Description";
import DialogDelete from "./DialogDelete";
import CloudOffIcon from '@material-ui/icons/CloudOff';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import DialogRights from "./DialogRights";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PersonIcon from '@material-ui/icons/Person';
import MenuCard from './MenuCard';
// import GetAppIcon from '@material-ui/icons/GetApp';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
// import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';


// import SpeedDial from '@material-ui/lab/SpeedDial';
// import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
// import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import AddPlace from './AddPlace';



const mapStateToProps = (state) => {
  return {
    acamar: state.acamar,
    appstate: state.appstate,
  };
};




class Places extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      showtile: {},
      mapmark: {},
      map: false,
      audiofilter: false,
      videofilter: false,
      imagefilter: false,
      documentfilter: false,
      filtersarray: [],
      publicRight: false,
      ccRight: false,
      otherRight: false,
      mineFilter:false,
      rightSelected: "",
      copyResState: [],
      searchValue: "",
      datedown: false,
      dateup: true,
      namedown: false,
      nameup: false,
      orderSelected: "dateup",
      openDisclaimer:false,
        openSpeedDial: false,
        type:"",
        openAddDialog:false,
        openPreviewDialog:false,
        openDialogRights:false

    };
  }
  componentDidMount() {
    this.props.appstate.ResourceManager.resources=[];
      this.props.dispatch(APP(this.props.appstate));
    this.getResources();
  }

  getResources = () => {
    this.props.appstate.Modal.openModalLoading = true;
    this.props.appstate.Modal.openModalLoadingTextActive = false;
    this.props.appstate.ResourceManager.fromannotator = false;
    this.props.dispatch(APP(this.props.appstate));
    let self = this;
    let ser =
    process.env.REACT_APP_DOMAIN +
    services.resourcemanager_GET_AUTH.replace(
      "{APPNAME}",
      this.props.appstate.App.name
    );
    //ser = ser.replace("{APPNAME}", this.props.appstate.App.name);
    //ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
    ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
    ser = ser.replace("{DEST}", "resource");
    getService(ser, "", function (err, result) {
      if (err) {
        self.props.appstate.Modal.openModalLoadingTextActive = true;
        self.props.appstate.Modal.openModalLoadingText =
        self.props.appstate.App.language.resourcerror;
        self.props.dispatch(APP(self.props.appstate));
        return;
      }
      if (result.status === 200) {
        self.props.appstate.Modal.openModalLoading = false;
        let orderArray = self.order(
          self.state.orderSelected,
          false,
          result.response
        );
        // let orderArray= itemsSortDate("modifiedAt",result.response);
        let filterArray = orderArray.filter(res => res.type === "poi" || res.type === "path");
        self.props.appstate.ResourceManager.resources = filterArray;
        self.props.dispatch(APP(self.props.appstate));
        self.setState({ items: self.props.appstate.ResourceManager.resources });
        return;
      }
      self.props.appstate.Modal.openModalLoadingTextActive = true;
      self.props.appstate.Modal.openModalLoadingText =
      self.props.appstate.App.language.noresource;
      self.props.dispatch(APP(self.props.appstate));
      return;
    });
  };

  upload = (event) => {
     this.setState({type:event, openAddDialog:true});
    // this.resourcesManagerDialogRef.init();
    this.mapDialogRef.init();
    //this.setState({ items: this.props.appstate.ResourceManager.resources });
    // this.props.appstate.ResourceManager.render = true;
    // this.props.appstate.ResourceManager.openModalResources = true;
    this.props.appstate.ResourceManager.fromannotator = false;
    this.props.appstate.MapDialog.position = { lat: 0, lng: 0 };
    this.props.dispatch(APP(this.props.appstate));
  };

  edit = (event) => {
    this.setState({type:"", openAddDialog:true});
    // this.resourcesManagerDialogRef.init();
    this.mapDialogRef.init();
    //this.setState({ items: this.props.appstate.ResourceManager.resources });
    // ricerca l'indice giusto in resources
    let indexItems = this.props.appstate.ResourceManager.resources.findIndex(
      (element) => element._id === event
    );
    // this.props.appstate.ResourceManager.index = event.currentTarget.name;
    this.props.appstate.ResourceManager.index = indexItems;
    this.props.appstate.ResourceManager.id = event;
    // this.props.appstate.ResourceManager.edit = true;
    // this.props.appstate.ResourceManager.render = true;
    // this.props.appstate.ResourceManager.openModalResources = true;
    this.props.appstate.MapDialog.position = { lat: 0, lng: 0 };
    this.props.dispatch(APP(this.props.appstate));
  };

  close = () => {
      this.setState({openAddDialog:false});
    }


  openRightsDetail = (event) => {
     this.setState({openDialogRights:true});
    console.log("openRightsDetail")

    //this.setState({ items: this.props.appstate.ResourceManager.resources });
    // ricerca l'indice giusto in resources
    let indexItems = this.props.appstate.ResourceManager.resources.findIndex(
      (element) => element._id === event.currentTarget.id
    );
    // this.resourcesManagerDialogRef.init(this.props.appstate.ResourceManager.resources[indexItems]);
    // this.props.appstate.ResourceManager.index = event.currentTarget.name;
    console.log(this.props.appstate.ResourceManager.resources[indexItems])
    this.props.appstate.DialogRights.item = this.props.appstate.ResourceManager.resources[indexItems];
    this.props.appstate.DialogRights.open = true;
    this.props.dispatch(APP(this.props.appstate));
  };
  delete = (id , name) => {
    // console.log(event);

    this.props.appstate.Modal.openModalDelete = true;
    this.props.appstate.Modal.openModalType = "manager";
    let indexItems = this.props.appstate.ResourceManager.resources.findIndex(
      // (element) => element._id === event.currentTarget.id
      (element) => element._id === id
    );
    // this.props.appstate.ResourceManager.index = event.currentTarget.name;
    this.props.appstate.ResourceManager.index = indexItems;
    // this.props.appstate.ResourceManager.id = event.currentTarget.id;
    this.props.appstate.ResourceManager.id = id;
    const itemcopy = Object.assign(
      {},
      // this.state.items[event.currentTarget.name]
      this.state.items[name]
    );
    // itemcopy.clientcover = this.coverUrl(event.currentTarget.id, Object.keys(this.state.items[event.currentTarget.name].medialan)[0]);
    itemcopy.clientcover = this.coverUrl(id, getResourceDataForLanguage(this.state.items[name]) );
    this.props.appstate.ResourceManager.resourceInfo = itemcopy;
    this.props.dispatch(APP(this.props.appstate));
  };

  urlCopy = (event) => {
    //console.log(event.currentTarget.id);
    let index = event.currentTarget.id;
    if (index) {
      const temp = document.createElement("input");
      document.body.appendChild(temp);

      // temp.value = this.resourceUrl(index,Object.keys(this.state.items[event.currentTarget.name].medialan)[0] );
      temp.value = this.resourceUrl(index, getResourceDataForLanguage(this.state.items[event.currentTarget.name]));
      temp.select();
      document.execCommand("copy");
      document.body.removeChild(temp);

      this.props.appstate.PreviewDialog.openModalPreview = true;
      this.props.appstate.PreviewDialog.render = true;
      this.props.appstate.PreviewDialog.item =this.state.items[event.currentTarget.name];
      this.props.appstate.PreviewDialog.share = true;


      // if (this.state.items[event.currentTarget.name].type==="webcloud"){
      if (getExternalMediaType().findIndex(x => x.value === this.state.items[event.currentTarget.name].type)!==-1){
        let selectedUri= this.state.items[event.currentTarget.name].url;
        let url="";
          if (typeof(selectedUri)==='string'){ //retrocompatibilità
            url = selectedUri;
          }else {
            let itemHELP={};
            itemHELP.medialan=selectedUri;
            let lang =  getResourceDataForLanguage(itemHELP);
            url = selectedUri[lang];
          }

        this.props.appstate.PreviewDialog.itemurl = url;
      } else  if (this.props.appstate.App.user.domainDetail.backupaws===true && this.state.items[event.currentTarget.name].streamextloaded===true){
        this.props.appstate.PreviewDialog.itemurl = this.state.items[event.currentTarget.name].streamext;
      }else{
        this.props.appstate.PreviewDialog.itemurl = temp.value;
      }
        this.previewDialogRef.init();
      this.props.dispatch(APP(this.props.appstate));
    }
  };

  imageOnMouseOver = (event) => {
    //console.log(event.currentTarget.id);
    let showtile = {};
    showtile[event.currentTarget.id] = true;
    this.setState({ showtile: showtile });
  };

  imageOnMouseOut = (event) => {
    //console.log(event.currentTarget.id);
    let showtile = {};
    showtile[event.currentTarget.id] = false;
    this.setState({ showtile: showtile });
  };

  searchInput = (event) => {
    if (event.target.value.length > 0) {
      let self = this;
      let filtered = this.props.appstate.ResourceManager.resources.filter(
        function (el, index, arr) {
          let found = false;
          let activeFilter = [];
          if (
            el.name
            .toUpperCase()
            .indexOf(event.currentTarget.value.toUpperCase()) !== -1
          ) {
            activeFilter.push("name");
            found = true;
          }

          let keysdesc = Object.keys(el.description);
          if (keysdesc.length>0){
            for (let i = 0; i < keysdesc.length; i++) {
              if (
                el.description[keysdesc[i]]
                .toUpperCase()
                .indexOf(event.currentTarget.value.toUpperCase()) !== -1
              ) {
                activeFilter.push("description");
                found = true;
                break;
              }
            }
          }
          /* if ((el.description[self.getKeyLang(el.description)].toUpperCase()).indexOf(event.currentTarget.value.toUpperCase()) !== -1) {
          activeFilter.push("description");
          found = true;
        }*/

        let keystitle = Object.keys(el.title);
        if (keystitle.length>0){
          for (let i = 0; i < keystitle.length; i++) {
            if (
              el.title[keystitle[i]]
              .toUpperCase()
              .indexOf(event.currentTarget.value.toUpperCase()) !== -1
            ) {
              activeFilter.push("title");
              found = true;
              break;
            }
          }
        }
        /* if ((el.title[self.getKeyLang(el.title)].toUpperCase()).indexOf(event.currentTarget.value.toUpperCase()) !== -1) {
        activeFilter.push("title");
        found = true;
      }*/

      if (el.tags) {
        if (
          self
          .getKeyLang(el.tags)
          .toUpperCase()
          .indexOf(event.currentTarget.value.toUpperCase()) !== -1
        ) {
          activeFilter.push("tags");
          found = true;
        }
      }
      el.filter = activeFilter;
      return found;
    }
  );
  this.setState(
    { items: filtered, searchValue: event.target.value },
    () => {
      this.filterengine(
        undefined,
        undefined,
        undefined,
        this.state.rightSelected
      );
    }
  );
} else {
  this.setState(
    {
      items: this.props.appstate.ResourceManager.resources,
      searchValue: event.target.value,
    },
    () => {
      this.filterengine(
        undefined,
        undefined,
        undefined,
        this.state.rightSelected
      );
    }
  );
}
};

searchInputCancel = () => {
  // document.getElementById("resourcesearchinput").value = "";
  this.setState(
    { items: this.props.appstate.ResourceManager.resources, searchValue: "" },
    () => {
      this.filterengine(
        undefined,
        undefined,
        undefined,
        this.state.rightSelected
      );
    }
  );
};

filterselection = (event, value) => {
  switch (event.currentTarget.name) {
    case "poi":
    this.setState(
      { poifilter: this.state.poifilter === true ? false : true },
      () => {
        this.filterengine(
          ["poi"],
          this.state.poifilter,
          undefined,
          this.state.rightSelected
        );
      }
    );
    break;
    case "path":
    this.setState(
      { pathfilter: this.state.pathfilter === true ? false : true },
      () => {
        this.filterengine(
          ["path"],
          this.state.pathfilter,
          undefined,
          this.state.rightSelected
        );
      }
    );
    break;
    case "map":
    this.setState({ map: this.state.map === true ? false : true }, () => {
      this.filterengine(undefined, undefined, undefined, "");
    }); // caso da controllare
    // this.filterengine();
    break;
    case "publicright":
    if (event.target.checked) {
      this.setState(
        {
          publicRight: event.target.checked,
          ccRight: !event.target.checked,
          otherRight: !event.target.checked,
        },
        () => {
          this.filterengine(undefined, undefined, undefined, "publicright");
        }
      );
    } else {
      this.setState(
        {
          publicRight: event.target.checked,
          ccRight: event.target.checked,
          otherRight: event.target.checked,
        },
        () => {
          this.filterengine(undefined, undefined, undefined, "");
        }
      );
    }
    break;
    case "ccright":
    if (event.target.checked) {
      this.setState(
        {
          publicRight: !event.target.checked,
          ccRight: event.target.checked,
          otherRight: !event.target.checked,
        },
        () => {
          this.filterengine(undefined, undefined, undefined, "ccright");
        }
      );
    } else {
      this.setState(
        {
          publicRight: event.target.checked,
          ccRight: event.target.checked,
          otherRight: event.target.checked,
        },
        () => {
          this.filterengine(undefined, undefined, undefined, "");
        }
      );
    }
    break;
    case "otherright":
    if (event.target.checked) {
      this.setState(
        {
          publicRight: !event.target.checked,
          ccRight: !event.target.checked,
          otherRight: event.target.checked,
        },
        () => {
          this.filterengine(undefined, undefined, undefined, "otherright");
        }
      );
    } else {
      this.setState(
        {
          publicRight: event.target.checked,
          ccRight: event.target.checked,
          otherRight: event.target.checked,
        },
        () => {
          this.filterengine(undefined, undefined, undefined, "");
        }
      );
    }
    break;

    case "mineFilter":
    this.setState(
      { mineFilter: this.state.mineFilter === true ? false : true },
      () => {
        this.filterengine(
          undefined,
          undefined,
          undefined,
          this.state.rightSelected
        );
      }
    );
    break;
    default:
    break;
  }
};

openAnnotator = (event, value) => {
  this.props.history.push('/App');
  this.props.appstate.App.currentappchild = services.annotator;
  console.log("elemento selezionato : ")
  console.log(this.state.items[event.currentTarget.value])
  this.props.appstate.Annotator.fsname = this.state.items[event.currentTarget.value].fsname;
  this.props.appstate.Annotator.resType = this.state.items[event.currentTarget.value].type.toUpperCase() + this.state.items[event.currentTarget.value].degree;
  this.props.appstate.Annotator.resourceid = this.state.items[event.currentTarget.value]._id;
  this.props.appstate.Annotator.medialanguage=getResourceDataForLanguage(this.state.items[event.currentTarget.value]);
  this.props.appstate.Annotator.annotatorMode = event.currentTarget.name;
  if (this.props.appstate.App.user.domainDetail.backupaws === true && this.state.items[event.currentTarget.value].streamextloaded === true) {
    this.props.appstate.Annotator.uri = this.state.items[event.currentTarget.value].streamext;
  } else {
    this.props.appstate.Annotator.uri = this.resourceUrl(this.state.items[event.currentTarget.value]._id, Object.keys(this.state.items[event.currentTarget.value].medialan)[0]);
  }
  this.props.dispatch(APP(this.props.appstate));
}

openMapFromCard = (event, value) => {
  if (this.mapref && window.google.maps) {
    this.mapref.setCenter(
      new window.google.maps.LatLng(
        this.state.items[event.currentTarget.name].location.coordinates[1],
        this.state.items[event.currentTarget.name].location.coordinates[0]
      )
    );
  }
  this.setState({
    map: true,
    openmapfromcard: true,
    openmapfromcardpos: [
      this.state.items[event.currentTarget.name].location.coordinates[1],
      this.state.items[event.currentTarget.name].location.coordinates[0],
    ],
  });
};
filterMyRes=(mineFilterStatus , el )=>{
  // console.log("filterMyRes" + "filterstatus: " + mineFilterStatus + "owner: "+el.owner )
  if (mineFilterStatus){
    return el.owner===this.props.appstate.App.user.userid;
  }else {
    return true;
  }
}
filterengine = (type, flag, mapbound, rightSel) => {
  let filarr = this.state.filtersarray.slice(
    0,
    this.state.filtersarray.length
  );

  // if (type) {
  //   let indexel = filarr.indexOf(type);
  //   if (indexel !== -1) {
  //     filarr.splice(indexel, 1);
  //   }
  //   if (flag === true) {
  //     filarr.push(type.toLowerCase());
  //   }
  // }

  if (type) {
    for (var i = 0; i < type.length; i++) {
      let indexel = filarr.indexOf(type[i]);
      if (indexel !== -1) {
        filarr.splice(indexel, 1);
      }
      if (flag === true) {
        filarr.push(type[i].toLowerCase());
      }
    }
  }

  var filtered = [];
  var copyResources = [];
  if (this.state.searchValue !== "") {
    copyResources = this.state.items.slice();
  } else {
    copyResources = this.props.appstate.ResourceManager.resources.slice(
      0,
      this.props.appstate.ResourceManager.resources.length
    );
  }
  let self = this;
  if (filarr.length > 0 && rightSel === "") {
    //one or more filter activated
    filtered = copyResources.filter(function (el, index, arr) {
      if (
        self.state.map === true &&
        mapbound !== undefined &&
        self.mapref &&
        parseFloat(el.location.coordinates[1]) !== 0 &&
        parseFloat(el.location.coordinates[0]) !== 0 &&
        typeof mapbound.contains === "function"

      ) {
        return (
          filarr.indexOf(el.type) !== -1 &&
          mapbound.contains(
            new window.google.maps.LatLng(
              parseFloat(el.location.coordinates[1]),
              parseFloat(el.location.coordinates[0])
            )
          )&& self.filterMyRes(self.state.mineFilter,el)
        );
      } else if (self.state.map === true && self.mapref) {
        return (
          filarr.indexOf(el.type) !== -1 &&
          parseFloat(el.location.coordinates[1]) !== 0 &&
          parseFloat(el.location.coordinates[0]) !== 0&& self.filterMyRes(self.state.mineFilter,el)
        );
      } else {
        return filarr.indexOf(el.type) !== -1 && self.filterMyRes(self.state.mineFilter,el);
      }
    });

    this.setState(
      {
        items: self.order(self.state.orderSelected, false, filtered),
        filtersarray: filarr,
        rightSelected: rightSel,
      },
      () => {
        loadMarkers(this.state.items, this.mapref, this);
      }
    );
  } else if (filarr.length > 0 && rightSel !== "") {
    switch (rightSel) {
      case "publicright":
      filtered = copyResources.filter(function (el, index, arr) {
        if (
          self.state.map === true &&
          mapbound !== undefined &&
          self.mapref &&
          parseFloat(el.location.coordinates[1]) !== 0 &&
          parseFloat(el.location.coordinates[0]) !== 0 &&
          typeof mapbound.contains === "function"&& self.filterMyRes(self.state.mineFilter,el)
        ) {
          return (
            filarr.indexOf(el.type) !== -1 &&
            mapbound.contains(
              new window.google.maps.LatLng(
                parseFloat(el.location.coordinates[1]),
                parseFloat(el.location.coordinates[0])
              )
            ) &&
            el.edmRights.dcRights === getRights("CC0").value&& self.filterMyRes(self.state.mineFilter,el)
          );
        } else if (self.state.map === true && self.mapref) {
          return (
            filarr.indexOf(el.type) !== -1 &&
            el.edmRights.dcRights === getRights("CC0").value &&
            parseFloat(el.location.coordinates[1]) !== 0 &&
            parseFloat(el.location.coordinates[0]) !== 0 && self.filterMyRes(self.state.mineFilter,el)
          );
        } else {
          return (
            filarr.indexOf(el.type) !== -1 &&
            el.edmRights.dcRights === getRights("CC0").value && self.filterMyRes(self.state.mineFilter,el)
          );
        }
      });
      this.setState(
        {
          items: self.order(self.state.orderSelected, false, filtered),
          filtersarray: filarr,
          rightSelected: rightSel,
        },
        () => {
          loadMarkers(this.state.items, this.mapref, this);
        }
      );
      break;
      case "ccright":
      filtered = copyResources.filter(function (el, index, arr) {
        if (
          self.state.map === true &&
          mapbound !== undefined &&
          self.mapref &&
          parseFloat(el.location.coordinates[1]) !== 0 &&
          parseFloat(el.location.coordinates[0]) !== 0 &&
          typeof mapbound.contains === "function" && self.filterMyRes(self.state.mineFilter,el)
        ) {
          return (
            filarr.indexOf(el.type) !== -1 &&
            mapbound.contains(
              new window.google.maps.LatLng(
                parseFloat(el.location.coordinates[1]),
                parseFloat(el.location.coordinates[0])
              )
            ) &&
            el.edmRights.dcRights !== getRights("CC0").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT").value&&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EU ORPHAN WORK").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value && self.filterMyRes(self.state.mineFilter,el)
          );
        } else if (self.state.map === true && self.mapref) {
          return (
            filarr.indexOf(el.type) !== -1 &&
            el.edmRights.dcRights !== getRights("CC0").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EU ORPHAN WORK").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value&&
            parseFloat(el.location.coordinates[1]) !== 0 &&
            parseFloat(el.location.coordinates[0]) !== 0 && self.filterMyRes(self.state.mineFilter,el)
          );
        } else {
          return (
            filarr.indexOf(el.type) !== -1 &&
            el.edmRights.dcRights !== getRights("CC0").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EU ORPHAN WORK").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value && self.filterMyRes(self.state.mineFilter,el)
          );
        }
      });
      this.setState(
        {
          items: self.order(self.state.orderSelected, false, filtered),
          filtersarray: filarr,
          rightSelected: rightSel,
        },
        () => {
          loadMarkers(this.state.items, this.mapref, this);
        }
      );
      break;
      case "otherright":
      filtered = copyResources.filter(function (el, index, arr) {
        if (
          self.state.map === true &&
          mapbound !== undefined &&
          self.mapref &&
          parseFloat(el.location.coordinates[1]) !== 0 &&
          parseFloat(el.location.coordinates[0]) !== 0 &&
          typeof mapbound.contains === "function"&& self.filterMyRes(self.state.mineFilter,el)
        ) {
          return (
            filarr.indexOf(el.type) !== -1 &&
            mapbound.contains(
              new window.google.maps.LatLng(
                parseFloat(el.location.coordinates[1]),
                parseFloat(el.location.coordinates[0])
              )
            ) &&
            (el.edmRights.dcRights === getRights("IN COPYRIGHT").value||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EU ORPHAN WORK").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value)&& self.filterMyRes(self.state.mineFilter,el)
          );
        } else if (self.state.map === true && self.mapref) {
          return (
            filarr.indexOf(el.type) !== -1 &&
            (el.edmRights.dcRights === getRights("IN COPYRIGHT").value||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EU ORPHAN WORK").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value)
            &&
            parseFloat(el.location.coordinates[1]) !== 0 &&
            parseFloat(el.location.coordinates[0]) !== 0&& self.filterMyRes(self.state.mineFilter,el)
          );
        } else {
          return (
            filarr.indexOf(el.type) !== -1 &&
            (el.edmRights.dcRights === getRights("IN COPYRIGHT").value||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EU ORPHAN WORK").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value)&& self.filterMyRes(self.state.mineFilter,el)
          );
        }
      });
      this.setState(
        {
          items: self.order(self.state.orderSelected, false, filtered),
          filtersarray: filarr,
          rightSelected: rightSel,
        },
        () => {
          loadMarkers(this.state.items, this.mapref, this);
        }
      );
      break;
      default:
      break;
    }
  } else if (filarr.length === 0 && rightSel !== "") {
    switch (rightSel) {
      case "publicright":
      filtered = copyResources.filter(function (el, index, arr) {
        if (
          self.state.map === true &&
          mapbound !== undefined &&
          self.mapref &&
          parseFloat(el.location.coordinates[1]) !== 0 &&
          parseFloat(el.location.coordinates[0]) !== 0 &&
          typeof mapbound.contains === "function" && self.filterMyRes(self.state.mineFilter,el)
        ) {
          return (
            mapbound.contains(
              new window.google.maps.LatLng(
                parseFloat(el.location.coordinates[1]),
                parseFloat(el.location.coordinates[0])
              )
            ) && el.edmRights.dcRights === getRights("CC0").value
          );
        } else if (self.state.map === true && self.mapref) {
          return (
            el.edmRights.dcRights === getRights("CC0").value &&
            parseFloat(el.location.coordinates[1]) !== 0 &&
            parseFloat(el.location.coordinates[0]) !== 0 && self.filterMyRes(self.state.mineFilter,el)
          );
        } else {
          return el.edmRights.dcRights === getRights("CC0").value && self.filterMyRes(self.state.mineFilter,el);
        }
      });
      this.setState(
        {
          items: self.order(self.state.orderSelected, false, filtered),
          filtersarray: filarr,
          rightSelected: rightSel,
        },
        () => {
          loadMarkers(this.state.items, this.mapref, this);
        }
      );
      break;
      case "ccright":
      filtered = copyResources.filter(function (el, index, arr) {
        if (
          self.state.map === true &&
          mapbound !== undefined &&
          self.mapref &&
          parseFloat(el.location.coordinates[1]) !== 0 &&
          parseFloat(el.location.coordinates[0]) !== 0 &&
          typeof mapbound.contains === "function"
        ) {
          return (
            mapbound.contains(
              new window.google.maps.LatLng(
                parseFloat(el.location.coordinates[1]),
                parseFloat(el.location.coordinates[0])
              )
            ) &&
            el.edmRights.dcRights !== getRights("CC0").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT").value&&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EU ORPHAN WORK").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value && self.filterMyRes(self.state.mineFilter,el)
          );
        } else if (self.state.map === true && self.mapref) {
          return (
            el.edmRights.dcRights !== getRights("CC0").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EU ORPHAN WORK").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value&&
            parseFloat(el.location.coordinates[1]) !== 0 &&
            parseFloat(el.location.coordinates[0]) !== 0 && self.filterMyRes(self.state.mineFilter,el)
          );
        } else {
          return (
            el.edmRights.dcRights !== getRights("CC0").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT").value&&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EU ORPHAN WORK").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value && self.filterMyRes(self.state.mineFilter,el)
          );
        }
      });
      this.setState(
        {
          items: self.order(self.state.orderSelected, false, filtered),
          filtersarray: filarr,
          rightSelected: rightSel,
        },
        () => {
          loadMarkers(this.state.items, this.mapref, this);
        }
      );
      break;
      case "otherright":
      filtered = copyResources.filter(function (el, index, arr) {
        if (
          self.state.map === true &&
          mapbound !== undefined &&
          self.mapref &&
          parseFloat(el.location.coordinates[1]) !== 0 &&
          parseFloat(el.location.coordinates[0]) !== 0 &&
          typeof mapbound.contains === "function" && self.filterMyRes(self.state.mineFilter,el)
        ) {
          return (
            mapbound.contains(
              new window.google.maps.LatLng(
                parseFloat(el.location.coordinates[1]),
                parseFloat(el.location.coordinates[0])
              )
            ) &&
            (el.edmRights.dcRights === getRights("IN COPYRIGHT").value||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EU ORPHAN WORK").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value) && self.filterMyRes(self.state.mineFilter,el)
          );
        } else if (self.state.map === true && self.mapref) {
          return (
            (el.edmRights.dcRights === getRights("IN COPYRIGHT").value||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EU ORPHAN WORK").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value)
            &&
            parseFloat(el.location.coordinates[1]) !== 0 &&
            parseFloat(el.location.coordinates[0]) !== 0 && self.filterMyRes(self.state.mineFilter,el)
          );
        } else {
          return (el.edmRights.dcRights === getRights("IN COPYRIGHT").value||
          el.edmRights.dcRights === getRights("IN COPYRIGHT - EU ORPHAN WORK").value ||
          el.edmRights.dcRights === getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value ||
          el.edmRights.dcRights === getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value ||
          el.edmRights.dcRights === getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value) && self.filterMyRes(self.state.mineFilter,el)
        }
      });
      this.setState(
        {
          items: self.order(self.state.orderSelected, false, filtered),
          filtersarray: filarr,
          rightSelected: rightSel,
        },
        () => {
          loadMarkers(this.state.items, this.mapref, this);
        }
      );
      break;
      default:
      break;
    }
  } else if (
    self.state.map === true &&
    mapbound !== undefined &&
    self.mapref &&
    typeof mapbound.contains === "function"
  ) {
    //fov map filter activated
    filtered = copyResources.filter(function (el, index, arr) {
      return (
        mapbound.contains(
          new window.google.maps.LatLng(
            parseFloat(el.location.coordinates[1]),
            parseFloat(el.location.coordinates[0])
          )
        ) &&
        parseFloat(el.location.coordinates[1]) !== 0 &&
        parseFloat(el.location.coordinates[0]) !== 0 &&
        el.location !== undefined && self.filterMyRes(self.state.mineFilter,el)
      );
    });
    this.setState(
      {
        items: self.order(self.state.orderSelected, false, filtered),
        filtersarray: filarr,
        rightSelected: rightSel,
      },
      () => {
        loadMarkers(this.state.items, this.mapref, this);
      }
    );
  } else if (self.state.map === true && self.mapref) {
    filtered = copyResources.filter(function (el, index, arr) {
      return (
        parseFloat(el.location.coordinates[1]) !== 0 &&
        parseFloat(el.location.coordinates[0]) !== 0 && self.filterMyRes(self.state.mineFilter,el)
      );
    });
    this.setState(
      { items: filtered, filtersarray: filarr, rightSelected: rightSel },
      () => {
        loadMarkers(this.state.items, this.mapref, this);
      }
    );
  } else {
    //no filter
    filtered = copyResources.filter(function (el, index, arr) {
      return (
        self.filterMyRes(self.state.mineFilter,el)
      );
    });
    this.setState(
      { items: filtered, filtersarray: filarr, rightSelected: rightSel },
      () => {
        loadMarkers(this.state.items, this.mapref, this);
      }
    );

    // this.setState(
    //   {
    //     items: self.order(self.state.orderSelected, false, copyResources),
    //     filtersarray: filarr,
    //     rightSelected: rightSel,
    //   },
    //   () => {
    //     loadMarkers(this.state.items, this.mapref, this);
    //   }
    // );
  }
  // if (filarr.length > 0) { //one or more filter activated
  //   filtered = copyResources.filter(function (el, index, arr) {    // casi qui dentro devo metterli ok
  //     if (self.state.map === true && mapbound !== undefined && self.mapref && el.location.coordinates[1] !== 0 && el.location.coordinates[0] !== 0 && typeof mapbound.contains === 'function') {
  //       return (filarr.indexOf(el.type) !== -1 && mapbound.contains(new window.google.maps.LatLng(parseFloat(el.location.coordinates[1]), parseFloat(el.location.coordinates[0]))))
  //     } else {
  //       return (filarr.indexOf(el.type) !== -1)
  //     }
  //   });
  //   this.setState({ items: filtered, filtersarray: filarr }, () => { loadMarkers(this.state.items, this.mapref, this) });
  // } else if (self.state.map === true && mapbound !== undefined && self.mapref && typeof mapbound.contains === 'function') { //fov map filter activated
  //   filtered = copyResources.filter(function (el, index, arr) {
  //     return (mapbound.contains(new window.google.maps.LatLng(parseFloat(el.location.coordinates[1]), parseFloat(el.location.coordinates[0]))) && el.location.coordinates[1] !== 0 && el.location.coordinates[0] !== 0 && el.location !== undefined)
  //   })
  //   this.setState({ items: filtered, filtersarray: filarr }, () => { loadMarkers(this.state.items, this.mapref, this) });
  // }else { //no filter
  //   this.setState({ items: copyResources, filtersarray: filarr }, () => { loadMarkers(this.state.items, this.mapref, this) });
  // }
};

coverUrl = (id,medialan) => {
  // console.log(medialan)
  let ser =
  process.env.REACT_APP_DOMAIN +
  services.singleresourcemanager_GET_AUTH.replace(
    "{APPNAME}",
    this.props.appstate.App.name
  );
  ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
  ser = ser.replace("{RESOURCEID}", id);
  ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
  ser = ser.replace("{COVER}", true);
  ser = ser + "&t=" + new Date().getTime();
  ser = ser.replace("{MEDIALAN}",medialan );
  ser = ser.replace("{MEDIARES}", "first");

  return ser;
};
getSingleImage = (id,medialan) => {
  let ser =
  process.env.REACT_APP_DOMAIN +
  services.singleresourcemanager_GET_AUTH.replace(
    "{APPNAME}",
    this.props.appstate.App.name
  );
  ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
  ser = ser.replace("{RESOURCEID}", id);
  ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
  ser = ser.replace("{COVER}", false);
  ser = ser + "&t=" + new Date().getTime();
  ser = ser.replace("{MEDIALAN}",medialan );
  ser = ser.replace("{MEDIARES}", "first");
  return ser;
};

avatarUrl = (id) => {
  let ser =
  process.env.REACT_APP_DOMAIN +
  services.avatar_GET_AUTH.replace(
    "{APPNAME}",
    this.props.appstate.App.name
  );
  ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
  ser = ser.replace("{USERID}", id);
  return ser;
};

resourceUrl = (id,medialan) => {
  console.log("resourceUrl")
  let ser =
  process.env.REACT_APP_DOMAIN +
  services.singleresourcemanager_GET_AUTH.replace(
    "{APPNAME}",
    this.props.appstate.App.name
  );
  ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
  ser = ser.replace("{RESOURCEID}", id);
  ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
  ser = ser.replace("{COVER}", false);
  ser = ser.replace("{MEDIALAN}",medialan );
  ser = ser.replace("{MEDIARES}", "first");
  return ser;
};

mapCallback = (type, result, mapbound) => {
  switch (type) {
    case "drawcompleted":
    break;
    case "ref":
    this.mapref = result;
    loadMarkers(this.state.items, this.mapref, this);
    //this.filterengine(undefined, undefined, mapbound);
    break;
    case "mapBoundDragend":
    case "mapBoundZoomChanged":
    case "mapBoundonTilesLoaded":
    this.filterengine(undefined, undefined, result, "");
    if (this.state.openmapfromcard && window.google.maps && this.mapref) {
      this.mapref.setCenter(
        new window.google.maps.LatLng(
          this.state.openmapfromcardpos[0],
          this.state.openmapfromcardpos[1]
        )
      );
      this.setState({ openmapfromcard: false, openmapfromcardpos: [] });
    }
    /*if (this.state.coords) {
    this.mapref.panTo(this.state.coords);
  }*/

  /*if (this.state.loadpoint === true) {
  this.mapref.panTo(new window.google.maps.LatLng(this.state.items[event.currentTarget.name].location.coordinates[1], this.state.items[event.currentTarget.name].location.coordinates[0])) : <></>
}*/
break;
default:
break;
}
};

mapMarkerCallback = (type, id, index) => {
  if (type === "delete") {
    this.props.appstate.Modal.openModalDelete = true;
    this.props.appstate.Modal.openModalType = "manager"; //to recognize from resources "emperor"
    this.props.appstate.ResourceManager.index = index;
    this.props.appstate.ResourceManager.id = id;
    const itemcopy = Object.assign({}, this.state.items[index]);
    itemcopy.clientcover = this.coverUrl(id,Object.keys(this.state.items[index].medialan)[0]);
    this.props.appstate.ResourceManager.resourceInfo = itemcopy;
    this.props.dispatch(APP(this.props.appstate));
  }
  //let mapmark = {};
  //mapmark[id] = true;
  //this.setState({ mapmark: mapmark});
};

resourcePreviewInfoWindow = (index) => {
  this.setState({openPreviewDialog:true});

  this.props.appstate.PreviewDialog.openModalPreview = true;
  this.props.appstate.PreviewDialog.render = true;
  this.props.appstate.PreviewDialog.item = this.state.items[index];

  if (this.props.appstate.App.user.domainDetail.backupaws===true && this.state.items[index].streamextloaded===true){
    this.props.appstate.PreviewDialog.itemurl = this.state.items[index].streamext;
  }else{
    this.props.appstate.PreviewDialog.itemurl = this.resourceUrl(this.state.items[index]._id,Object.keys(this.state.items[index].medialan)[0]);
  }

      this.previewDialogRef.init();
  this.props.dispatch(APP(this.props.appstate));
};


resourcePreview = (event) => {
  console.log("resourcePreview")
  let index = event.currentTarget.name;
  if (!index) {
    index = event.target.id;
  }

  this.props.appstate.PreviewDialog.openModalPreview = true;
  this.props.appstate.PreviewDialog.render = true;
  console.log(this.state.items[index])
  this.props.appstate.PreviewDialog.item = this.state.items[index];

  if (this.props.appstate.App.user.domainDetail.backupaws===true && this.state.items[index].streamextloaded===true){
    this.props.appstate.PreviewDialog.itemurl = this.state.items[index].streamext;
  }else{
    // this.props.appstate.PreviewDialog.itemurl = this.resourceUrl(this.state.items[index]._id,Object.keys(this.state.items[index].medialan)[0]);
    this.props.appstate.PreviewDialog.itemurl = this.resourceUrl(this.state.items[index]._id,getResourceDataForLanguage(this.state.items[index]));

  }
  this.previewDialogRef.init();
  this.props.dispatch(APP(this.props.appstate));
};

qrcode = (event) => {

  this.props.appstate.PreviewDialog.openModalPreview = true;
  this.props.appstate.PreviewDialog.qrcode = true;
  this.props.appstate.PreviewDialog.render = true;
  this.props.appstate.PreviewDialog.item =this.state.items[event.currentTarget.name];

  if (getExternalMediaType().findIndex(x => x.value === this.state.items[event.currentTarget.name].type)!==-1){
    let selectedUri= this.state.items[event.currentTarget.name].url;
    let url="";
      if (typeof(selectedUri)==='string'){ //retrocompatibilità
        url = selectedUri;
      }else {
        let itemHELP={};
        itemHELP.medialan=selectedUri;
        let lang =  getResourceDataForLanguage(itemHELP);
        url = selectedUri[lang];
      }

    this.props.appstate.PreviewDialog.itemurl = url;
  }else if (this.props.appstate.App.user.domainDetail.backupaws===true && this.state.items[event.currentTarget.name].streamextloaded===true){
    this.props.appstate.PreviewDialog.itemurl = this.state.items[event.currentTarget.name].streamext;
  }else{
    // this.props.appstate.PreviewDialog.itemurl = this.resourceUrl(this.state.items[event.currentTarget.name]._id,Object.keys(this.state.items[event.currentTarget.name].medialan)[0]);
     this.props.appstate.PreviewDialog.itemurl = this.resourceUrl(this.state.items[event.currentTarget.name]._id,getResourceDataForLanguage(this.state.items[event.currentTarget.name]));


  }
  this.previewDialogRef.init();
  this.props.dispatch(APP(this.props.appstate));
};

copyItem = (event) => {
  //disabilita pulsante se owen ed user nonsono la stessa persona
  let index = event;
  // let index = event.currentTarget.name;
  // if (!index) {
  //   index = event.target.id;
  // }
  this.props.appstate.Modal.openModalLoading = true;
  this.props.appstate.Modal.openModalLoadingTextActive = false;
  this.props.dispatch(APP(this.props.appstate));
  let params = {};
  // params.resId = this.state.items[event.currentTarget.name]._id;
  params.resId = this.state.items[index]._id;
  params.appname = this.props.appstate.App.name;
  params.domainId = this.props.appstate.App.user.domain;
  let jsonlang = this.props.appstate.App.language;
  var self = this;
  let indexFoundExternalType = getExternalMediaType().findIndex(
    (element) =>
    // element.value === this.state.items[event.currentTarget.name].type
    element.value === this.state.items[index].type

  );
  let duplicateUrlService = services.resourcemanager_duplicate_POST_AUTH;
  if (indexFoundExternalType !== -1) {
    duplicateUrlService = services.resourcemanager_weakduplicate_POST_AUTH;
  }
  console.log(indexFoundExternalType);
  console.log(duplicateUrlService);
  postService(
    process.env.REACT_APP_DOMAIN + duplicateUrlService,
    params,
    "",
    "",
    function (err, result) {
      if (err) {
        self.props.appstate.Modal.openModalLoading = false;
        self.props.appstate.Modal.openModalLoadingTextActive = true;
        self.props.appstate.Modal.openModalLoadingText =
        jsonlang.copyreserror;
        self.props.dispatch(APP(self.props.appstate));
      } else if (result.status === 201) {
        self.props.appstate.Modal.openModalLoadingTextActive = true;
        self.props.appstate.Modal.openModalLoadingText = jsonlang.copyresok;
        self.props.appstate.Modal.openModalLoadingTextExtra =
        jsonlang.duplicateresname + result.response.name;
        self.props.appstate.ResourceManager.resources.push(result.response);
        self.props.dispatch(APP(self.props.appstate));
        self.setState(
          { items: [...self.state.items, result.response] },
          () => {
            self.filterengine(
              undefined,
              undefined,
              undefined,
              self.state.rightSelected
            );
          }
        );
      }
    }
  );
};
tabOrder = (event) => {
  this.order(event.currentTarget.name, true, this.state.items);
};

order = (orderType, orderByInterface, arrayToOrder) => {
  //ordinamento potrebbe dover essere fatto piu volte anche quando fai edit o quando cambi l'array attenzione
  //nel caso completare implementazione di questa funzione  rendendola chiamabile dall'esterno
  let data = arrayToOrder.slice();
  let orderArray = [];
  // console.log(orderType)
  // console.log(orderByInterface)
  // console.log(data)
  switch (orderType) {
    case "nameup":
    orderArray = itemsSort("name", data);
    if (orderByInterface) {
      this.setState({
        datedown: false,
        dateup: false,
        namedown: false,
        nameup: true,
        items: orderArray,
        orderSelected: "nameup",
      });
    } else {
      return orderArray;
    }
    break;
    case "namedown":
    orderArray = itemsSortInverse("name", data);
    if (orderByInterface) {
      this.setState({
        datedown: false,
        dateup: false,
        namedown: true,
        nameup: false,
        items: orderArray,
        orderSelected: "namedown",
      });
    } else {
      return orderArray;
    }
    break;
    case "dateup":
    orderArray = itemsSortDate("modifiedAt", data);
    if (orderByInterface) {
      this.setState({
        datedown: false,
        dateup: true,
        namedown: false,
        nameup: false,
        items: orderArray,
        orderSelected: "dateup",
      });
    } else {
      return orderArray;
    }
    break;
    case "datedown":
    orderArray = itemsSortInverseDate("modifiedAt", data);
    if (orderByInterface) {
      this.setState({
        datedown: true,
        dateup: false,
        namedown: false,
        nameup: false,
        items: orderArray,
        orderSelected: "datedown",
      });
    } else {
      return orderArray;
    }
    break;
    default:
    break;
  }
};
getUTCData = (data) => {
  try {
    return new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "UTC",
      timeZoneName: "short",
    }).format(new Date(data));
  } catch (error) {
    return "";
    // console.log(error);
  }
};

getKeyLang = (strinput) => {
  if (this.props.acamar.configuration.Languages && strinput) {
    let keylang = "en";
    if (strinput[this.props.acamar.configuration.Languages.default]) {
      return this.props.acamar.configuration.Languages.default;
    } else if (strinput[keylang]) {
      return keylang;
    } else {
      let allKey = Object.keys(strinput);
      if (allKey.length > 0) {
        return allKey[0];
      }
    }
  }
  return "";
};

dialogResourcesManagerCallBack = () => {
  // document.getElementById("resourcesearchinput").value = "";
  // this.setState({ items: this.props.appstate.ResourceManager.resources});
  this.setState(
    { items: this.props.appstate.ResourceManager.resources },
    () => {
      this.filterengine(
        undefined,
        undefined,
        undefined,
        this.state.rightSelected
      );
    }
  );
};/*
downloadButtonStatus(item){
  // console.log("downloadButtonStatus")
  // console.log(index);
  // console.log(item);
  // let item=this.state.items[index];
  if ( (item.type.toLowerCase()==="audio" || item.type.toLowerCase()==="video" ||item.type.toLowerCase()==="image" )
  && ((item.owner===this.props.appstate.App.user.userid)
  || (item.owner!==this.props.appstate.App.user.userid &&  !item.edmRights.dcRights.includes("IN COPYRIGHT"))
)) {
  return false
}else {
  return true
}
}
downloadResource = (event) => {
  fetch(this.getSingleImage(event.currentTarget.id,getResourceDataForLanguage(this.state.items[event.currentTarget.name],"")))
  .then(response => {
    if (response.status===200){
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        console.log("url");
        console.log(url);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'download';
        a.click();
      });
    }else {
      console.log("Dialog di errore download !")
    }
  });
}*/

handleClickOpenDisclaim = () => {
  this.setState({openDisclaimer:true});
  this.dialogDisclaimer.init(this.props.appstate.App.user.domain);
}

handleCloseDisclaim = () => {
  this.setState({openDisclaimer:false});
};
getUriLang =(selectedUri)=>{
  let url="";
    if (typeof(selectedUri)==='string'){ //retrocompatibilità
      url = selectedUri;
    }else {
      let itemHELP={};
      itemHELP.medialan=selectedUri;
      let lang =  getResourceDataForLanguage(itemHELP);
      url = selectedUri[lang];
    }
    return url;
}


closePreviewDialog=()=>{
  this.setState({openPreviewDialog:false});
}


handleCloseRights=()=>{
  this.setState({openDialogRights:false});
}


render() {
  let jsonlang = this.props.appstate.App.language;
  return (
    <div>
      <DialogDisclaimer open={this.state.openDisclaimer} close={this.handleCloseDisclaim}
        okDisc={this.handleCloseDisclaim}
        onRef={childthis => (this.dialogDisclaimer = childthis)}/>
        <DialogRights
          open={this.state.openDialogRights} close={this.handleCloseRights}
          //  onRef={(childthis) => (this.resourcesManagerDialogRef = childthis)}
        />
        <DialogPlacesManager
          type={this.state.type}
          open={this.state.openAddDialog}
          onClose={this.close}

          callback={this.dialogResourcesManagerCallBack}
          //onRef={(childthis) => (this.resourcesManagerDialogRef = childthis)}
        />
        <DialogDelete callback={this.dialogResourcesManagerCallBack} />
        <MapDialog onRef={(childthis) => (this.mapDialogRef = childthis)} />
        <PreviewDialog
          open={this.state.openPreviewDialog}
          onClose={this.closePreviewDialog}

          onRef={(childthis) => (this.previewDialogRef = childthis)}
        />
        <AppBar className="appbarstyle" position="fixed" color="default">
          <Typography variant="h6" justify="center" align="center">
          {jsonlang.places.toUpperCase()}
          </Typography>
          <Toolbar >
            <InputBase
              style={{  minWidth: 80, marginLeft: 8 }}
              id="resourcesearchinput"
              onChange={this.searchInput}
              placeholder={jsonlang.search}
              value={this.state.searchValue}
            />
            <Tooltip title={<h2>{jsonlang.search.toUpperCase()}</h2>}>
            <IconButton
              type="submit"
              color="primary"
              aria-label="search"
              className="pad10"
              // onChange={this.searchInput}
              >
                <Search />
              </IconButton>
            </Tooltip>
            <Divider style={{ height: 28, margin: 4 }} orientation="vertical" />

      <Tooltip title={<h2>{jsonlang.cancel.toUpperCase()}</h2>}>
      <CancelRounded
        color="primary"
        onClick={this.searchInputCancel}
        fontSize="large"
        style={{ "margin-right": "4.5em" }}
      />
    </Tooltip>

    <Tooltip title={<h2>{jsonlang.onlymyres.toUpperCase()}</h2>}>
    <Checkbox
      checked={this.state.mineFilter}
      onChange={this.filterselection}
      name="mineFilter"
      color="primary"
      icon={<PersonOutlineIcon fontSize="large" />}
      checkedIcon={<PersonIcon className="btn-dotted" fontSize="large" />}
    />
  </Tooltip>
  <Divider style={{ height: 28, margin: 4 }} orientation="vertical" />

  {/* filtri data e alfabetico inizio   */}

  <Tooltip title={<h2>{jsonlang.namesort.toUpperCase()}</h2>}>
  <SortByAlphaIcon fontSize="large" />
</Tooltip>
{/* <Tooltip title={<h2>{jsonlang.resgeo.toUpperCase()}</h2>}> */}
<Checkbox
  checked={this.state.nameup}
  onChange={this.tabOrder}
  name="nameup"
  color="primary"
  icon={<ArrowDropUpIcon fontSize="large" />}
  checkedIcon={<ArrowDropUpIcon className="btn-dotted" fontSize="large" />}
/>
{/* </Tooltip> */}
{/* <Tooltip title={<h2>{jsonlang.resgeo.toUpperCase()}</h2>}> */}
<Checkbox
  checked={this.state.namedown}
  onChange={this.tabOrder}
  name="namedown"
  color="primary"
  icon={<ArrowDropDownIcon fontSize="large" />}
  checkedIcon={<ArrowDropDownIcon className="btn-dotted" fontSize="large" />}
/>
{/* </Tooltip> */}
<Divider style={{ height: 28, margin: 4 }} orientation="vertical" />

<Tooltip title={<h2>{jsonlang.datesort.toUpperCase()}</h2>}>
<AccessTimeIcon fontSize="large" />
</Tooltip>
{/* <Tooltip title={<h2>{jsonlang.resgeo.toUpperCase()}</h2>}> */}
<Checkbox
  checked={this.state.dateup}
  onChange={this.tabOrder}
  name="dateup"
  color="primary"
  icon={<ArrowDropUpIcon fontSize="large" />}
  checkedIcon={<ArrowDropUpIcon className="btn-dotted" fontSize="large" />}
/>
{/* </Tooltip> */}
<Tooltip title={<h2>{jsonlang.resgeo.toUpperCase()}</h2>}>
<Checkbox
  checked={this.state.datedown}
  onChange={this.tabOrder}
  name="datedown"
  color="primary"
  icon={<ArrowDropDownIcon fontSize="large" />}
  checkedIcon={<ArrowDropDownIcon className="btn-dotted" fontSize="large" />}
/>
</Tooltip>
<Divider
  style={{ height: 28, marginRight: "4.5em"}}
  orientation="vertical"

/>

{/* filtri data e alfabetico fine   */}
  {/* filtri path/poi  */}

  <Tooltip title={<h2>POI</h2>}>
  <Checkbox
    checked={this.state.poifilter}
    onChange={this.filterselection}
    name="poi"
    color="primary"
    icon={ <RoomIcon fontSize="large"/> }
    checkedIcon={<RoomIcon  className="btn-dotted" fontSize="large"/>}
    // icon={ <FontAwesomeIcon icon={faBan} />  }
    // checkedIcon={<FontAwesomeIcon icon={faBan} color="blue"/>}
  />
</Tooltip>


<Divider style={{ height: 28, margin: 4 }} orientation="vertical" />
<Tooltip title={<h2>PATH</h2>}>
<Checkbox
  checked={this.state.pathfilter}
  onChange={this.filterselection}
  name="path"
  color="primary"
  icon={ <TimelineIcon fontSize="large" /> }
  checkedIcon={<TimelineIcon  className="btn-dotted" fontSize="large"/>}
/>
</Tooltip>



{/* fine filtri poi/path */}

<AddPlace
  onClick={this.upload}
  //onClose={this.CloseSpeed}
  //onOpen={this.OpenSpeed}
  //open={this.state.openSpeedDial}
/>


</Toolbar>
</AppBar>
<div className="gridcontainerwithmap">
  <Grid
    container
    // className={
    //   this.state.map ? "gridcontainerwithmap" : "gridcontainer"
    // }
    >
      {/* {this.state.map ? ( */}
        <Grid xs={9}>
          <GeoMap
            isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCtAWW-Sv99CiDFq5i4cYgE_0UBAuQBwXg&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: "100%" }} />}
            containerElement={<div className="mapcontainer" />}
            mapElement={<div style={{ height: "88%" }} />}
            options={{
              scrollwheel: false,
              streetViewControl: false,
              minZoom: 1,
              maxZoom: 19,
              mapTypeControl: true,
              zoomControl: true,
            }}
            drawingControl={false}
            onlyMarker={false}
            callback={this.mapCallback}
          />{" "}
        </Grid>
      {/* ) : (
        <></>
      )} */}
      <Grid
        // className="cardcontainer" xs={this.state.map ? 1 : 12}
        xs={1}>
        {this.state.items && this.state.items.length > 0 ? (
          this.state.items.map((item, index) => (
            <Card className="cardstyle" index={index}>
              <CardHeader
                // className="cardheaderstyle"
                avatar={
                  <Tooltip title={<h2>{item.ownerdetails[0].name}</h2>}>
                  <Avatar
                    aria-label="recipe"
                    src={this.avatarUrl(item.owner)}
                    ></Avatar>
                  </Tooltip>
                }
                action={
                     <ListItem style={{
                       marginLeft:"-12px"
                     }}>


                    <IconButton
                      name={index}
                      id={item._id}
                      onClick={this.resourcePreview}
                      style={{padding:0}}
                      >

                                <Tooltip
                                  title={<h2>{jsonlang.review.toUpperCase()}</h2>}
                                  >
                                    <VisibilityIcon
                                     color="primary"
                                   />

                                  </Tooltip>

                              </IconButton>
                              {
                                item.location ? (
                                  parseInt(item.location.coordinates[0]) !== 0 &&
                                  parseInt(item.location.coordinates[1]) !== 0 ? (
                                    <Tooltip
                                      title={<h2>{jsonlang.remap.toUpperCase()}</h2>}
                                      >
                                        <IconButton
                                          style={{padding:0}}
                                          name={index}
                                          id="map"
                                          onClick={this.openMapFromCard}
                                          >
                                            <MapIcon
                                              // style={{ padding: 0, fontSize: 20}}
                                              color="primary"
                                              ></MapIcon>
                                            </IconButton>
                                          </Tooltip>
                                        ) : (
                                          <></>)) : (<></>)
                                        }
                                        <MenuCard
                                          key={item._id}
                                          item={item}
                                          index={index}
                                          onCopy={() => this.copyItem(index)}
                                          onDelete={() => this.delete(item._id,index)}
                                          onEdit={() => this.edit(item._id)}
                                        />
                                      </ListItem>
                                    }
                                    title={
                                      <Tooltip title={<h2>{item.name}</h2>}>
                                      <div className="cardtextstyle">{item.name}</div>
                                    </Tooltip>
                                  }
                                  subheader={
                                    <div className="cardsubtextstyle">
                                      {this.getUTCData(item.modifiedAt)}
                                    </div>
                                  }
                                />
                                {item.filter && this.state.searchValue !== "" ? (
                                  <div className="type-find">
                                    {item.filter.map((filtering, index) => (
                                      <Chip
                                        style={{ backgroundColor: "#192bb0AA" }}
                                        color="primary"
                                        label={filtering}
                                        icon={<Search />}
                                      />
                                    ))}
                                  </div>
                                ) : null}
                                <Tooltip title={<h2>{jsonlang.backupres.toUpperCase()}</h2>}>
                                <div className="backup">
                                  <Checkbox
                                    disabled
                                    style={{color:'white'}}
                                    checked={this.props.appstate.App.user.domainDetail.backupaws === true && item.streamextloaded === true ? true : false}
                                    icon={<CloudOffIcon />}
                                    checkedIcon={<CloudDoneIcon />}
                                  />
                                </div>
                              </Tooltip>
                              <Tooltip title={<h2>{item.name}</h2>}>
                              <CardMedia
                                square
                                className="cardmediastyle"
                                id={index}
                                image={
                                  item.uriCoverExternalRes ? item.uriCoverExternalRes
                                    : item.type==="poi"? "/images/icons/poi.jpg" : "/images/icons/path.jpg"
                                  }
                                title=""
                                onClick={this.resourcePreview}
                              />

                            </Tooltip>

                        <CardActions disableSpacing className="actionarea">

                      <div className="cardshare">
                        <Tooltip
                                                  title={
                                                    <h2>
                                                      {item ? (item.edmRights ? item.edmRights.dcRights : <></>) : <></>}
                                                    </h2>
                                                  }
                                                  >
                                                    {item ? (item.edmRights ? getRights(item.edmRights.dcRights).uri === "" ? (
                                                      <img
                                                        className="rights-img-RM"
                                                        alt="CC-BY-NC-ND"
                                                        src={
                                                          item.edmRights
                                                          ? getRights(item.edmRights.dcRights).logo
                                                          : ""
                                                        }
                                                      />
                                                    ) : (
                                                      <img
                                                        className="rights-img-RM"
                                                        alt="CC-BY-NC-ND"
                                                        id={item._id}
                                                        src={
                                                          item.edmRights
                                                          ? getRights(item.edmRights.dcRights).logo
                                                          : ""
                                                        }
                                                        onClick={this.openRightsDetail}
                                                      />

                                                    ) : <></>) : <></>}
                                                  </Tooltip>

                          <Tooltip
                            title={<h2>{"DISCLAIMER"}</h2>}
                            >
                              <IconButton
                                name={index}
                                id={item._id}
                                onClick={this.handleClickOpenDisclaim}
                                >
                                  <ReportProblemIcon
                                    color="primary"
                                    style={{ fontSize: 20 }}
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={<h2>{jsonlang.resshare.toUpperCase()}</h2>}
                                >
                                  <IconButton
                                    name={index}
                                    id={item._id}
                                    disabled={item.type==="textcloud" ?true :false}
                                    onClick={this.urlCopy}
                                    >
                                      <ShareIcon
                                        color={item.type==="textcloud" ?"disabled" :"primary"}
                                        style={{ fontSize: 20 }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    title={<h2>{jsonlang.rescode.toUpperCase()}</h2>}
                                    >
                                      <IconButton
                                        name={index}
                                        id={item._id}
                                        disabled={item.type==="textcloud" ?true :false}
                                        onClick={this.qrcode}
                                        >

                                            <FontAwesomeIcon icon={faQrcode} />
                                        </IconButton>
                                        </Tooltip>



              </div>

          </CardActions>
        </Card>
      ))
    ) : (
      <></>
    )}
  </Grid>
</Grid>
</div>
</div>
);
}
}
export default connect(mapStateToProps)(withRouter(Places));
