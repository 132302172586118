import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { APP } from '../../redux/actions/actions';
import * as services from "../../services/services.json";
import FlagsDialog from './FlagsDialog';
import LanDialog from './LanDialog';
import InfoDialog from './InfoDialog';
import languages from '../../config/languages.json';
import languages2 from '../../config/languages.json';
// import * as services from "../../services/services.json";
// import { getService, postService } from "../../services/services";
// import { makeStyles } from '@material-ui/core/styles';
import * as mediatypes from "../../Utils/mediatype.json";
import './ResourcesEditManager.css';
import TextCloudHandler from "./TextCloudHandler"
import  WeakResourceHandler from "./WeakResourceHandler"
import {
  TextField,
  Tooltip,
  MenuItem,
  Switch,
  Grid,
  Divider,
  IconButton,
  // OutlinedInput,
  Select,
  InputLabel,
  FormControl,
  ListItemIcon,
  Typography,
  Link,
  Checkbox,
  // Toolbar,
  Button
} from "@material-ui/core";

import { getRights,getExternalMediaType } from "../../Utils/utility";
import RoomIcon from '@material-ui/icons/Room';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LanguageIcon from '@material-ui/icons/Language';
import InfoIcon from '@material-ui/icons/Info';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

class ResourcesEditManager extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      name: "",
      description: {},
      title: {},
      file: undefined,
      filename: "",
      rights: getRights("all"),
      selectedRight: getRights("CC0").value,
      mediaLanguageList: this.props.acamar.configuration.Languages.list,
      selectedMediaLanguage: "",
      dataLanguage: "",
      degreeMedia: false,
      degreeMediaSelected: 2,
      langfilter: new Array(this.props.acamar.configuration.Languages.list.length).fill(false),
      titlestate: "",
      descstate: "",
      linkRight: getRights("CC0").uri,
      creator: "",
      dcRightsHolder:"",
      backupstatus:true,
      //new prop
      medialan:{},
      folderIdentifier:"",
      currentMediaLan:"",
      arraymediares:[],
      file1:undefined,
      file2:undefined,
      file3:undefined,
      file4:undefined,
      file5:undefined,
      deleteLanguageFlag:false,
      uriCoverExternalRes:"",
      publicStatusExtRes:false,
      deleteSecondResolution:true,
      deleteThirdResolution:true,
      deleteFourthResolution:true,
      deleteFifthResolution:true,
      dashlink:"",
      hlslink:"",
      hls:{},
      dash:{},
      textcloudcontent:{},
      // textcloud:""


    })
  }

  componentDidMount() {
    this.props.onRef(this);
    this.firstInit()
  }

  firstInit=(lan)=>{
    let itemData = this.props.appstate.ResourceManager.resources[this.props.appstate.ResourceManager.index];
    let currentMediaLan_help="";
    let arraymediares_help="";
    if (lan){
      currentMediaLan_help=lan;
      arraymediares_help=itemData.medialan[lan];
    }else {
      currentMediaLan_help=Object.keys(itemData.medialan)[0]
      arraymediares_help=itemData.medialan[Object.keys(itemData.medialan)[0]]
    }
    let parseDescription;
    try {
      parseDescription = itemData.description;
    } catch (e) {
      parseDescription = "";
    }
    let parseTitle;
    try {
      parseTitle = itemData.title;
    } catch (e) {
      parseTitle = "";
    }
    let parseHls;
    try {
      parseHls = itemData.hls;
    } catch (e) {
      parseHls = "";
    }
    let parseDash;
    try {
      parseDash = itemData.dash;
    } catch (e) {
      parseDash = "";
    }
    console.log(typeof(itemData.url))
    console.log("itemData")
    console.log(itemData)
    this.setState({
      name: itemData.name,
      descstate: itemData.description ? itemData.description[this.getKeyLang(parseDescription)] : "",
      description: parseDescription,
      title: parseTitle,
      titlestate: itemData.title ? itemData.title[this.getKeyLang(parseTitle)] : "",
      filename: itemData.fsname,
      file: undefined,
      selectedMediaLanguage: itemData.mediaLanguage,
      selectedRight: itemData.edmRights ? itemData.edmRights.dcRights : "",
      linkRight: itemData.edmRights ? itemData.edmRights.uri : "",
      id: itemData._id,
      creator: itemData.dcCreator ? itemData.dcCreator : "",
      degreeMediaSelected: itemData.degree,
      degreeMedia: parseInt(itemData.degree) === 3 ? true : false,
      type:itemData.type,
      url:itemData.url,
      dcRightsHolder:itemData.dcRightsHolder?itemData.dcRightsHolder:"",
      backupstatus:itemData.streamextloaded,
      //new prop
      medialan:itemData.medialan,
      medialanextload:itemData.medialanextload,
      folderIdentifier:itemData.folderIdentifier,
      currentMediaLan:currentMediaLan_help,
      arraymediares:arraymediares_help,
      deleteLanguageFlag:false,
      uriCoverExternalRes:itemData.uriCoverExternalRes ?itemData.uriCoverExternalRes : "" ,
      publicStatusExtRes:itemData.publicStatusExtRes ?itemData.publicStatusExtRes : false,
      file1:undefined,
      file2:undefined,
      file3:undefined,
      file4:undefined,
      file5:undefined,
      dashlink: itemData.dash ? itemData.dash[currentMediaLan_help] : "",
      hlslink: itemData.hls ? itemData.hls[currentMediaLan_help] : "",
      hls:parseHls,
      dash:parseDash,
      // textcloudcontent:parseTextCloud,
      // textcloud:itemData.textcloudcontent ? itemData.textcloudcontent[currentMediaLan_help] : ""


    },this.worldLangHandler(this.getKeyLang(itemData.description)));
    this.props.appstate.MapDialog.position.lat = itemData.location.coordinates[1];
    this.props.appstate.MapDialog.position.lng = itemData.location.coordinates[0];
    this.props.dispatch(APP(this.props.appstate));
  }


  fileChoiced = (event) => {
    switch (event.target.id) {
      case "first":
      if (event.target.files[0]){
        this.setState({ file: event.target.files[0], filename: event.target.files[0].name, mediaresToSend:event.target.id , file1:event.target.files[0]}, this.props.dataform({
          // this.setState({ file: document.getElementById('file-input').files[0], filename: document.getElementById('file-input').files[0].name }, this.props.dataform({
          name: this.state.name,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degree: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          id: this.state.id,
          creator: this.state.creator,
          url:this.state.url,
          type:this.state.type,
          dcRightsHolder:this.state.dcRightsHolder,
          folderIdentifier:this.state.folderIdentifier,
          medialanToSend:this.state.currentMediaLan,
          mediaresToSend:event.target.id,
          uriCoverExternalRes:this.state.uriCoverExternalRes,
          publicStatusExtRes:this.state.publicStatusExtRes ,
          hls:this.state.hls,
          dash:this.state.dash
          ,textcloudcontent:this.state.textcloudcontent
        },
        // document.getElementById('file-input').files[0]));
        // document.getElementById('file-input') ? document.getElementById('file-input').files[0]:null));
        event.target.files[0]));
      }
      break;
      case "second":
      if (event.target.files[0]){
        this.setState({ file: event.target.files[0], filename: event.target.files[0].name, mediaresToSend:event.target.id, file2:event.target.files[0]}, this.props.dataform({
          // this.setState({ file: document.getElementById('file-input').files[0], filename: document.getElementById('file-input').files[0].name }, this.props.dataform({
          name: this.state.name,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degree: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          id: this.state.id,
          creator: this.state.creator,
          url:this.state.url,
          type:this.state.type,
          dcRightsHolder:this.state.dcRightsHolder,
          folderIdentifier:this.state.folderIdentifier,
          medialanToSend:this.state.currentMediaLan,
          mediaresToSend:event.target.id,
          hls:this.state.hls,
          dash:this.state.dash
          ,textcloudcontent:this.state.textcloudcontent
        },
        // document.getElementById('file-input').files[0]));
        // document.getElementById('file-input') ? document.getElementById('file-input').files[0]:null));
        event.target.files[0]));
      }
      break;
      case "third":
      if (event.target.files[0]){
        this.setState({ file: event.target.files[0], filename: event.target.files[0].name, mediaresToSend:event.target.id, file3:event.target.files[0]}, this.props.dataform({
          // this.setState({ file: document.getElementById('file-input').files[0], filename: document.getElementById('file-input').files[0].name }, this.props.dataform({
          name: this.state.name,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degree: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          id: this.state.id,
          creator: this.state.creator,
          url:this.state.url,
          type:this.state.type,
          dcRightsHolder:this.state.dcRightsHolder,
          folderIdentifier:this.state.folderIdentifier,
          medialanToSend:this.state.currentMediaLan,
          mediaresToSend:event.target.id,
          uriCoverExternalRes:this.state.uriCoverExternalRes,
          publicStatusExtRes:this.state.publicStatusExtRes,
          hls:this.state.hls,
          dash:this.state.dash
          ,textcloudcontent:this.state.textcloudcontent
        },
        // document.getElementById('file-input').files[0]));
        // document.getElementById('file-input') ? document.getElementById('file-input').files[0]:null));
        event.target.files[0]));
      }
      break;
      case "fourth":
      if (event.target.files[0]){
        this.setState({ file: event.target.files[0], filename: event.target.files[0].name, mediaresToSend:event.target.id, file4:event.target.files[0]}, this.props.dataform({
          // this.setState({ file: document.getElementById('file-input').files[0], filename: document.getElementById('file-input').files[0].name }, this.props.dataform({
          name: this.state.name,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degree: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          id: this.state.id,
          creator: this.state.creator,
          url:this.state.url,
          type:this.state.type,
          dcRightsHolder:this.state.dcRightsHolder,
          folderIdentifier:this.state.folderIdentifier,
          medialanToSend:this.state.currentMediaLan,
          mediaresToSend:event.target.id,
          uriCoverExternalRes:this.state.uriCoverExternalRes,
          publicStatusExtRes:this.state.publicStatusExtRes,
          hls:this.state.hls,
          dash:this.state.dash
          ,textcloudcontent:this.state.textcloudcontent
        },
        // document.getElementById('file-input').files[0]));
        // document.getElementById('file-input') ? document.getElementById('file-input').files[0]:null));
        event.target.files[0]));
      }
      break;
      case "fifth":
      if (event.target.files[0]){
        this.setState({ file: event.target.files[0], filename: event.target.files[0].name, mediaresToSend:event.target.id, file5:event.target.files[0]}, this.props.dataform({
          // this.setState({ file: document.getElementById('file-input').files[0], filename: document.getElementById('file-input').files[0].name }, this.props.dataform({
          name: this.state.name,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degree: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          id: this.state.id,
          creator: this.state.creator,
          url:this.state.url,
          type:this.state.type,
          dcRightsHolder:this.state.dcRightsHolder,
          folderIdentifier:this.state.folderIdentifier,
          medialanToSend:this.state.currentMediaLan,
          mediaresToSend:event.target.id,
          uriCoverExternalRes:this.state.uriCoverExternalRes,
          publicStatusExtRes:this.state.publicStatusExtRes,
          hls:this.state.hls,
          dash:this.state.dash
          ,textcloudcontent:this.state.textcloudcontent
        },
        // document.getElementById('file-input').files[0]));
        // document.getElementById('file-input') ? document.getElementById('file-input').files[0]:null));
        event.target.files[0]));
      }
      break;
      default:
      break;
    }

  }
  textfiedlvalue = (event) => {
    switch (event.target.id) {
      case "nameres":
      this.setState({ name: event.target.value }, this.props.dataform({
        name: event.target.value,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degree: this.state.degreeMediaSelected,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        id: this.state.id,
        creator: this.state.creator,
        url:this.state.url,
        type:this.state.type,
        dcRightsHolder:this.state.dcRightsHolder    ,folderIdentifier:this.state.folderIdentifier,
        medialanToSend:this.state.currentMediaLan,
        mediaresToSend:this.state.mediaresToSend,
        uriCoverExternalRes:this.state.uriCoverExternalRes,
        publicStatusExtRes:this.state.publicStatusExtRes,
        hls:this.state.hls,
        dash:this.state.dash
        ,textcloudcontent:this.state.textcloudcontent
      },
      // document.getElementById('file-input').files[0]));
      this.state.file ? this.state.file:null));
      break;
      case "descres":
      this.setState({ descstate: event.target.value });
      // this.setState({ description: event.target.value }, this.props.dataform({ name: this.state.name, description: event.target.value }, document.getElementById('file').files[0]));
      break;
      case "title":
      this.setState({ titlestate: event.target.value });
      // this.setState({ description: event.target.value }, this.props.dataform({ name: this.state.name, description: event.target.value }, document.getElementById('file').files[0]));
      break;
      case "creator":
      this.setState({ creator: event.target.value }, this.props.dataform({
        name: this.state.name,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degree: this.state.degreeMediaSelected,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        id: this.state.id,
        creator: event.target.value,
        url:this.state.url,
        type:this.state.type,
        dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
        medialanToSend:this.state.currentMediaLan,
        mediaresToSend:this.state.mediaresToSend,
        uriCoverExternalRes:this.state.uriCoverExternalRes,
        publicStatusExtRes:this.state.publicStatusExtRes,
        hls:this.state.hls,
        dash:this.state.dash
        ,textcloudcontent:this.state.textcloudcontent
      },
      this.state.file ? this.state.file:null));
      // document.getElementById('file-input').files[0]));
      // this.setState({ description: event.target.value }, this.props.dataform({ name: this.state.name, description: event.target.value }, document.getElementById('file').files[0]));
      break;
      case "url":
      this.setState({ url:event.target.value},this.props.dataform({
        name: this.state.name,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degree: this.state.degreeMediaSelected,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        id: this.state.id,
        creator: this.state.creator,
        url:event.target.value,
        type:this.state.type,
        dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
        medialanToSend:this.state.currentMediaLan,
        mediaresToSend:this.state.mediaresToSend,
        uriCoverExternalRes:this.state.uriCoverExternalRes,
        publicStatusExtRes:this.state.publicStatusExtRes,
        hls:this.state.hls,
        dash:this.state.dash
        ,textcloudcontent:this.state.textcloudcontent

      },
      null));
      // this.setState({ description: event.target.value }, this.props.dataform({ name: this.state.name, description: event.target.value }, document.getElementById('file').files[0]));
      break;
      case "dcRightsHolder":
      this.setState({ dcRightsHolder:event.target.value},this.props.dataform({
        name: this.state.name,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degree: this.state.degreeMediaSelected,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        id: this.state.id,
        creator: this.state.creator,
        url:this.state.url,
        type:this.state.type,
        dcRightsHolder:event.target.value,folderIdentifier:this.state.folderIdentifier,
        medialanToSend:this.state.currentMediaLan,
        mediaresToSend:this.state.mediaresToSend,
        uriCoverExternalRes:this.state.uriCoverExternalRes,
        publicStatusExtRes:this.state.publicStatusExtRes,
        hls:this.state.hls,
        dash:this.state.dash
        ,textcloudcontent:this.state.textcloudcontent
      },
      null));
      // this.setState({ description: event.target.value }, this.props.dataform({ name: this.state.name, description: event.target.value }, document.getElementById('file').files[0]));
      break;
      case "coverUrl":
      this.setState({ uriCoverExternalRes:event.target.value},this.props.dataform({
        name: this.state.name,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degree: this.state.degreeMediaSelected,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        id: this.state.id,
        creator: this.state.creator,
        url:this.state.url,
        type:this.state.type,
        dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
        medialanToSend:this.state.currentMediaLan,
        mediaresToSend:this.state.mediaresToSend,
        uriCoverExternalRes:event.target.value,
        publicStatusExtRes:this.state.publicStatusExtRes,
        hls:this.state.hls,
        dash:this.state.dash
        ,textcloudcontent:this.state.textcloudcontent
      },
      null));
      break;
      case "dash":
      this.setState({ dashlink: event.target.value });
      // this.setState({ description: event.target.value }, this.props.dataform({ name: this.state.name, description: event.target.value }, document.getElementById('file').files[0]));
      break;
      case "hls":
      this.setState({ hlslink: event.target.value });
      // this.setSt
      break;
      default:
      break;

    }
  }

  saveHlsForLang = () => {
    let hlsCopy = Object.assign({}, this.state.hls);
    if (this.state.hlslink === "") {
      // se l'elemento di lingua esiste lo cancelllo altrimenti niente
      if (hlsCopy[this.state.currentMediaLan]) {
        delete hlsCopy[this.state.currentMediaLan]
      }
    } else {
      //creo o aggiorno l'elemento di lingua
      hlsCopy[this.state.currentMediaLan] = this.state.hlslink;

    }
    console.log("hlsCopy")
    console.log(hlsCopy)
    this.setState({ hls: hlsCopy }, this.props.dataform({
      name: this.state.name,
      title: this.state.title,
      selectedRight: this.state.selectedRight,
      linkRight: this.state.linkRight,
      degree: this.state.degreeMediaSelected,
      selectedMediaLanguage: this.state.selectedMediaLanguage,
      id: this.state.id,
      creator: this.state.creator,
      url:this.state.url,
      type:this.state.type,
      dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
      medialanToSend:this.state.currentMediaLan,
      mediaresToSend:this.state.mediaresToSend,
      uriCoverExternalRes:this.state.uriCoverExternalRes,
      publicStatusExtRes:this.state.publicStatusExtRes,
      description: this.state.description,
      hls:hlsCopy,
      dash:this.state.dash
      ,textcloudcontent:this.state.textcloudcontent
    },
    this.state.file ? this.state.file:null));
    // document.getElementById('file-input').files[0]));
  }
  saveDashForLang = () => {
    let dashCopy = Object.assign({}, this.state.dash);
    if (this.state.dashlink === "") {
      // se l'elemento di lingua esiste lo cancelllo altrimenti niente
      if (dashCopy[this.state.currentMediaLan]) {
        delete dashCopy[this.state.currentMediaLan]
      }
    } else {
      //creo o aggiorno l'elemento di lingua
      dashCopy[this.state.currentMediaLan] = this.state.dashlink;
    }
    this.setState({ dash:dashCopy }, this.props.dataform({
      name: this.state.name,
      description: this.state.description,
      dash:dashCopy,
      hls:this.state.hls,
      title: this.state.title,
      selectedRight: this.state.selectedRight,
      linkRight: this.state.linkRight,
      degree: this.state.degreeMediaSelected,
      selectedMediaLanguage: this.state.selectedMediaLanguage,
      id: this.state.id,
      creator: this.state.creator,
      url:this.state.url,
      type:this.state.type,
      dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
      medialanToSend:this.state.currentMediaLan,
      mediaresToSend:this.state.mediaresToSend,
      uriCoverExternalRes:this.state.uriCoverExternalRes,
      publicStatusExtRes:this.state.publicStatusExtRes
      ,textcloudcontent:this.state.textcloudcontent
    },
    this.state.file ? this.state.file:null));
    // document.getElementById('file-input').files[0]));
  }
  saveDescForLang =()=>{
    let descCopy=  Object.assign({}, this.state.description);
    if (this.state.descstate===""){
      // se l'elemento di lingua esiste lo cancelllo altrimenti niente
      if (descCopy[this.state.dataLanguage]){
        delete descCopy[this.state.dataLanguage]
      }
    }else{
      //creo o aggiorno l'elemento di lingua
      descCopy[this.state.dataLanguage]=this.state.descstate;
    }
    this.setState({description:descCopy}, this.props.dataform({
      name: this.state.name ,
      description:descCopy,
      title:this.state.title,
      selectedRight: this.state.selectedRight,
      linkRight: this.state.linkRight,
      degree: this.state.degreeMediaSelected,
      selectedMediaLanguage: this.state.selectedMediaLanguage,
      id: this.state.id,
      creator: this.state.creator,
      url:this.state.url,
      type:this.state.type,
      dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
      medialanToSend:this.state.currentMediaLan,
      mediaresToSend:this.state.mediaresToSend,
      uriCoverExternalRes:this.state.uriCoverExternalRes,
      publicStatusExtRes:this.state.publicStatusExtRes,
      hls:this.state.hls,
      dash:this.state.dash
      ,textcloudcontent:this.state.textcloudcontent
    },
    // document.getElementById('file').files[0]));
    this.state.file ? this.state.file:null));
  }
  saveTitleForLang = () => {
    let titleCopy = Object.assign({}, this.state.title);
    if (this.state.titlestate === "") {
      // se l'elemento di lingua esiste lo cancelllo altrimenti niente
      if (titleCopy[this.state.dataLanguage]) {
        delete titleCopy[this.state.dataLanguage]
      }
    } else {
      //creo o aggiorno l'elemento di lingua
      titleCopy[this.state.dataLanguage] = this.state.titlestate;
    }
    this.setState({ title: titleCopy }, this.props.dataform({
      name: this.state.name,
      description: this.state.description,
      title: titleCopy,
      selectedRight: this.state.selectedRight,
      linkRight: this.state.linkRight,
      degree: this.state.degreeMediaSelected,
      selectedMediaLanguage: this.state.selectedMediaLanguage,
      id: this.state.id,
      creator: this.state.creator,
      url:this.state.url,
      type:this.state.type,
      dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
      medialanToSend:this.state.currentMediaLan,
      mediaresToSend:this.state.mediaresToSend,
      uriCoverExternalRes:this.state.uriCoverExternalRes,
      publicStatusExtRes:this.state.publicStatusExtRes,
      hls:this.state.hls,
      dash:this.state.dash
      ,textcloudcontent:this.state.textcloudcontent
    },
    this.state.file ? this.state.file:null));    // document.getElementById('file-input').files[0]));
  }

  showMap = () => {
    this.props.appstate.MapDialog.render = true;
    this.props.appstate.MapDialog.openModalMap = true;
    this.props.dispatch(APP(this.props.appstate));
  }
  handleChangeRight = (e) => {
    //console.log(e.target.value);
    this.setState({ selectedRight: e.target.value, linkRight: getRights(e.target.value).uri }, this.props.dataform({
      name: this.state.name,
      description: this.state.description,
      title: this.state.title,
      selectedRight: e.target.value,
      linkRight: getRights(e.target.value).uri,
      degree: this.state.degreeMediaSelected,
      selectedMediaLanguage: this.state.selectedMediaLanguage,
      id: this.state.id,
      creator: this.state.creator,
      url:this.state.url,
      type:this.state.type,
      dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
      medialanToSend:this.state.currentMediaLan,
      mediaresToSend:this.state.mediaresToSend,
      uriCoverExternalRes:this.state.uriCoverExternalRes,
      publicStatusExtRes:this.state.publicStatusExtRes,
      hls:this.state.hls,
      dash:this.state.dash
      ,textcloudcontent:this.state.textcloudcontent
    },
    this.state.file ? this.state.file:null));
    // document.getElementById('file-input').files[0]));
  }
  handleChangeMediaLanguage = (e) => {
    //console.log(e.target.value)
    this.setState({ selectedMediaLanguage: e.target.value }, this.props.dataform({
      name: this.state.name,
      description: this.state.description,
      title: this.state.title,
      selectedRight: this.state.selectedRight,
      linkRight: this.state.linkRight,
      degree: this.state.degreeMediaSelected,
      selectedMediaLanguage: e.target.value,
      id: this.state.id,
      creator: this.state.creator,
      url:this.state.url,
      type:this.state.type,
      dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
      medialanToSend:this.state.currentMediaLan,
      mediaresToSend:this.state.mediaresToSend,
      uriCoverExternalRes:this.state.uriCoverExternalRes,
      publicStatusExtRes:this.state.publicStatusExtRes,
      hls:this.state.hls,
      dash:this.state.dash
      ,textcloudcontent:this.state.textcloudcontent
    },
    this.state.file ? this.state.file:null));
    // document.getElementById('file-input').files[0]));
  }
  handleChangeDataLanguage = (e) => {
    //gestione filtro
    let langfiltercopy = this.state.langfilter.slice().fill(false);
    // langfiltercopy[e.target.id]=e.target.checked;
    langfiltercopy[e.target.id] = true;
    let currentDescription = this.state.description[this.state.mediaLanguageList[e.target.id].code];
    let currentTitle = this.state.title[this.state.mediaLanguageList[e.target.id].code];
    if (!currentTitle) {
      currentTitle = "";
    }
    if (!currentDescription) {
      currentDescription = "";
    }
    this.setState({ dataLanguage: this.state.mediaLanguageList[e.target.id].code, langfilter: langfiltercopy, titlestate: currentTitle, descstate: currentDescription },
      this.props.dataform({
        name: this.state.name,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degree: this.state.degreeMediaSelected,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        id: this.state.id,
        creator: this.state.creator,
        url:this.state.url,
        type:this.state.type,
        dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
        medialanToSend:this.state.currentMediaLan,
        mediaresToSend:this.state.mediaresToSend,
        uriCoverExternalRes:this.state.uriCoverExternalRes,
        publicStatusExtRes:this.state.publicStatusExtRes,
        hls:this.state.hls,
        dash:this.state.dash
        ,textcloudcontent:this.state.textcloudcontent
      },
      this.state.file ? this.state.file:null));
      // document.getElementById('file-input').files[0])
      // );
    }
    newMediaLanHandler=(language)=>{
      // let langfiltercopy = this.state.langfilter.slice().fill(false);
      let lan= language;
      // check controllo se hls esiste
      let currentHls="";
      if  (this.state.hls){
        currentHls = this.state.hls[lan.toLowerCase()];
        if (!currentHls) {
          currentHls = "";
        }
      }
      let currentDash = "";
      if  (this.state.dash){
        currentDash =  this.state.dash[lan.toLowerCase()];
        if (!currentDash) {
          currentDash = "";
        }
      }



      let arraymediares_help = this.state.medialan[lan.toLowerCase()];
      let deleteLanguageFlag_help=false;
      if (!arraymediares_help) {
        arraymediares_help = [];
        deleteLanguageFlag_help=true;
      }
      this.setState({
        arraymediares:arraymediares_help,
        currentMediaLan:lan.toLowerCase(),
        file1:undefined,
        file2:undefined,
        file3:undefined,
        file4:undefined,
        file5:undefined,
        deleteLanguageFlag:deleteLanguageFlag_help,
        hlslink:currentHls,
        dashlink:currentDash
      });
    }
    worldLangHandler = (lang) => {
      let langfiltercopy = this.state.langfilter.slice().fill(false);
      let currentDescription = this.state.description[lang.toLowerCase()];
      let currentTitle = this.state.title[lang.toLowerCase()];
      if (!currentTitle) {
        currentTitle = "";
      }
      if (!currentDescription) {
        currentDescription = "";
      }
      this.setState({ dataLanguage: lang.toLowerCase(), langfilter: langfiltercopy, titlestate: currentTitle, descstate: currentDescription },
      this.props.dataform({
        name: this.state.name,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degree: this.state.degreeMediaSelected,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        dataLanguage: lang.toLowerCase(),
        id: this.state.id,
        creator: this.state.creator,
        url:this.state.url,
        type:this.state.type,
        dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
        medialanToSend:this.state.currentMediaLan,
        mediaresToSend:this.state.mediaresToSend,
        uriCoverExternalRes:this.state.uriCoverExternalRes,
        publicStatusExtRes:this.state.publicStatusExtRes,
        hls:this.state.hls,
        dash:this.state.dash
        ,textcloudcontent:this.state.textcloudcontent
      },
      this.state.file ? this.state.file:null));
      // document.getElementById('file-input').files[0]));
      // this.setState({dataLanguage:lang.toLowerCase(),langfilter:langfiltercopy});
    }
    handleDegreeType = (e) => {
      let internalDegree = "";
      if (e.target.checked) {
        internalDegree = 3;
      } else {
        internalDegree = 2;
      }
      this.setState({ degreeMedia: e.target.checked, degreeMediaSelected: internalDegree }, this.props.dataform({
        name: this.state.name,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degree: internalDegree,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        id: this.state.id,
        creator: this.state.creator,
        url:this.state.url,
        type:this.state.type,
        dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
        medialanToSend:this.state.currentMediaLan,
        mediaresToSend:this.state.mediaresToSend,
        uriCoverExternalRes:this.state.uriCoverExternalRes,
        publicStatusExtRes:this.state.publicStatusExtRes,
        hls:this.state.hls,
        dash:this.state.dash
        ,textcloudcontent:this.state.textcloudcontent
      },
      this.state.file ? this.state.file:null));
      // document.getElementById('file-input').files[0]));
    }

    getKeyLang = (strinput) => {
      if (this.props.acamar.configuration.Languages && strinput) {
        let keylang = "en";
        if (strinput[this.props.acamar.configuration.Languages.default]) {
          return this.props.acamar.configuration.Languages.default;
        } else if (strinput[keylang]) {
          return keylang;
        } else {
          let allKey = Object.keys(strinput);
          if (allKey.length > 0) {
            return allKey[0];
          }
        }
      }
      return "";
    }

    coverUrl = (id, type,mediaData) => {
      let ser = process.env.REACT_APP_DOMAIN + services.singleresourcemanager_GET_AUTH.replace("{APPNAME}", this.props.appstate.App.name);
      ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
      ser = ser.replace("{RESOURCEID}", id);
      ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
      if (type === "audio" || type === "video") {
        ser = ser.replace("{COVER}", false);
      } else {
        ser = ser.replace("{COVER}", true);
      }
      ser=ser.replace("{MEDIARES}",mediaData.resolution );
      ser=ser.replace("{MEDIALAN}",mediaData.lan);
      return ser + "&t=" + new Date().getTime();
    }

    openFlagsDialog = () => {
      this.flagsDialogRef.init();
      this.props.appstate.FlagsDialog.render = true;
      this.props.appstate.FlagsDialog.openModalFlags = true;
      let titlearr = Object.keys(this.state.title);
      let descarr = Object.keys(this.state.description);
      if (titlearr.length >= descarr.length) {
        this.props.appstate.FlagsDialog.items = titlearr;
      } else {
        this.props.appstate.FlagsDialog.items = descarr;
      }
      this.props.dispatch(APP(this.props.appstate));
    }

    openInfoDialog = (event) => {
      console.log(event.currentTarget.name)
      this.props.appstate.InfoDialog.render = true;
      this.props.appstate.InfoDialog.openModalInfo = true;
      this.props.appstate.InfoDialog.tagElement = event.currentTarget.name;
      this.props.appstate.InfoDialog.lan=this.state.currentMediaLan;
      this.props.appstate.InfoDialog.items = this.props.appstate.ResourceManager.resources[this.props.appstate.ResourceManager.index];
      this.props.dispatch(APP(this.props.appstate));
      this.infoDialogRef.init();
    }


    openLanDialog = () => {
      this.lanDialogRef.init();
      this.props.appstate.LanDialog.render = true;
      this.props.appstate.LanDialog.openModalLan = true;
      let arrayLan=[];
      Object.keys(this.state.medialan).forEach(function(key) {
        arrayLan.push(key);});
        this.props.appstate.LanDialog.items= arrayLan;
        this.props.dispatch(APP(this.props.appstate));
      }

      callBackFlagsDialog = (lang) => {
        this.worldLangHandler(lang);
      }
      callBackLanDialog= (lang) => {
        this.newMediaLanHandler(lang)
      }
      selectMediaLanguage=(event)=>{
        this.newMediaLanHandler(event.target.value);
      }
      selectLanguage = (event) => {
        this.worldLangHandler(event.target.value);
      }
      handleChangeExternalMediaType=(e)=>{
        // this.setState({selectedExternalMediaType:e.target.value});
        this.setState({type:e.target.value, uriCoverExternalRes:"" ,publicStatusExtRes:false },this.props.dataform({
          name: this.state.name,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degree: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          id: this.state.id,
          creator: this.state.creator,
          url:this.state.url,
          type:e.target.value,
          dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
          medialanToSend:this.state.currentMediaLan,
          mediaresToSend:this.state.mediaresToSend,
          uriCoverExternalRes:"",
          publicStatusExtRes:false,
          hls:this.state.hls,
          dash:this.state.dash,textcloudcontent:this.state.textcloudcontent
        },
        null));
      }

      deleteLanguage=()=>{
        console.log("delete lang");
        let item= this.props.appstate.ResourceManager.resources[this.props.appstate.ResourceManager.index];
        let data={
          domain:this.props.appstate.App.user.domain,
          id: item._id,
          user: this.props.appstate.App.user.userid,
          appname: this.props.appstate.App.name,
          medialan: this.state.currentMediaLan
        }
        this.props.deleteLan(data)
      }

      handlePublicStatusExternalWebResource=(e)=>{
        this.setState({ publicStatusExtRes: e.target.checked }, this.props.dataform({
          name: this.state.name,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degree: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          id: this.state.id,
          creator: this.state.creator,
          url:this.state.url,
          type:this.state.type,
          dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
          medialanToSend:this.state.currentMediaLan,
          mediaresToSend:this.state.mediaresToSend,
          publicStatusExtRes:e.target.checked,
          uriCoverExternalRes:this.state.uriCoverExternalRes,
          hls:this.state.hls,
          dash:this.state.dash,textcloudcontent:this.state.textcloudcontent
        },
        this.state.file ? this.state.file:null));
      }





      handlePublicStatus=(e)=>{
        console.log(e.target.checked);
        this.setState({ publicStatusExtRes: e.target.checked }, this.props.dataform({
          name: this.state.name,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degree: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          id: this.state.id,
          creator: this.state.creator,
          url:this.state.url,
          type:this.state.type,
          dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
          medialanToSend:this.state.currentMediaLan,
          mediaresToSend:this.state.mediaresToSend,
          publicStatusExtRes:e.target.checked,
          uriCoverExternalRes:this.state.uriCoverExternalRes,
          hls:this.state.hls,
          dash:this.state.dash,textcloudcontent:this.state.textcloudcontent
        },
        this.state.file ? this.state.file:null));
      }




      deleteResolution=(e)=>{
        console.log("deleteResolution:" +e.currentTarget.id)
        let item= this.props.appstate.ResourceManager.resources[this.props.appstate.ResourceManager.index];
        let data={
          domain:this.props.appstate.App.user.domain,
          id: item._id,
          user: this.props.appstate.App.user.userid,
          appname: this.props.appstate.App.name,
          medialan: this.state.currentMediaLan
        }
        switch (e.currentTarget.id) {
          case "secondResolution":
          data.mediares="second"
          break;
          case "thirdResolution":
          data.mediares="third"
          break;
          case "fourthResolution":
          data.mediares="fourth"
          break;
          case "fifthResolution":
          data.mediares="fifth"
          break;
          default:
          break;
        }
        this.props.deleteResolution(data)
      }
      dataformText=(textObj)=>{
        console.log("dataformText")
        console.log(textObj)
        this.setState({ textcloudcontent: textObj }, this.props.dataform({
          name: this.state.name,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degree: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          id: this.state.id,
          creator: this.state.creator,
          url:this.state.url,
          type:this.state.type,
          dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
          medialanToSend:this.state.currentMediaLan,
          mediaresToSend:this.state.mediaresToSend,
          publicStatusExtRes:this.state.publicStatusExtRes,
          uriCoverExternalRes:this.state.uriCoverExternalRes,
          hls:this.state.hls,
          dash:this.state.dash,
          textcloudcontent: textObj
        },
        this.state.file ? this.state.file:null));
        // this.props.dataform({});
      }
      //funzione chiamata dal componente Weak che salva i dati della sezione upload per le weak resource
      dataformWeakRes=(data,type)=>{
        console.log("dataformWeakRes");
        switch (type) {
          case "coverUrl":
          this.setState({ uriCoverExternalRes:data},this.props.dataform({
            name: this.state.name,
            description: this.state.description,
            title: this.state.title,
            selectedRight: this.state.selectedRight,
            linkRight: this.state.linkRight,
            degree: this.state.degreeMediaSelected,
            selectedMediaLanguage: this.state.selectedMediaLanguage,
            id: this.state.id,
            creator: this.state.creator,
            url:this.state.url,
            type:this.state.type,
            dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
            medialanToSend:this.state.currentMediaLan,
            mediaresToSend:this.state.mediaresToSend,
            uriCoverExternalRes:data,
            publicStatusExtRes:this.state.publicStatusExtRes,
            hls:this.state.hls,
            dash:this.state.dash
            ,textcloudcontent:this.state.textcloudcontent
          },
          null));
          break;
          case "publicStatus":
          this.setState({ publicStatusExtRes: data }, this.props.dataform({
            name: this.state.name,
            description: this.state.description,
            title: this.state.title,
            selectedRight: this.state.selectedRight,
            linkRight: this.state.linkRight,
            degree: this.state.degreeMediaSelected,
            selectedMediaLanguage: this.state.selectedMediaLanguage,
            id: this.state.id,
            creator: this.state.creator,
            url:this.state.url,
            type:this.state.type,
            dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
            medialanToSend:this.state.currentMediaLan,
            mediaresToSend:this.state.mediaresToSend,
            publicStatusExtRes:data,
            uriCoverExternalRes:this.state.uriCoverExternalRes,
            hls:this.state.hls,
            dash:this.state.dash,textcloudcontent:this.state.textcloudcontent
          },
          this.state.file ? this.state.file:null));

          break;
          case "degreeMedia":
          let internalDegree = "";
          if (data) {
            internalDegree = 3;
          } else {
            internalDegree = 2;
          }
          this.setState({ degreeMedia: data, degreeMediaSelected: internalDegree }, this.props.dataform({
            name: this.state.name,
            description: this.state.description,
            title: this.state.title,
            selectedRight: this.state.selectedRight,
            linkRight: this.state.linkRight,
            degree: internalDegree,
            selectedMediaLanguage: this.state.selectedMediaLanguage,
            id: this.state.id,
            creator: this.state.creator,
            url:this.state.url,
            type:this.state.type,
            dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
            medialanToSend:this.state.currentMediaLan,
            mediaresToSend:this.state.mediaresToSend,
            uriCoverExternalRes:this.state.uriCoverExternalRes,
            publicStatusExtRes:this.state.publicStatusExtRes,
            hls:this.state.hls,
            dash:this.state.dash
            ,textcloudcontent:this.state.textcloudcontent
          },
          this.state.file ? this.state.file:null));
          break;
          case "url":
          this.setState({ url:data},this.props.dataform({
            name: this.state.name,
            description: this.state.description,
            title: this.state.title,
            selectedRight: this.state.selectedRight,
            linkRight: this.state.linkRight,
            degree: this.state.degreeMediaSelected,
            selectedMediaLanguage: this.state.selectedMediaLanguage,
            id: this.state.id,
            creator: this.state.creator,
            url:data,
            type:this.state.type,
            dcRightsHolder:this.state.dcRightsHolder,folderIdentifier:this.state.folderIdentifier,
            medialanToSend:this.state.currentMediaLan,
            mediaresToSend:this.state.mediaresToSend,
            uriCoverExternalRes:this.state.uriCoverExternalRes,
            publicStatusExtRes:this.state.publicStatusExtRes,
            hls:this.state.hls,
            dash:this.state.dash
            ,textcloudcontent:this.state.textcloudcontent

          },
          null));
          break;

          default:
          break;
        }
        // gestire come datafromText ma con le varianti dei dati in piu es show in showroom
      }


      render() {
        let jsonlang = this.props.appstate.App.language;
        let itemData = this.props.appstate.ResourceManager.resources[this.props.appstate.ResourceManager.index];
        return (
          <div>
            <FlagsDialog onRef={childthis => (this.flagsDialogRef = childthis)} callback={this.callBackFlagsDialog}/>
            <LanDialog onRef={childthis => (this.lanDialogRef = childthis)} callback={this.callBackLanDialog}/>
            <InfoDialog onRef={childthis => (this.infoDialogRef = childthis)} callback={this.callBackFlagsDialog}/>
            <Grid container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className="gridmargin">
              <Grid item xs={2}>
                <Typography variant="button" display="block">
                  {jsonlang.identifier.toUpperCase()}
                </Typography>
                {/* <label for="confirm">{jsonlang.nome.toUpperCase()}</label> */}
              </Grid>

              <Grid item xs={9}>
                <div className="gridmargin">
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="nameres"
                    defaultValue=""
                    label={jsonlang.identifier.toUpperCase()}
                    value={this.state.name}
                    onChange={this.textfiedlvalue}
                  />
                </div>
              </Grid>
              <Grid item xs={1}>
                <div className="gridmargin">
                  <Tooltip title={<h2>{jsonlang.backupres.toUpperCase()}</h2>}>
                  <Checkbox
                    checked={this.state.backupstatus}
                    icon={<CloudOffIcon />}
                    checkedIcon={<CloudDoneIcon color="primary"/>}
                  />
                </Tooltip>
              </div>
            </Grid>
          </Grid>


          <Divider />
          <div className="gridmargin">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className="gridmargin">
              <Grid item xs={2}>
                <Typography variant="button" display="block">
                  {jsonlang.selelang.toUpperCase()}
                </Typography>
                {/* <label for="confirm">{jsonlang.selelang.toUpperCase()}</label> */}
              </Grid>
              {/* <Divider orientation="vertical" flexItem /> */}
              <Grid item xs={3}>
                <FormControl>
                  <InputLabel id="languages_choose">Languages</InputLabel>
                  <Select
                    style={{ minWidth: 356, maxHeight: 70}}
                    labelId="languages_choose"
                    id="languages_select"
                    value={this.state.dataLanguage.toLowerCase()}
                    onChange={this.selectLanguage}
                    displayEmpty
                    // variant={"outlined"}
                    defaultValue={"en"}
                    >
                      <MenuItem disabled value="">
                        <em>{jsonlang.language.toUpperCase()}</em>
                      </MenuItem>
                      <MenuItem selected disabled value="">
                        {/* <h3>{jsonlang.selelang.toUpperCase()}</h3> */}
                      </MenuItem>
                      {languages.map((item, index) =>
                        <MenuItem key={index} value={item.code} selected>
                          <ListItemIcon>
                            <img
                              name={item.code}
                              alt={item.code}
                              style={{ width: "32px", height: "32px" }}
                              src={"/images/languages/" + item.code + ".svg"}>
                            </img>
                          </ListItemIcon>
                          <Typography variant="inherit">{item.name}</Typography>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  // style={{margin: "10px 10px 10px 10px"}}
                  item xs={1}
                  onClick={this.openFlagsDialog}
                  >
                    <Tooltip title={<h2>{jsonlang.lanset.toUpperCase()}</h2>}>
                    <IconButton color="primary"
                      role="button"
                      size="small"
                      aria-label={jsonlang.adda.toUpperCase()}
                      onClick={this.openFlagsDialog}
                      >
                        <LanguageIcon
                          style={{ fontSize: 40 }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  {/* <Divider orientation="vertical" flexItem /> */}
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      style={{width:"95%"}}
                      id="title"
                      label={jsonlang.title.toUpperCase()}
                      defaultValue=""
                      onBlur={this.saveTitleForLang}
                      value={this.state.titlestate}
                      onChange={this.textfiedlvalue}
                      disabled={this.state.dataLanguage === "" ? true : false}
                    />
                  </Grid>
                  {/* <Divider orientation="vertical" flexItem /> */}
                  <Grid item xs={3}>
                    <TextField
                      // fullWidth
                      style={{width:"95%"}}
                      variant="outlined"
                      id="descres"
                      label={jsonlang.desc.toUpperCase()}
                      defaultValue=""
                      onBlur={this.saveDescForLang}
                      value={this.state.descstate}
                      onChange={this.textfiedlvalue}
                      disabled={this.state.dataLanguage === "" ? true : false}
                    />
                  </Grid>
                </Grid>
              </div>

              <Divider />
              <div className="gridmargin">
                <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className="gridmargin" >

                  <Grid item xs={2}>
                    <Typography variant="button" display="block">
                      {/* {jsonlang.medialan.toUpperCase()} / {jsonlang.rights.toUpperCase()} / {jsonlang.creator.toUpperCase()} */}
                      {"RIGHTS/ POSITION / CREATOR/ RIGHTS HOLDER"}

                    </Typography>
                  </Grid>

                  {/* <Divider orientation="vertical" flexItem /> */}
                  <Grid item xs={3}>
                    <div>
                      {/* <label for="confirm">{jsonlang.selerig.toUpperCase()}</label> */}
                      <Select
                        required
                        id="id"
                        displayEmpty
                        fullWidth
                        value={this.state.selectedRight}
                        onChange={this.handleChangeRight}
                        >
                          <MenuItem disabled value="">
                            <em>{jsonlang.selerig.toUpperCase()}</em>
                          </MenuItem>
                          {this.state.rights.map((right, i) =>
                            <MenuItem key={i} className="MuiInputBase-input-custom" value={right.value}>{right.value}</MenuItem>
                          )}
                        </Select>
                        {getRights(this.state.selectedRight).uri===""?
                        <img className="rights-img-RM" alt="CC-BY-NC-ND" src={getRights(this.state.selectedRight).logo}/>
                        :
                        <Tooltip title={jsonlang.rightInfo.toUpperCase()}>
                          <Link
                            color="inherit"
                            role="link"
                            aria-label="Public Domain - No Copyright"
                            href={getRights(this.state.selectedRight).uri}
                            target="blank"
                            >
                              <img className="rights-img-RM" alt="CC-BY-NC-ND" src={getRights(this.state.selectedRight).logo}/>
                            </Link>
                          </Tooltip>
                        }
                      </div>
                    </Grid>

                    {/* <Divider orientation="vertical" flexItem /> */}
                    <Grid item xs={1} onClick={this.showMap}>
                      <Tooltip title={<h2>{jsonlang.position.toUpperCase()}</h2>}>
                      <IconButton color="primary"
                        role="button"
                        size="small"
                        aria-label={jsonlang.adda.toUpperCase()}
                        onClick={this.showMap} >
                        <RoomIcon style={{ fontSize: 40 }}
                        />
                      </IconButton>
                    </Tooltip>
                    {parseFloat(this.props.appstate.MapDialog.position.lat).toFixed(3) > 0 && parseFloat(this.props.appstate.MapDialog.position.lng).toFixed(3) > 0 ?
                      <label> {this.props.appstate.MapDialog.position ? parseFloat(this.props.appstate.MapDialog.position.lat).toFixed(3) + "," + parseFloat(this.props.appstate.MapDialog.position.lng).toFixed(3) : ""} </label> :
                      <label> </label>}
                    </Grid>
                    <Grid item xs={3}>
                      <Tooltip title={<h2>{jsonlang.creatordesc}</h2>}>
                      <div>
                        <TextField
                          variant="outlined"
                          // fullWidth
                          style={{width:"95%"}}
                          id="creator"
                          label={jsonlang.creator.toUpperCase()}
                          defaultValue=""
                          value={this.state.creator}
                          onChange={this.textfiedlvalue}
                        />
                      </div>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={3}>
                    <Tooltip title={<h2>{jsonlang.rightsholdertip}</h2>}>
                    <div>
                      <TextField
                        variant="outlined"
                        // fullWidth
                        style={{width:"95%"}}
                        id="dcRightsHolder"
                        label={jsonlang.rightsholder.toUpperCase()}
                        defaultValue=""
                        value={this.state.dcRightsHolder}
                        onChange={this.textfiedlvalue}
                      />
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            </div>
            <Divider />
            <div className="gridmargin">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="gridmargin">
                <Grid item  xs={2}>
                  <Typography variant="button" display="block">
                    {/* {jsonlang.medialan.toUpperCase()} / {jsonlang.rights.toUpperCase()} / {jsonlang.creator.toUpperCase()} */}
                    {"UPLOAD"}
                  </Typography>
                </Grid>

                {/* new language */}
                {this.state.type==="textcloud"?

                <TextCloudHandler dataform={this.dataformText}/>
                :
                <>
                {getExternalMediaType().findIndex(x => x.value === this.state.type)!==-1 && (typeof(this.state.url)!=='string' )?
                <Grid item  xs={10}>
                  <WeakResourceHandler dataform={this.dataformWeakRes}/>
                </Grid>

                :
                <>
                {getExternalMediaType().findIndex(x => x.value === this.state.type)===-1 ?

                  <Grid item  xs={4}>
                    {/* <Toolbar> */}

                    <Select
                      style={{ minWidth: 360, maxHeight: 70}}
                      labelId="media_languages_choose"
                      id="media_languages_select"
                      value={this.state.currentMediaLan.toLowerCase()}
                      onChange={this.selectMediaLanguage}
                      displayEmpty
                      >
                        <MenuItem disabled value="">
                          <em>{jsonlang.language.toUpperCase()}</em>
                        </MenuItem>
                        {languages2.map((item, index) =>
                          <MenuItem key={index} value={item.code} selected>
                            <ListItemIcon>
                              <img alt="flag" name={item.code} style={{ width: "32px", height: "32px" }} src={"/images/languages/" + item.code + ".svg"}></img>
                            </ListItemIcon>
                            <Typography variant="inherit">{item.name}</Typography>
                          </MenuItem>
                        )}
                      </Select>

                      <IconButton color="primary"
                        role="button"
                        size="small"
                        aria-label={jsonlang.adda.toUpperCase()}
                        onClick={this.openLanDialog}
                        >
                          <PlaylistAddCheckIcon style={{ fontSize: 40 }}
                          />
                        </IconButton>
                        <IconButton color="primary"
                          role="button"
                          size="small"
                          disabled={this.state.deleteLanguageFlag}
                          aria-label={jsonlang.adda.toUpperCase()}
                          onClick={this.deleteLanguage}
                          >
                            <DeleteForeverIcon style={{ fontSize: 40 }}
                            />
                          </IconButton>

                          {/* </Toolbar> */}
                        </Grid> :null}</>

                      }
                    </>
                  }
                  {/* <Grid item  xs={4}>
                  <Toolbar>

                  <Select
                  style={{ minWidth: 200, maxHeight: 70}}
                  labelId="media_languages_choose"
                  id="media_languages_select"
                  value={this.state.currentMediaLan.toLowerCase()}
                  onChange={this.selectMediaLanguage}
                  displayEmpty
                  >
                  <MenuItem disabled value="">
                  <em>{jsonlang.language.toUpperCase()}</em>
                </MenuItem>
                {languages2.map((item, index) =>
                <MenuItem key={index} value={item.code} selected>
                <ListItemIcon>
                <img name={item.code} style={{ width: "32px", height: "32px" }} src={"/images/languages/" + item.code + ".svg"}></img>
              </ListItemIcon>
              <Typography variant="inherit">{item.name}</Typography>
            </MenuItem>
          )}
        </Select>

        <IconButton color="primary"
        role="button"
        size="small"
        aria-label={jsonlang.adda.toUpperCase()}
        onClick={this.openLanDialog}
        >
        <PlaylistAddCheckIcon style={{ fontSize: 40 }}
      />
    </IconButton>
    <IconButton color="primary"
    role="button"
    size="small"
    disabled={this.state.deleteLanguageFlag}
    aria-label={jsonlang.adda.toUpperCase()}
    onClick={this.deleteLanguage}
    >
    <DeleteForeverIcon style={{ fontSize: 40 }}
  />
</IconButton>

</Toolbar>
</Grid> */}

{/* Caso vecchio weak resource senza multilingua per permettere la modifica  */}
{getExternalMediaType().findIndex(x => x.value == this.state.type)!==-1  && (typeof(this.state.url)==='string' ) ?

<div
  // style={{padding:10}}
  >

    <TextField
      variant="outlined"
      fullWidth
      id="url"
      label="url"
      defaultValue=""
      value={this.state.url}
      onChange={this.textfiedlvalue}
    />

    <Select
      required
      id="extmediatype"
      disabled={true}
      displayEmpty
      fullWidth
      style={{"marginBottom":"10px"}}
      value={this.state.type}
      onChange={this.handleChangeExternalMediaType}
      >
        <MenuItem disabled value="">
          <em>Media Type</em>
        </MenuItem>
        {getExternalMediaType().map((extType, i) =>
          <MenuItem  key={extType.id} className="MuiInputBase-input-custom" value={extType.value}>
            {extType.label}
          </MenuItem>
        )}
      </Select>
      {this.state.type ==="imagecloud" || this.state.type==="videocloud"?
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>2d</Grid>
        <Grid item>
          <Switch checked={this.state.degreeMedia} onChange={this.handleDegreeType} color='primary'/>
        </Grid>
        <Grid item>360</Grid>
      </Grid>
      :
      null}

      <Grid component="label" container alignItems="center" spacing={1}>
        <TextField
          variant="outlined"
          fullWidth
          id="coverUrl"
          label="Cover Url"
          defaultValue=""
          value={this.state.uriCoverExternalRes}
          onChange={this.textfiedlvalue}
        />
        <Grid item>Show In {jsonlang.showroomMUME}</Grid>
        <Grid item>
          <Switch checked={this.state.publicStatusExtRes} onChange={this.handlePublicStatusExternalWebResource} color='primary'/>
        </Grid>
      </Grid>

    </div>
    :
    <>


    {this.state.type!=="textcloud" &&  getExternalMediaType().findIndex(x => x.value == this.state.type)===-1?
    <Grid item sm={3} xs={1}>
      <input
        ref="fileInput"
        onChange={this.fileChoiced}
        type="file"
        accept={mediatypes.resourcesMT}
        style={{ display: "none" }}
        id="first"
        // multiple={false}
      />
      <Tooltip title={<h2>{jsonlang.upload.toUpperCase()}</h2>}>
      <Button
        variant="contained"
        color="primary"
        aria-label="upload resource"
        component="span"
        size="small"
        startIcon={<CloudUploadIcon />}
        onClick={() => this.refs.fileInput.click()}
        >
          {jsonlang.upload.toUpperCase()}
        </Button>
      </Tooltip>
      <Tooltip title={jsonlang.mediatype.toUpperCase()+":"+ mediatypes.resourcesMT}>
        <InfoIcon color="grey" fontSize="small"/>
      </Tooltip>
      {(this.state.file1 && this.state.file1.type !== "audio/mpeg") || itemData.type !== "audio" && itemData.type !== "document"  ?
      <>
      <Grid component="label" container alignItems="center">
        <Grid item>2d</Grid>
        <Grid item>
          <Switch checked={this.state.degreeMedia} onChange={this.handleDegreeType} color='primary' />
        </Grid>
        <Grid item>360</Grid>
      </Grid>
      {/* <Grid item>Show In {jsonlang.showroomMUME}</Grid>
      <Grid item>
      <Switch checked={this.state.publicStatusExtRes} onChange={this.handlePublicStatus} color='primary'/>
    </Grid> */}
    </>
      : null}
    </Grid>
    :
    //  caso textcloud
    null
  }

</>
}
<Grid item sm={3} xs={12}>
  {this.state.type!=="webcloud" && this.state.type!=="textcloud" &&
  this.state.type!=="imagecloud" && this.state.type!=="videocloud" && this.state.type!=="audiocloud"?
  <><label>{jsonlang.resprev.toUpperCase()}</label> <br /></>
  :null}


  {this.state.file1 ?


    (this.state.file1.type === "audio/mpeg" ? <audio controls width="50px" height="auto" src={this.state.file1 ? URL.createObjectURL(this.state.file1) : this.coverUrl(itemData._id, null,{lan:this.state.currentMediaLan,resolution:"first"})} /> :
    this.state.file1.type === "video/mp4" ? <video controls width="150px" height="auto" src={this.state.file1 ? URL.createObjectURL(this.state.file1) : this.coverUrl(itemData._id, null,{lan:this.state.currentMediaLan,resolution:"first"})} /> :
    this.state.file1.type === "image/jpeg" ?  <img alt="" width="150px" height="auto" src={this.state.file1 ? URL.createObjectURL(this.state.file1) : this.coverUrl(itemData._id, null,{lan:this.state.currentMediaLan,resolution:"first"})} /> :
    this.state.file1.type === "image/png" ? <img alt="" width="150px" height="auto" src={this.state.file1 ? URL.createObjectURL(this.state.file1) : this.coverUrl(itemData._id, null,{lan:this.state.currentMediaLan,resolution:"first"})} /> :
    null) :
    (itemData.type === "audio" ? <audio controls width="50px" height="auto" src={this.coverUrl(itemData._id, itemData.type,{lan:this.state.currentMediaLan,resolution:"first"})} /> :
    itemData.type === "video" ? <video controls width="150px" height="auto" src={this.coverUrl(itemData._id, itemData.type,{lan:this.state.currentMediaLan,resolution:"first"})} /> :
    itemData.type === "image" ? <img alt="" width="150px" height="auto" src={this.coverUrl(itemData._id, itemData.type,{lan:this.state.currentMediaLan,resolution:"first"})} /> :
    null)
  }
  {/* {this.state.url!=="" && this.state.type!=="" ?
  this.state.type==="videocloud"? <video controls width="150px"height="auto" src={this.state.url}/>:
  this.state.type==="imagecloud"?  <img  alt="" width="150px"height="auto" src={this.state.url}/>:
  <Typography >{jsonlang.notpreview.toUpperCase()}</Typography>:
  null
} */}
</Grid>
</Grid>

</div>

{ itemData.type==="video"?
<Grid container
  style={{padding:10}}
  direction="row"
  justifyContent="center"
  alignItems="center"
  className="gridmargin">
  <Grid item sm={3} xs={12}>
    <TextField
      variant="outlined"
      fullWidth
      id="dash"
      label={"DASH-MANIFEST"}
      defaultValue=""
      onBlur={this.saveDashForLang}
      value={this.state.dashlink}
      onChange={this.textfiedlvalue}
    />
  </Grid>
  <Grid item sm={3} xs={12}>
    <TextField
      variant="outlined"
      fullWidth
      id="hls"
      label={"HLS-MANIFEST"}
      defaultValue=""
      onBlur={this.saveHlsForLang}
      value={this.state.hlslink}
      onChange={this.textfiedlvalue}
    />
  </Grid>
</Grid>
:null}
{/* secondo elemento */}
{ itemData.type==="image"?
<div>
  <Divider />
  {this.state.arraymediares.includes("first") && (this.state.url===""||this.state.url===null)?
  <div className="gridmargin">
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className="gridmargin">
      <Grid item  xs={2}>
        <Typography variant="button" display="block">
          {/* {jsonlang.medialan.toUpperCase()} / {jsonlang.rights.toUpperCase()} / {jsonlang.creator.toUpperCase()} */}
          {"UPLOAD Second element"}
        </Typography>
      </Grid>


      <Grid item xs={3}>
        <input
          ref="fileInput2"
          onChange={this.fileChoiced}
          type="file"
          accept={this.state.degreeMedia? mediatypes.resourcesMTWithBasis: mediatypes.resourcesMT }
          style={{ display: "none" }}
          id="second"
          // multiple={false}
        />
        <Tooltip title={<h2>{jsonlang.upload.toUpperCase()}</h2>}>
        <Button
          variant="contained"
          color="primary"
          aria-label="upload resource"
          component="span"
          size="small"
          startIcon={<CloudUploadIcon />}
          onClick={() => this.refs.fileInput2.click()}
          >
            {jsonlang.upload.toUpperCase()}
          </Button>
        </Tooltip>
        <Tooltip title={jsonlang.mediatype.toUpperCase()+":"+ mediatypes.resourcesMT}>
          <InfoIcon color="grey" fontSize="small"/>
        </Tooltip>
        <IconButton color="primary"
          role="button"
          size="small"
          id="secondResolution"
          disabled={this.state.arraymediares.includes("second")&& (this.state.url==="" ||this.state.url===null) &&
          !this.state.arraymediares.includes("third")&&
          !this.state.arraymediares.includes("fourth")&&
          !this.state.arraymediares.includes("fifth")
          ? false:true}
          aria-label={jsonlang.adda.toUpperCase()}
          onClick={this.deleteResolution}
          >
            <DeleteForeverIcon style={{ fontSize: 40 }}
            />
          </IconButton>
          {/* {(this.state.file && this.state.file.type !== "audio/mpeg") || itemData.type !== "audio" && itemData.type !== "document"  ?
          <Grid component="label" container alignItems="center">
          <Grid item>2d</Grid>
          <Grid item>
          <Switch checked={this.state.degreeMedia} onChange={this.handleDegreeType} color='primary' />
        </Grid>
        <Grid item>360</Grid>
      </Grid>
      : null} */}
    </Grid>
    <Grid item  xs={4}>
      {/* lasciato intenzionalemente vuoto */}
    </Grid>

    <Grid item xs={3}>
      <label>{jsonlang.resprev.toUpperCase()}</label> <br />
      {/* <label> {this.state.filename}</label><br /> */}
      {this.state.file2 ?
        (this.state.file2.type === "audio/mpeg" ? <audio controls width="50px" height="auto" src={this.state.file2 ? URL.createObjectURL(this.state.file2) : this.coverUrl(itemData._id,null,{lan:this.state.currentMediaLan,resolution:"second"})} /> :
        this.state.file2.type === "video/mp4" ? <video controls width="150px" height="auto" src={this.state.file2 ? URL.createObjectURL(this.state.file2) : this.coverUrl(itemData._id,null,{lan:this.state.currentMediaLan,resolution:"second"})} /> :
        this.state.file2.type === "image/jpeg" ? <img alt="" width="150px" height="auto" src={this.state.file2 ? URL.createObjectURL(this.state.file2) : this.coverUrl(itemData._id,null,{lan:this.state.currentMediaLan,resolution:"second"})} /> :
        this.state.file2.type === "image/png" ? <img alt="" width="150px" height="auto" src={this.state.file2 ? URL.createObjectURL(this.state.file2) : this.coverUrl(itemData._id,null,{lan:this.state.currentMediaLan,resolution:"second"})} /> :
        null) :
        (itemData.type === "audio" ? <audio controls width="50px" height="auto" src={this.coverUrl(itemData._id, itemData.type,{lan:this.state.currentMediaLan,resolution:"second"})} /> :
        itemData.type === "video" ? <video controls width="150px" height="auto" src={this.coverUrl(itemData._id, itemData.type,{lan:this.state.currentMediaLan,resolution:"second"})} /> :
        itemData.type === "image" ?  <div><label> {
          this.state.medialanextload?
          this.state.medialanextload[this.state.currentMediaLan]?
          this.state.medialanextload[this.state.currentMediaLan][1]?
          this.state.medialanextload[this.state.currentMediaLan][1].second?
          this.state.medialanextload[this.state.currentMediaLan][1].second==="true"
          ?"High performance Format Data":"Image Format Data"
          :""
          :""
          :"":""}
        </label> <br /><img alt="" width="150px" height="auto" src={this.coverUrl(itemData._id, itemData.type,{lan:this.state.currentMediaLan,resolution:"second"})} />
        <Tooltip title={<h2>{"INFO"}</h2>}>
        <IconButton
          onClick={this.openInfoDialog}
          name="second"
          color="primary"
          disabled={this.state.arraymediares.includes("second")&&  (this.state.url==="" ||this.state.url===null)

          ? false:true}
          ><InfoIcon color="grey" fontSize="small"/></IconButton>
        </Tooltip>
      </div>

      :null)
    }

  </Grid>
  {/* <Grid item sm={4} xs={12}>

</Grid>       */}
</Grid>

</div>
:
null}
{/* terzo */}
{this.state.arraymediares.includes("second")&& (this.state.url===""||this.state.url===null)?
<div className="gridmargin">
  <Divider />
  <Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    className="gridmargin">
    <Grid item  xs={2}>
      <Typography variant="button" display="block">
        {/* {jsonlang.medialan.toUpperCase()} / {jsonlang.rights.toUpperCase()} / {jsonlang.creator.toUpperCase()} */}
        {"UPLOAD third elemement"}
      </Typography>
    </Grid>
    {/* <Divider orientation="vertical" flexItem /> */}


    <Grid item xs={3}>
      <input
        ref="fileInput3"
        onChange={this.fileChoiced}
        type="file"
        accept={this.state.degreeMedia? mediatypes.resourcesMTWithBasis: mediatypes.resourcesMT}
        style={{ display: "none" }}
        id="third"
        // multiple={false}
      />
      <Tooltip title={<h2>{jsonlang.upload.toUpperCase()}</h2>}>
      <Button
        variant="contained"
        color="primary"
        aria-label="upload resource"
        component="span"
        size="small"
        startIcon={<CloudUploadIcon />}
        onClick={() => this.refs.fileInput3.click()}
        >
          {jsonlang.upload.toUpperCase()}
        </Button>
      </Tooltip>
      <Tooltip title={jsonlang.mediatype.toUpperCase()+":"+ mediatypes.resourcesMT}>
        <InfoIcon color="grey" fontSize="small"/>
      </Tooltip>
      <IconButton color="primary"
        role="button"
        size="small"
        id="thirdResolution"
        disabled={  (this.state.url==="" ||this.state.url===null) &&
        this.state.arraymediares.includes("third")&&
        !this.state.arraymediares.includes("fourth")&&
        !this.state.arraymediares.includes("fifth")
        ? false:true}
        aria-label={jsonlang.adda.toUpperCase()}
        onClick={this.deleteResolution}
        >
          <DeleteForeverIcon style={{ fontSize: 40 }}
          />
        </IconButton>
        {/* {(this.state.file && this.state.file.type !== "audio/mpeg") || itemData.type !== "audio" && itemData.type !== "document"  ?
        <Grid component="label" container alignItems="center">
        <Grid item>2d</Grid>
        <Grid item>
        <Switch checked={this.state.degreeMedia} onChange={this.handleDegreeType} color='primary' />
      </Grid>
      <Grid item>360</Grid>
    </Grid>
    : null} */}
  </Grid>
  <Grid item  xs={4}>
    {/* lasciato intenzionalemente vuoto */}
  </Grid>
  <Grid item xs={3}>
    <label>{jsonlang.resprev.toUpperCase()}</label> <br />
    {/* <label> {this.state.filename}</label><br /> */}
    {this.state.file3 ?
      (this.state.file3.type === "audio/mpeg" ? <audio controls width="50px" height="auto" src={this.state.file3 ? URL.createObjectURL(this.state.file3) : this.coverUrl(itemData._id,null,{lan:this.state.currentMediaLan,resolution:"third"})} /> :
      this.state.file3.type === "video/mp4" ? <video controls width="150px" height="auto" src={this.state.file3? URL.createObjectURL(this.state.file3) : this.coverUrl(itemData._id,null,{lan:this.state.currentMediaLan,resolution:"third"})} /> :
      this.state.file3.type === "image/jpeg" ? <img alt="" width="150px" height="auto" src={this.state.file3 ? URL.createObjectURL(this.state.file3) : this.coverUrl(itemData._id,null,{lan:this.state.currentMediaLan,resolution:"third"})} /> :
      this.state.file3.type === "image/png" ? <img alt="" width="150px" height="auto" src={this.state.file3 ? URL.createObjectURL(this.state.file3) : this.coverUrl(itemData._id,null,{lan:this.state.currentMediaLan,resolution:"third"})} /> :
      null) :
      (itemData.type === "audio" ? <audio controls width="50px" height="auto" src={this.coverUrl(itemData._id, itemData.type,{lan:this.state.currentMediaLan,resolution:"third"})} /> :
      itemData.type === "video" ? <video controls width="150px" height="auto" src={this.coverUrl(itemData._id, itemData.type,{lan:this.state.currentMediaLan,resolution:"third"})} /> :
      itemData.type === "image" ? <div><label> {
        this.state.medialanextload?
        this.state.medialanextload[this.state.currentMediaLan]?
        this.state.medialanextload[this.state.currentMediaLan][2]?
        this.state.medialanextload[this.state.currentMediaLan][2].third?
        this.state.medialanextload[this.state.currentMediaLan][2].third==="true"
        ?"High performance Format Data":"Image Format Data"
        :""
        :""
        :""
        :""}
      </label> <br /><img alt="" width="150px" height="auto" src={this.coverUrl(itemData._id, itemData.type,{lan:this.state.currentMediaLan,resolution:"third"})} />
      <Tooltip title={<h2>{"INFO"}</h2>}>
      <IconButton
        onClick={this.openInfoDialog}
        name="third"
        color="primary"
        disabled={  (this.state.url==="" ||this.state.url===null) &&
        this.state.arraymediares.includes("third")

        ? false:true}
        ><InfoIcon color="grey" fontSize="small"/></IconButton>
      </Tooltip>
    </div>
    :null)
  }

</Grid>
</Grid>

</div>
:null}
{/* quarto */}
{this.state.arraymediares.includes("third")&& (this.state.url===""||this.state.url===null)?
<div className="gridmargin">
  <Divider />
  <Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    className="gridmargin">
    <Grid item  xs={2}>
      <Typography variant="button" display="block">
        {/* {jsonlang.medialan.toUpperCase()} / {jsonlang.rights.toUpperCase()} / {jsonlang.creator.toUpperCase()} */}
        {"UPLOAD fourth element"}
      </Typography>
    </Grid>

    <Grid item xs={3}>
      <input
        ref="fileInput4"
        onChange={this.fileChoiced}
        type="file"
        accept={this.state.degreeMedia? mediatypes.resourcesMTWithBasis: mediatypes.resourcesMT}
        style={{ display: "none" }}
        id="fourth"
        // multiple={false}
      />
      <Tooltip title={<h2>{jsonlang.upload.toUpperCase()}</h2>}>
      <Button
        variant="contained"
        color="primary"
        aria-label="upload resource"
        component="span"
        size="small"
        startIcon={<CloudUploadIcon />}
        onClick={() => this.refs.fileInput4.click()}
        >
          {jsonlang.upload.toUpperCase()}
        </Button>
      </Tooltip>
      <Tooltip title={jsonlang.mediatype.toUpperCase()+":"+ mediatypes.resourcesMT}>
        <InfoIcon color="grey" fontSize="small"/>
      </Tooltip>
      <IconButton color="primary"
        role="button"
        size="small"
        id="fourthResolution"
        disabled={  (this.state.url==="" ||this.state.url===null) &&
        this.state.arraymediares.includes("fourth")&&
        !this.state.arraymediares.includes("fifth")
        ? false:true}
        aria-label={jsonlang.adda.toUpperCase()}
        onClick={this.deleteResolution}
        >
          <DeleteForeverIcon style={{ fontSize: 40 }}
          />
        </IconButton>
        {/* {(this.state.file && this.state.file.type !== "audio/mpeg") || itemData.type !== "audio" && itemData.type !== "document"  ?
        <Grid component="label" container alignItems="center">
        <Grid item>2d</Grid>
        <Grid item>
        <Switch checked={this.state.degreeMedia} onChange={this.handleDegreeType} color='primary' />
      </Grid>
      <Grid item>360</Grid>
    </Grid>
    : null} */}
  </Grid>

  <Grid item  xs={4}>
    {/* lasciato intenzionalemente vuoto */}
  </Grid>

  <Grid item xs={3}>
    <label>{jsonlang.resprev.toUpperCase()}</label> <br />
    {/* <label> {this.state.filename}</label><br /> */}
    {this.state.file4 ?
      (this.state.file4.type === "audio/mpeg" ? <audio controls width="50px" height="auto" src={this.state.file4 ? URL.createObjectURL(this.state.file4) : this.coverUrl(itemData._id,null,{lan:this.state.currentMediaLan,resolution:"fourth"})} /> :
      this.state.file4.type === "video/mp4" ? <video controls width="150px" height="auto" src={this.state.file4 ? URL.createObjectURL(this.state.file4) : this.coverUrl(itemData._id,null,{lan:this.state.currentMediaLan,resolution:"fourth"})} /> :
      this.state.file4.type === "image/jpeg" ? <img alt="" width="150px" height="auto" src={this.state.file4 ? URL.createObjectURL(this.state.file4) : this.coverUrl(itemData._id,null,{lan:this.state.currentMediaLan,resolution:"fourth"})} /> :
      this.state.file4.type === "image/png" ? <img alt="" width="150px" height="auto" src={this.state.file4 ? URL.createObjectURL(this.state.file4) : this.coverUrl(itemData._id,null,{lan:this.state.currentMediaLan,resolution:"fourth"})} /> :
      null) :
      (itemData.type === "audio" ? <audio controls width="50px" height="auto" src={this.coverUrl(itemData._id, itemData.type,{lan:this.state.currentMediaLan,resolution:"fourth"})} /> :
      itemData.type === "video" ? <video controls width="150px" height="auto" src={this.coverUrl(itemData._id, itemData.type,{lan:this.state.currentMediaLan,resolution:"fourth"})} /> :
      itemData.type === "image" ? <div><label> {
        this.state.medialanextload?
        this.state.medialanextload[this.state.currentMediaLan]?
        this.state.medialanextload[this.state.currentMediaLan][3]?
        this.state.medialanextload[this.state.currentMediaLan][3].fourth?
        this.state.medialanextload[this.state.currentMediaLan][3].fourth==="true"
        ?"High performance Format Data":"Image Format Data"
        :""
        :""
        :""
        :""}
      </label> <br /><img alt="" width="150px" height="auto" src={this.coverUrl(itemData._id, itemData.type,{lan:this.state.currentMediaLan,resolution:"fourth"})} />
      <Tooltip title={<h2>{"INFO"}</h2>}>
      <IconButton
        onClick={this.openInfoDialog}
        name="fourth"
        color="primary"
        disabled={  (this.state.url==="" ||this.state.url===null) &&
        this.state.arraymediares.includes("fourth")

        ? false:true}
        ><InfoIcon color="grey" fontSize="small"/></IconButton>
      </Tooltip>
    </div>
    :null)
  }

</Grid>
</Grid>

</div>
:null}
{/* quinto */}
{this.state.arraymediares.includes("fourth")&& (this.state.url===""||this.state.url===null)?
<div className="gridmargin">
  <Divider />
  <Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    className="gridmargin">
    <Grid item  xs={2}>
      <Typography variant="button" display="block">
        {/* {jsonlang.medialan.toUpperCase()} / {jsonlang.rights.toUpperCase()} / {jsonlang.creator.toUpperCase()} */}
        {"UPLOAD fifth element"}
      </Typography>
    </Grid>
    <Grid item xs={3}>
      <input
        ref="fileInput5"
        onChange={this.fileChoiced}
        type="file"
        accept={this.state.degreeMedia? mediatypes.resourcesMTWithBasis: mediatypes.resourcesMT}
        style={{ display: "none" }}
        id="fifth"
        // multiple={false}
      />
      <Tooltip title={<h2>{jsonlang.upload.toUpperCase()}</h2>}>
      <Button
        variant="contained"
        color="primary"
        aria-label="upload resource"
        component="span"
        size="small"
        startIcon={<CloudUploadIcon />}
        onClick={() => this.refs.fileInput5.click()}
        >
          {jsonlang.upload.toUpperCase()}
        </Button>
      </Tooltip>
      <Tooltip title={jsonlang.mediatype.toUpperCase()+":"+ mediatypes.resourcesMT}>
        <InfoIcon color="grey" fontSize="small"/>
      </Tooltip>
      <IconButton color="primary"
        role="button"
        size="small"
        id="fifthResolution"
        disabled={  (this.state.url==="" ||this.state.url===null) &&
        this.state.arraymediares.includes("fifth")
        ? false:true}
        aria-label={jsonlang.adda.toUpperCase()}
        onClick={this.deleteResolution}
        >
          <DeleteForeverIcon style={{ fontSize: 40 }}
          />
        </IconButton>
        {/* {(this.state.file && this.state.file.type !== "audio/mpeg") || itemData.type !== "audio" && itemData.type !== "document"  ?
        <Grid component="label" container alignItems="center">
        <Grid item>2d</Grid>
        <Grid item>
        <Switch checked={this.state.degreeMedia} onChange={this.handleDegreeType} color='primary' />
      </Grid>
      <Grid item>360</Grid>
    </Grid>
    : null} */}
  </Grid>

  <Grid item  xs={4}>
    {/* lasciato intenzionalemente vuoto */}
  </Grid>

  <Grid item xs={3}>
    <label>{jsonlang.resprev.toUpperCase()}</label> <br />
    {/* <label> {this.state.filename}</label><br /> */}
    {this.state.file5 ?
      (this.state.file5.type === "audio/mpeg" ? <audio controls width="50px" height="auto" src={this.state.file5 ? URL.createObjectURL(this.state.file5) : this.coverUrl(itemData._id,null,{lan:this.state.currentMediaLan,resolution:"fifth"})} /> :
      this.state.file5.type === "video/mp4" ? <video controls width="150px" height="auto" src={this.state.file5 ? URL.createObjectURL(this.state.file5) : this.coverUrl(itemData._id,null,{lan:this.state.currentMediaLan,resolution:"fifth"})} /> :
      this.state.file5.type === "image/jpeg" ? <img alt="" width="150px" height="auto" src={this.state.file5 ? URL.createObjectURL(this.state.file5) : this.coverUrl(itemData._id,null,{lan:this.state.currentMediaLan,resolution:"fifth"})} /> :
      this.state.file5.type === "image/png" ? <img alt="" width="150px" height="auto" src={this.state.file5 ? URL.createObjectURL(this.state.file5) : this.coverUrl(itemData._id,null,{lan:this.state.currentMediaLan,resolution:"fifth"})} /> :
      null) :
      (itemData.type === "audio" ? <audio controls width="50px" height="auto" src={this.coverUrl(itemData._id, itemData.type,{lan:this.state.currentMediaLan,resolution:"fifth"})} /> :
      itemData.type === "video" ? <video controls width="150px" height="auto" src={this.coverUrl(itemData._id, itemData.type,{lan:this.state.currentMediaLan,resolution:"fifth"})} /> :
      itemData.type === "image" ? <div><label> {
        this.state.medialanextload?
        this.state.medialanextload[this.state.currentMediaLan]?
        this.state.medialanextload[this.state.currentMediaLan][4]?
        this.state.medialanextload[this.state.currentMediaLan][4].fifth?
        this.state.medialanextload[this.state.currentMediaLan][4].fifth==="true"
        ?"High performance Format Data":"Image Format Data"
        :""
        :""
        :""
        :""}
      </label> <br /><img alt="" width="150px" height="auto" src={this.coverUrl(itemData._id, itemData.type,{lan:this.state.currentMediaLan,resolution:"fifth"})} />
      <Tooltip title={<h2>{"INFO"}</h2>}>
      <IconButton
        onClick={this.openInfoDialog}
        name="fifth"
        color="primary"
        disabled={  (this.state.url==="" ||this.state.url===null) &&
        this.state.arraymediares.includes("fifth")
        ? false:true}
        > <InfoIcon color="grey" fontSize="small"/></IconButton>
      </Tooltip>
    </div>
    :null)
  }
</Grid>
</Grid>

</div>

:null}
</div>:null}
</div>
)
}
}

export default connect(mapStateToProps)(withRouter(ResourcesEditManager));
