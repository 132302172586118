
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  // WHOLE,
  APP } from '../../redux/actions/actions';
  import * as services from "../../services/services.json";
  import {
    // getService,
    postService } from "../../services/services";
    import {
      Dialog,
      DialogTitle,
      DialogContent,
      DialogActions,
      Button,
      Slide,
      Grid,
      Typography,
      // FormControl,
      TextField,
      // InputLabel,
      IconButton,
      MenuItem,
      Tooltip,
      InputAdornment,
      OutlinedInput,
      Switch,
      FormControlLabel
    } from "@material-ui/core";
    import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
    import ClearIcon from '@material-ui/icons/Clear';
    import SaveIcon from '@material-ui/icons/Save';
    import Visibility from '@material-ui/icons/Visibility';
    import VisibilityOff from '@material-ui/icons/VisibilityOff';
    import './Domains.css';
    /*
    TODO:
    1-controlli di coerenza dati di input
    2-nazionalita da servizi google devono essere tutte del mondo (bassa priorità)
    3-convalida  mail
    4-confirmation dialog per success creation
    5-failed dialog per unseccess creation
    6-loader durante la richiesta
    */


    const mapStateToProps = state => {
      return {
        acamar: state.acamar,
        appstate: state.appstate
      };
    };

    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="down" ref={ref} {...props} />;
    });

    const MapNations = [
      {
        value: '1',
        label: 'SPAIN',
      },
      {
        value: '2',
        label: 'POLAND',
      },
      {
        value: '3',
        label: 'GREECE',
      },
      {
        value: '4',
        label: 'ITALY',
      },
      {
        value: '5',
        label: 'SWEDEN',
      },
      {
        value: '6',
        label: 'OTHER',
      },
    ];



    class DomainsCreateDialog extends Component {
      constructor(props) {
        super(props);
        this.state = ({
          password: "",
          showPassword: false,
          createdShortName:"",
          createdLegalName:"",
          createdEmail:"",
          createdUsername:"",
          newSelectCountry:"",
          nameerror:false,
          emailerror:false,
          passworderror:false,
          shortnameerror:false,
          legalnameerror:false,
          countryerror:false,
          cloudfrontflag:false,
          backupaws:false,
          bucketname:"",
          cloudfrontdomain:"",
          bucketnameerror:false,
          cloudfronterror:false
        });
        console.log("donaiubns ciostructor")
      }
      componentDidMount() {
        console.log("DomainsCreateDialog")
      }

      onChangeLabel= (e) =>{
        switch (e.target.name) {
          case "CRE_shortname":
          this.setState({createdShortName:e.target.value,  shortnameerror: (e.target.value.length > 0 ? false : true)});
          break;
          case "CRE_legalname":
          this.setState({createdLegalName:e.target.value, legalnameerror: (e.target.value.length > 0 ? false : true)});
          break;
          case "CRE_email":
          this.setState({createdEmail:e.target.value , emailerror: (e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null ? true : false) });
          break;
          case "CRE_password":
          this.setState({password:e.target.value, passworderror: (e.target.value.length > 6 ? false : true) });
          break;
          case "CRE_username":
          this.setState({createdUsername:e.target.value, nameerror: (e.target.value.length > 0 ? false : true)});
          break;
          case "CRE_bucket":
          this.setState({bucketname:e.target.value, bucketnameerror: (e.target.value.length > 0 ? false : true)});
          break;
          case "CRE_cloudfront":
          this.setState({cloudfrontdomain:e.target.value, cloudfronterror: (e.target.value.length > 0 ? false : true)});
          break;
          default:
          break;
        }
      }

      onChagneSelect=(e,d)=>{
        this.setState({newSelectCountry:d.props.value, countryerror: (d.props.value.length > 0 ? false : true)});
      }

      onSaveDomain=()=>{
        let self=this;
        let data={};
        data.domain={};
        data.user={};
        data.domain.shortname=this.state.createdShortName;
        data.domain.legalname=this.state.createdLegalName;
        data.domain.country=this.state.newSelectCountry;
        data.domain.cloudfrontflag=this.state.cloudfrontflag;
        data.domain.backupaws=this.state.backupaws;
        data.domain.bucketname=this.state.bucketname;
        data.domain.cloudfrontdomain=this.state.cloudfrontdomain;
        data.user.name=this.state.createdUsername;
        data.user.email=this.state.createdEmail;
        data.user.password=this.state.password;
        data.user.appname=this.props.acamar.app;
        this.props.appstate.Modal.openModalLoading = true;
        this.props.appstate.Modal.openModalLoadingTextActive = false;
        this.props.dispatch(APP(this.props.appstate));
        console.log("new domain:");
        console.log(data);
        postService(process.env.REACT_APP_DOMAIN + services.new_createdomain_POST, data,
          "", "",
          function (err, result) {
            if (err) {
              console.log("create domain  failed")
              self.props.appstate.Modal.openModalLoadingTextActive = true;
              self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.createdomainerror;
              self.props.dispatch(APP(self.props.appstate));
              //utilizzo snackbar
            }else  if (result.status === 200) {
              self.props.appstate.Modal.openModalLoadingTextActive = true;
              self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.createdomainok;
              self.props.dispatch(APP(self.props.appstate));
              console.log(result);
              self.setState({newSelectCountry:"",password:""});
              self.onclose();
              self.props.callback()
            }
          }
        );
      }


      handleChangeShow =(event, pwdinput)=>{
        this.setState({ [pwdinput]: event.target.value});
        //setValues({ ...values, [prop]: event.target.value });
      };
      handleClickShowPassword= ()=> {
        //setValues({ ...values, showPassword: !values.showPassword });
        this.setState({showPassword: !this.state.showPassword });
      };
      onclose = () => {
        this.setState({newSelectCountry:"",password:"",cloudfrontflag:false, backupaws:false,bucketnameerror:false,cloudfrontflag:false});
        this.props.appstate.CreateDomDialog.open = false;
        this.props.dispatch(APP(this.props.appstate));
      }

      cloudfrontflagChange=(event)=>{
        if (event.target.checked ===false){
          this.setState({cloudfrontflag:event.target.checked, cloudfronterror:false, cloudfrontdomain:""});
        }else{
          this.setState({cloudfrontflag:event.target.checked, cloudfronterror: (this.state.cloudfrontdomain.length > 0 ? false : true)});
        }
      }
      backupawsChange=(event)=>{
        if (event.target.checked ===false){
          this.setState({backupaws:event.target.checked , cloudfrontflag:event.target.checked, bucketnameerror:false, cloudfronterror:false , bucketname:"",cloudfrontdomain:""});
        }else {
          this.setState({backupaws:event.target.checked , bucketnameerror: (this.state.bucketname.length > 0 ? false : true)});
        }
      }

      render() {


        let jsonlang = this.props.appstate.App.language;

        return(
          <Dialog
            disableBackdropClick
            open={this.props.appstate.CreateDomDialog.open}
            onClose={this.onclose}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth = {'md'}
            >
              <DialogTitle>

                <AccountBalanceIcon
                  aria-label="Domain Icon"
                  style={{
                    color: "#fff",
                    backgroundColor: "#3f51b5",
                    borderRadius: "50px",
                    fontSize: "40px",
                    padding: "5px",
                  }}
                />
                <span style={{
                  padding: 5,
                  position: 'fixed',
                }}>
                {jsonlang.createinst.toUpperCase()}
              </span>
            </DialogTitle>
            <DialogContent dividers>
              <div>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    {/* createdPassword createdEmail createdLegalName createdShortName createdUsername */}

                    <TextField
                      //  error={this.state.eeee.length<2 ? true : false}
                      color="primary"
                      // placeholder={jsonlang.instshortname}
                      label={jsonlang.instshortname.toUpperCase()}
                      variant="outlined"
                      fullWidth
                      required //valido solo per label
                      error={this.state.shortnameerror}
                      onChange={this.onChangeLabel}
                      name="CRE_shortname"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      required
                      color="primary"
                      // placeholder={jsonlang.country}
                      label={jsonlang.country.toUpperCase()}
                      variant="outlined"
                      fullWidth
                      value={this.state.newSelectCountry}
                      onChange={this.onChagneSelect}
                      name="CRE_country"
                      error={this.state.countryerror}

                      >
                        {MapNations.map((option) =>
                          <MenuItem
                            key={option.label}
                            value={option.label}
                            >
                              {option.label}
                            </MenuItem>
                          )}
                        </TextField>


                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          color="primary"
                          fullWidth
                          // placeholder={jsonlang.instlegalname}
                          label={jsonlang.instlegalname.toUpperCase()}
                          variant="outlined"
                          required
                          onChange={this.onChangeLabel}
                          name="CRE_legalname"
                          error={this.state.legalnameerror}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <br/>
                  <Typography
                    variant="h5"
                    color="primary"
                    >
                      {jsonlang.instadmin}
                    </Typography>
                    <br/>
                    <div>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <TextField
                            autoComplete="new-password"
                            color="primary"
                            fullWidth
                            // placeholder={jsonlang.instusername}
                            label={jsonlang.name.toUpperCase()}
                            variant="outlined"
                            required
                            onChange={this.onChangeLabel}
                            name="CRE_username"
                            error={this.state.nameerror}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          {/* <FormControl variant="outlined"> */}
                          {/* <InputLabel htmlFor="password">{jsonlang.instpwd}</InputLabel> */}
                          <OutlinedInput
                            autoComplete="new-password"
                            placeholder={jsonlang.instpwd.toUpperCase()}
                            error={this.state.passworderror}
                            // label={jsonlang.instpwd}
                            fullWidth
                            onChange={this.onChangeLabel}
                            name="CRE_password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            value={this.state.password}
                            // onChange={(e)=>this.handleChangeShow(e,'password')}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  color="inherit"
                                  fontSize="large"
                                  onClick={this.handleClickShowPassword}
                                  onMouseDown={this.handleMouseDownPassword}
                                  edge="start"
                                  >
                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              // labelWidth={120}
                            />
                            {/* </FormControl> */}
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              autocomplete="new-password"
                              color="primary"
                              fullWidth
                              // placeholder={jsonlang.instemail}
                              label={jsonlang.instemail}
                              variant="outlined"
                              required
                              onChange={this.onChangeLabel}
                              name="CRE_email"
                              error={this.state.emailerror}
                            />

                          </Grid>

                        </Grid>
                      </div>
                      {/* new features */}
                    {/*  <br/>
                      <Typography variant="h6" color="primary">{jsonlang.backupoption.toUpperCase()}</Typography>
                      <br/>
                       <div> */}
                        {/* <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Tooltip placement="top"  title={<h2 >{jsonlang.awsbackup.toUpperCase()}</h2>}>
                            <FormControlLabel
                              checked={this.state.backupaws}
                              control={<Switch color='primary' onChange={this.backupawsChange}/>}
                              label={<Typography>{jsonlang.awsbackup.toUpperCase()}</Typography>}/>
                            </Tooltip>
                          </Grid> */}
                          {/* {this.state.backupaws ?
                            <Grid container>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={!this.state.backupaws}
                                  autocomplete="new-password"
                                  color="primary"
                                  fullWidth
                                  label={jsonlang.awsbucket.toUpperCase()}
                                  variant="outlined"
                                  required
                                  onChange={this.onChangeLabel}
                                  name="CRE_bucket"
                                  error={this.state.bucketnameerror}
                                />
                              </Grid> */}
                              {/* <Grid item xs={12}>
                              <Tooltip placement="top"  title={<h2 >{jsonlang.awscloudfront.toUpperCase()}</h2>}>
                              <FormControlLabel
                              checked={this.state.cloudfrontflag}
                              control={<Switch color='primary' onChange={this.cloudfrontflagChange}/>}
                              label={<Typography>{jsonlang.awscloudfront.toUpperCase()}</Typography>}/>
                            </Tooltip>
                          </Grid> */}
                        {/* </Grid>
                        : null} */}
                        {/* {this.state.cloudfrontflag ?
                          <Grid item xs={12}>
                          <TextField
                          autocomplete="new-password"
                          color="primary"
                          fullWidth
                          label={jsonlang.awscloudfrontdomain.toUpperCase()}
                          variant="outlined"
                          required
                          onChange={this.onChangeLabel}
                          name="CRE_cloudfront"
                          error={this.state.cloudfronterror}
                        />
                      </Grid>:null} */}
                    {/* </Grid>*/}
                  {/* </div>  */}
                  {/* new features end */}
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={this.onclose}
                    color="primary"
                    startIcon={<ClearIcon />}>
                    <Tooltip title={<h2>{jsonlang.instexit.toUpperCase()}</h2>}>
                    <Typography variant="button">{jsonlang.instcancel}</Typography>
                  </Tooltip>
                </Button>
                <Button
                  disabled={(
                    this.state.emailerror === false
                    && this.state.createdEmail.length > 0
                    && this.state.passworderror === false
                    && this.state.password.length > 6
                    && this.state.newSelectCountry !== ""
                    && this.state.createdUsername.length > 0
                    && this.state.nameerror===false
                    && this.state.createdShortName.length > 0
                    && this.state.shortnameerror===false
                    && this.state.createdLegalName.length > 0
                    && this.state.legalnameerror===false
                    && this.state.bucketnameerror===false
                    && this.state.cloudfronterror===false
                  )? false : true} //  && this.state.createdUsername.length > 0 ? false : true)}createdUsername
                  variant="contained"
                  size="large"
                  onClick={ this.onSaveDomain}
                  color="primary"
                  startIcon={<SaveIcon />}>
                  <Tooltip title={<h2>{jsonlang.instsavedata.toUpperCase()}</h2>}>
                  <Typography variant="button">{jsonlang.instsave}</Typography>
                </Tooltip>
              </Button>
            </DialogActions>
          </Dialog>

        )}
      };
      export default connect(mapStateToProps)(DomainsCreateDialog);
